import React, { useMemo } from "react";
import dayjs from "dayjs";
import { useToast } from "@chakra-ui/react";

import { $TSFixMeEval } from "_react/evals/shared/_types";
import { useDraftDate } from "_react/_hooks";

import { TPlayerPageCombinedPlayer, TPlayerPagePlayerClassification } from "_react/playerpage/_types";
import PlayerPageAmaContent from "_react/playerpage/ama/content/PlayerPageAmaContent";
import PlayerPageAmaProvider from "_react/playerpage/ama/PlayerPageAmaProvider";
import { TAmaBioDocument } from "_react/playerpage/bio/_types";

type PlayerPageAmaProps = {
	player?: TPlayerPageCombinedPlayer | null;
	evals?: $TSFixMeEval[] | null;
	setViewClassification?: (viewClassification?: TPlayerPagePlayerClassification) => void;
	viewClassification: string | null;
	setView?: (key: string, isReplaceState?: boolean) => void;
	view?: string;
	setIsBeta?: (isBeta: string | null, isReplaceState?: boolean) => void;
	hasProjectionsPermissions?: boolean;
	hasAmaBioEditPermissions?: boolean;
	hasPitchTypeMappingEditPermissions?: boolean;
	hasIntlPlayerPagesBetaPermissions?: boolean;
	bioDisplayOnly: boolean;
	amaBioDocument: TAmaBioDocument | null;
	setAmaBioDocument: (newDocument: TAmaBioDocument) => void;
	setBioDisplayOnly: (bioDisplayOnly: boolean) => void;
	isBioSaving: boolean;
	submitAmaBioUpdate: () => Promise<void>;
};

const PlayerPageAma = ({
	player,
	evals,
	setViewClassification,
	viewClassification,
	setView,
	view,
	setIsBeta,
	hasProjectionsPermissions = false,
	hasAmaBioEditPermissions = false,
	hasPitchTypeMappingEditPermissions = false,
	hasIntlPlayerPagesBetaPermissions = false,
	bioDisplayOnly,
	amaBioDocument,
	setAmaBioDocument,
	setBioDisplayOnly,
	isBioSaving,
	submitAmaBioUpdate
}: PlayerPageAmaProps) => {
	const toast = useToast();
	const currentDraftDate = useDraftDate();
	const currentSeasonData = useMemo(() => {
		// TODO: Replace this when current season logic is finalized
		return {
			currentSeason: dayjs().year(),
			isFetching: false
		};
	}, []);

	return (
		<PlayerPageAmaProvider
			playerId={player?.id}
			currentSeasonData={currentSeasonData}
			currentDraftDate={currentDraftDate}
			toast={toast}
		>
			<PlayerPageAmaContent
				player={player}
				evals={evals}
				setViewClassification={setViewClassification}
				viewClassification={viewClassification}
				setView={setView}
				view={view}
				setIsBeta={setIsBeta}
				hasProjectionsPermissions={hasProjectionsPermissions}
				hasAmaBioEditPermissions={hasAmaBioEditPermissions}
				hasPitchTypeMappingEditPermissions={hasPitchTypeMappingEditPermissions}
				hasIntlPlayerPagesBetaPermissions={hasIntlPlayerPagesBetaPermissions}
				bioDisplayOnly={bioDisplayOnly}
				amaBioDocument={amaBioDocument}
				setAmaBioDocument={setAmaBioDocument}
				setBioDisplayOnly={setBioDisplayOnly}
				isBioSaving={isBioSaving}
				submitAmaBioUpdate={submitAmaBioUpdate}
			/>
		</PlayerPageAmaProvider>
	);
};

export default PlayerPageAma;
