import dayjs, { Dayjs } from "dayjs";

import { TEAM_COLORS, generateColorSchemeGroup, BLUE, RED } from "_react/shared/legacy/ui/Colors";
import {
	VIEW_MANUEL,
	VIEW_OBSERVED,
	VIEW_PROJECTIONS,
	VIEW_SCOUTING_REPORTS,
	VIEW_STATISTICS,
	VIEW_SUMMARY,
	VIEW_BIO
} from "_react/shared/_constants/query_strings";
import { getAgeFromBirthDate } from "utils/helpers";

import { AMA, INTL, PRO, ONE } from "_react/playerpage/_constants";
import {
	TPlayerPageCombinedPlayer,
	TPlayerPagePlayerProProfile,
	TPlayerPagePlayerAmaProfile,
	TPlayerPagePlayerIntlProfile,
	TPlayerPageMakeupNote
} from "_react/playerpage/_types";
import { TRockyNote } from "_react/shared/data_models/notes/_types";

export const MERGE_PLAYER_ADMIN_IAM_GROUP = 82;

export const getMostRecentDraftPick = (player: TPlayerPageCombinedPlayer) => {
	if (player.draftPicks && player.draftPicks.length > 0) {
		const sortedPicks = player.draftPicks.sort((a, b) => (b.r4Year ?? 0) - (a.r4Year ?? 0));
		const mostRecentDraft = sortedPicks[0];
		return mostRecentDraft;
	}
	return null;
};

export const formatDraftAge = (dob: dayjs.Dayjs, draftDate: Dayjs) => {
	return getAgeFromBirthDate(dob, draftDate).toString();
};

export const getHasEligibleYearMakeupNote = (player: TPlayerPageCombinedPlayer): boolean => {
	return Boolean(
		player.makeupNotes?.filter((note: TPlayerPageMakeupNote) => {
			const eligibleYear = player.amaProfile?.eligibleYear ?? dayjs().year();
			const cutoffDate = dayjs(`${eligibleYear - 1}-08-01`);
			return dayjs(note.publishDate).isAfter(cutoffDate, "day");
		})?.length
	);
};

export const getOrgColorSchemeGroup = (_org: string | undefined | null) => {
	const org = _org != null ? _org.toLowerCase() : "none";
	let colorSchemeGroup = TEAM_COLORS[org];
	if (org === "phi" || org === "none")
		// Switching phi colors (TODO: Fix)
		colorSchemeGroup = generateColorSchemeGroup({
			primary: colorSchemeGroup.secondary.color,
			secondary: colorSchemeGroup.primary.color
		});
	return colorSchemeGroup;
};

export const getRosterStatusColor = (rosterStatus: string | null) => {
	if (rosterStatus == null || rosterStatus === "ACT") return { color: "white", text: BLUE };
	if (rosterStatus === "RES") return { color: BLUE, text: "white" };
	return { color: "white", text: RED };
};

export const getPlayerPhilId = (player: TPlayerPageCombinedPlayer | null, viewClassification: string | null) => {
	if (player) {
		let philId = player.playerProId;
		if (viewClassification === INTL) {
			philId = player.playerIntlId;
		}
		if (viewClassification === AMA) {
			philId = player.playerAmaId;
		}
		return philId;
	}
	return null;
};

export const countCurrentYearNotes = (notes: TRockyNote[] | null) => {
	if (notes) {
		const startOfCurentYear = dayjs().startOf("year");
		return notes.filter(x => dayjs(x.date).isAfter(startOfCurentYear) && x.recordStatus !== "DL").length;
	}
	return 0;
};

export const getUserDefaultView = (
	viewClassification: string | null | undefined,
	isBeta: string | null | undefined,
	hasProProjectionsPermissions = false,
	hasAmaProjectionsPermissions = false,
	hasIntlProjectionsPermissions = false,
	hasObservedPermissions = false,
	hasScoutingReportsPermissions = false,
	hasManuelsPermissions = false
) => {
	if (isBeta !== ONE) {
		return VIEW_SUMMARY;
	}
	// Default tab based on new pro player pages user permissions OR ama beta permissions
	if (hasProProjectionsPermissions && viewClassification === PRO) return VIEW_PROJECTIONS;
	if (hasAmaProjectionsPermissions && viewClassification === AMA) return VIEW_PROJECTIONS;
	if (hasIntlProjectionsPermissions && viewClassification === INTL) return VIEW_PROJECTIONS;
	if (hasObservedPermissions) return VIEW_OBSERVED;
	if (hasScoutingReportsPermissions) return VIEW_SCOUTING_REPORTS;
	if (hasManuelsPermissions) return VIEW_MANUEL;
	if (viewClassification === INTL) return VIEW_BIO;
	return VIEW_STATISTICS;
};

export const getAgentName = (
	profile?: TPlayerPagePlayerProProfile | TPlayerPagePlayerAmaProfile | TPlayerPagePlayerIntlProfile | null,
	nullFillerText = "-"
) => {
	return profile
		? !profile.agent?.firstName && !profile.agent?.lastName
			? nullFillerText
			: `${profile.agent?.firstName ?? ""} ${profile.agent?.lastName ?? ""}`
		: undefined;
};
