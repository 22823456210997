import { Machine, assign, Interpreter, AnyEventObject } from "xstate";
import { CancelTokenSource } from "axios";
import { CreateToastFnReturn } from "@chakra-ui/react";

import { promiseWRetry } from "utils/helpers";
import { fetchIntlProspectValue } from "_react/shared/data_models/phred/_network";
import { IIntlProspectValue } from "_react/shared/data_models/phred/_types";
import { getCancelSource } from "utils/url_helpers";
import { DEFAULT_TOAST_ERROR_PROPS } from "_react/shared/_constants/toast";

import { TIntlProspectValuesStatCardData } from "_react/shared/ui/data/cards/IntlProspectValuesStatCard/IntlProspectValuesStatCard";

export type TIntlProspectValuesStatCardContext = {
	playerId?: number;
	lastPlayerId?: number;
	shouldFetchData?: boolean;
	intlProspectValues?: Array<IIntlProspectValue> | null;
	cancelSources: Record<string, CancelTokenSource>;
	toast?: CreateToastFnReturn;
};

interface IIntlProspectValuesStatCardStateSchema {
	states: {
		initializing: {};
		initialized: {
			states: {
				// Refreshes the context when the playerId prop changes
				playerIdRefresh: {
					states: {
						idle: {};
						clearing: {};
					};
				};
				// Fetches all APV data
				intlProspectValues: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
			};
		};
	};
}

export const SET_PLAYER_ID = "SET_PLAYER_ID";
export const SET_INTL_PROSPECT_VALUES = "SET_INTL_PROSPECT_VALUES";
const INTL_PROSPECT_VALUES_CANCEL_SOURCE = "intlProspectValues";
export const FETCHING_INTL_PROSPECT_VALUES = { initialized: { intlProspectValues: "fetching" } };

const FETCH_INTL_PROSPECT_VALUES_DONE = "done.invoke.fetchingIntlProspectValues:invocation[0]";
type TFetchIntlProspectValuesEvent = { type: typeof FETCH_INTL_PROSPECT_VALUES_DONE; data?: Array<IIntlProspectValue> };

type TSetPlayerIdEvent = { type: typeof SET_PLAYER_ID; data: number | undefined };
type TSetIntlProspectValuesEvent = {
	type: typeof SET_INTL_PROSPECT_VALUES;
	data: Array<IIntlProspectValue> | null | undefined;
};

type TIntlProspectValuesStatCardEvent = TSetPlayerIdEvent | TSetIntlProspectValuesEvent | TFetchIntlProspectValuesEvent;

export type TIntlProspectValuesValuesStatCardSend = Interpreter<
	TIntlProspectValuesStatCardContext,
	IIntlProspectValuesStatCardStateSchema,
	TIntlProspectValuesStatCardEvent
>["send"];

const IntlProspectValuesStatCardMachine = (
	playerIdProp?: number,
	shouldFetchData = true,
	data?: TIntlProspectValuesStatCardData,
	toastProp?: CreateToastFnReturn
) =>
	Machine<
		TIntlProspectValuesStatCardContext,
		IIntlProspectValuesStatCardStateSchema,
		TIntlProspectValuesStatCardEvent
	>(
		{
			id: "intlProspectValuesStatCard",
			initial: "initializing",
			context: {
				playerId: playerIdProp,
				lastPlayerId: playerIdProp,
				shouldFetchData: shouldFetchData,
				intlProspectValues: data?.intlProspectValues,
				cancelSources: {},
				toast: toastProp
			},
			states: {
				initializing: {
					always: { target: "initialized" }
				},
				initialized: {
					type: "parallel",
					on: {
						[SET_PLAYER_ID]: { actions: "setPlayerId" },
						[SET_INTL_PROSPECT_VALUES]: { actions: "setIntlProspectValues" }
					},
					states: {
						playerIdRefresh: {
							initial: "idle",
							states: {
								idle: {
									always: { target: "clearing", cond: "shouldClearContext" }
								},
								clearing: {
									always: { target: "idle", actions: "clearContext" }
								}
							}
						},
						intlProspectValues: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchingIntlProspectValues",
												cond: "shouldFetchIntlProspectValues"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchingIntlProspectValues",
									entry: ["refreshIntlProspectValuesCancelSource"],
									invoke: {
										src: "fetchIntlProspectValues",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchIntlProspectValuesSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchIntlProspectValuesErrored"
										}
									}
								}
							}
						}
					}
				}
			}
		},
		{
			guards: {
				shouldClearContext: (
					context: TIntlProspectValuesStatCardContext,
					_event: TIntlProspectValuesStatCardEvent
				) => context.playerId !== context.lastPlayerId,
				shouldFetchIntlProspectValues: (
					context: TIntlProspectValuesStatCardContext,
					_event: TIntlProspectValuesStatCardEvent
				) => context.intlProspectValues === undefined && shouldFetchData && context.playerId !== undefined
			},
			actions: {
				setPlayerId: assign<TIntlProspectValuesStatCardContext, TIntlProspectValuesStatCardEvent>({
					playerId: (
						context: TIntlProspectValuesStatCardContext,
						event: TIntlProspectValuesStatCardEvent
					) => {
						if (event.type !== SET_PLAYER_ID) return context.playerId;
						return event.data;
					}
				}),
				setIntlProspectValues: assign<TIntlProspectValuesStatCardContext, TIntlProspectValuesStatCardEvent>({
					intlProspectValues: (
						context: TIntlProspectValuesStatCardContext,
						event: TIntlProspectValuesStatCardEvent
					) => {
						if (event.type !== SET_INTL_PROSPECT_VALUES) return context.intlProspectValues;
						return event.data;
					},
					cancelSources: (
						context: TIntlProspectValuesStatCardContext,
						event: TIntlProspectValuesStatCardEvent
					) => {
						if (event.type !== SET_INTL_PROSPECT_VALUES) return context.cancelSources;
						if (context.cancelSources[INTL_PROSPECT_VALUES_CANCEL_SOURCE] != null)
							context.cancelSources[INTL_PROSPECT_VALUES_CANCEL_SOURCE].cancel();
						delete context.cancelSources[INTL_PROSPECT_VALUES_CANCEL_SOURCE];
						return context.cancelSources;
					}
				}),
				clearContext: assign<TIntlProspectValuesStatCardContext, TIntlProspectValuesStatCardEvent>({
					lastPlayerId: (
						context: TIntlProspectValuesStatCardContext,
						_event: TIntlProspectValuesStatCardEvent
					) => context.playerId,
					intlProspectValues: (
						_context: TIntlProspectValuesStatCardContext,
						_event: TIntlProspectValuesStatCardEvent
					) => undefined,
					cancelSources: (
						context: TIntlProspectValuesStatCardContext,
						_event: TIntlProspectValuesStatCardEvent
					) => {
						Object.values(context.cancelSources).forEach((tokenSource: CancelTokenSource) =>
							tokenSource.cancel()
						);
						return {};
					}
				}),
				// Cancel Source Actions
				refreshIntlProspectValuesCancelSource: assign<
					TIntlProspectValuesStatCardContext,
					TIntlProspectValuesStatCardEvent
				>({
					cancelSources: (
						context: TIntlProspectValuesStatCardContext,
						_event: TIntlProspectValuesStatCardEvent
					) => {
						if (context.cancelSources[INTL_PROSPECT_VALUES_CANCEL_SOURCE] != null)
							context.cancelSources[INTL_PROSPECT_VALUES_CANCEL_SOURCE].cancel();
						context.cancelSources[INTL_PROSPECT_VALUES_CANCEL_SOURCE] = getCancelSource();
						return context.cancelSources;
					}
				}),
				// Fetch Success Actions
				handleFetchIntlProspectValuesSuccess: assign<
					TIntlProspectValuesStatCardContext,
					TIntlProspectValuesStatCardEvent
				>({
					intlProspectValues: (
						context: TIntlProspectValuesStatCardContext,
						event: TIntlProspectValuesStatCardEvent
					) => {
						if (event.type !== FETCH_INTL_PROSPECT_VALUES_DONE) return context.intlProspectValues;
						return event.data;
					}
				}),
				// Fetch Errored Actions
				handleFetchIntlProspectValuesErrored: (
					context: TIntlProspectValuesStatCardContext,
					_event: TIntlProspectValuesStatCardEvent
				) => {
					if (context.toast)
						context.toast({
							title: "INTL Prospect Value",
							description: "Error fetching intl prospect value data.",
							...DEFAULT_TOAST_ERROR_PROPS
						});
				}
			},
			services: {
				fetchIntlProspectValues: (context: TIntlProspectValuesStatCardContext, _event: AnyEventObject) => {
					const fetchFunc = () =>
						fetchIntlProspectValue(
							{
								playerId: context.playerId,
								isUseCache: true
							},
							context.cancelSources[INTL_PROSPECT_VALUES_CANCEL_SOURCE]?.token
						);
					return promiseWRetry(fetchFunc);
				}
			}
		}
	);

export default IntlProspectValuesStatCardMachine;
