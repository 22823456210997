import { Machine, assign, Interpreter, AnyEventObject } from "xstate";
import { CancelTokenSource } from "axios";
import { CreateToastFnReturn } from "@chakra-ui/react";

import { promiseWRetry } from "utils/helpers";
import { DEFAULT_TOAST_ERROR_PROPS } from "_react/shared/_constants/toast";
import {
	ISwingMetricsReferenceApiResponse,
	ISwingMetricsInferredReferenceApiResponse
} from "_react/shared/data_models/seasonal_grades/_types";
import { fetchTwentyEightyGradeDistribution } from "_react/shared/data_models/dataviz/_network";
import { fetchPlayer } from "_react/shared/data_models/player/_network";
import { PLAYING_LEVEL_AMA, PLAYING_LEVEL_INTL } from "_react/shared/data_models/hitter_grades/_constants";
import {
	fetchSwingMetricsReference,
	fetchSwingMetricsInferredReference
} from "_react/shared/data_models/seasonal_grades/_network";
import {
	fetchPlayerSeasonHitterGrades,
	fetchThreeGradesThreshold
} from "_react/shared/data_models/hitter_grades/_network";
import {
	IPlayerSeasonHitterGrades,
	IThreeGradesThresholdApiResponse,
	TPlayingLevel
} from "_react/shared/data_models/hitter_grades/_types";
import { ITwentyEightyGradeDistribution } from "_react/shared/data_models/dataviz/_types";
import { getCancelSource } from "utils/url_helpers";
import { extractFromEventDataArray } from "_react/shared/_helpers/xstate";

import { GAME_TYPE_OVERALL } from "_react/shared/ui/data/plots/PositionStatDistributions/_constants";
import { TPositionStatDistributionsPlayer } from "_react/shared/ui/data/plots/PositionStatDistributions/_types";
import { TPositionStatDistributionsData } from "_react/shared/ui/data/plots/PositionStatDistributions/PositionStatDistributions";

const PLAYER_SEASON_HITTER_GRADES_CANCEL_SOURCE = "playerSeasonHitterGrades";
const TWENTY_EIGHTY_GRADE_DISTRIBUTION_CANCEL_SOURCE = "twentyEightyGradeDistribution";
const SWING_METRICS_INFERRED_REFERENCE_CANCEL_SOURCE = "swingMetricsInferredReference";
const SWING_METRICS_REFERENCE_CANCEL_SOURCE = "swingMetricsReference";
const THREE_GRADES_THRESHOLD_CANCEL_SOURCE = "threeGradesThreshold";
const POSITION_STAT_DISTRIBUTIONS_PLAYER_CANCEL_SOURCE = "positionStatDistributionsPlayer";

export type TPositionStatDistributionsCancelSource = {
	[PLAYER_SEASON_HITTER_GRADES_CANCEL_SOURCE]?: CancelTokenSource;
	[TWENTY_EIGHTY_GRADE_DISTRIBUTION_CANCEL_SOURCE]?: CancelTokenSource;
	[SWING_METRICS_INFERRED_REFERENCE_CANCEL_SOURCE]?: CancelTokenSource;
	[SWING_METRICS_REFERENCE_CANCEL_SOURCE]?: CancelTokenSource;
	[THREE_GRADES_THRESHOLD_CANCEL_SOURCE]?: CancelTokenSource;
	[POSITION_STAT_DISTRIBUTIONS_PLAYER_CANCEL_SOURCE]?: CancelTokenSource;
};

export type TPositionStatDistributionsContext = {
	playerId?: number;
	// Not currently used but useful for debugging
	lastPlayerId?: number;
	playingLevel?: TPlayingLevel;
	lastPlayingLevel?: TPlayingLevel;
	seasonFilter: number;
	// Not currently used but useful for debugging
	lastSeasonFilter: number;
	shouldFetchData?: boolean;
	playerSeasonHitterGrades?: Array<IPlayerSeasonHitterGrades> | null;
	twentyEightyGradeDistribution?: Array<ITwentyEightyGradeDistribution> | null;
	swingMetricsInferredReference?: ISwingMetricsInferredReferenceApiResponse | null;
	swingMetricsReference?: ISwingMetricsReferenceApiResponse | null;
	threeGradesThreshold?: Array<IThreeGradesThresholdApiResponse> | null;
	positionStatDistributionsPlayer?: TPositionStatDistributionsPlayer;
	cancelSources: TPositionStatDistributionsCancelSource;
	toast?: CreateToastFnReturn;
};

type TPositionStatDistributionsStateSchema = {
	states: {
		initializing: {};
		initialized: {
			states: {
				// Fetches all player_season hitter grades data
				playerSeasonHitterGrades: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
				// Fetches all twenty eighty grade distribution data
				twentyEightyGradeDistribution: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
				// Fetches swing metrics inferred reference data (attack angle distribution data)
				swingMetricsInferredReference: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
				// Fetches swing metrics reference data (attack angle vertical distribution data)
				swingMetricsReference: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
				// Fetches three grades threshold data
				threeGradesThreshold: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
				// Fetches additional player data
				positionStatDistributionsPlayer: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
			};
		};
	};
};

export const SET_PLAYER_SEASON_HITTER_GRADES = "SET_PLAYER_SEASON_HITTER_GRADES";
export const SET_TWENTY_EIGHTY_GRADE_DISTRIBUTION = "SET_TWENTY_EIGHTY_GRADE_DISTRIBUTION";
export const SET_SWING_METRICS_INFERRED_REFERENCE = "SET_SWING_METRICS_INFERRED_REFERENCE";
export const SET_SWING_METRICS_REFERENCE = "SET_SWING_METRICS_REFERENCE";
export const SET_THREE_GRADES_THRESHOLD = "SET_THREE_GRADES_THRESHOLD";
export const SET_POSITION_STAT_DISTRIBUTIONS_PLAYER = "SET_POSITION_STAT_DISTRIBUTIONS_PLAYER";
export const SET_PLAYER_ID = "SET_PLAYER_ID";
export const SET_PLAYING_LEVEL = "SET_PLAYING_LEVEL";
export const SET_SEASON_FILTER = "SET_SEASON_FILTER";
export const FETCHING_PLAYER_SEASON_HITTER_GRADES = { initialized: { playerSeasonHitterGrades: "fetching" } };
export const FETCHING_TWENTY_EIGHTY_GRADE_DISTRIBUTION = { initialized: { twentyEightyGradeDistribution: "fetching" } };
export const FETCHING_SWING_METRICS_INFERRED_REFERENCE = { initialized: { swingMetricsInferredReference: "fetching" } };
export const FETCHING_THREE_GRADES_THRESHOLD = { initialized: { threeGradesThreshold: "fetching" } };
export const FETCHING_SWING_METRICS_REFERENCE = { initialized: { swingMetricsReference: "fetching" } };
export const FETCHING_POSITION_STAT_DISTRIBUTIONS_PLAYER = {
	initialized: { positionStatDistributionsPlayer: "fetching" }
};

const FETCH_PLAYER_SEASON_HITTER_GRADES_DONE = "done.invoke.fetchingPlayerSeasonHitterGrades:invocation[0]";
const FETCH_TWENTY_EIGHTY_GRADE_DISTRIBUTION_DONE = "done.invoke.fetchingTwentyEightyGradeDistribution:invocation[0]";
const FETCH_SWING_METRICS_INFERRED_REFERENCE_DONE = "done.invoke.fetchingSwingMetricsInferredReference:invocation[0]";
const FETCH_SWING_METRICS_REFERENCE_DONE = "done.invoke.fetchingSwingMetricsReference:invocation[0]";
const FETCH_THREE_GRADES_THRESHOLD_DONE = "done.invoke.fetchingThreeGradesThreshold:invocation[0]";
const FETCH_POSITION_STAT_DISTRIBUTIONS_PLAYER_DONE =
	"done.invoke.fetchingPositionStatDistributionsPlayer:invocation[0]";

type TSetPlayerSeasonHitterGradesEvent = {
	type: typeof SET_PLAYER_SEASON_HITTER_GRADES;
	data: Array<IPlayerSeasonHitterGrades> | null | undefined;
};
type TSetTwentyEightyGradeDistributionEvent = {
	type: typeof SET_TWENTY_EIGHTY_GRADE_DISTRIBUTION;
	data: Array<ITwentyEightyGradeDistribution> | null | undefined;
};
type TSetSwingMetricsInferredReferenceEvent = {
	type: typeof SET_SWING_METRICS_INFERRED_REFERENCE;
	data: ISwingMetricsInferredReferenceApiResponse | null | undefined;
};
type TSetSwingMetricsReferenceEvent = {
	type: typeof SET_SWING_METRICS_REFERENCE;
	data: ISwingMetricsReferenceApiResponse | null | undefined;
};
type TSetThreeGradesThresholdEvent = {
	type: typeof SET_THREE_GRADES_THRESHOLD;
	data: Array<IThreeGradesThresholdApiResponse> | null | undefined;
};
type TSetPositionStatDistributionsPlayerEvent = {
	type: typeof SET_POSITION_STAT_DISTRIBUTIONS_PLAYER;
	data: TPositionStatDistributionsPlayer | undefined;
};
type TSetPlayerIdEvent = {
	type: typeof SET_PLAYER_ID;
	data: number | undefined;
};
type TSetPlayingLevelEvent = {
	type: typeof SET_PLAYING_LEVEL;
	data: TPlayingLevel | undefined;
};
type TSetSeasonFilterEvent = {
	type: typeof SET_SEASON_FILTER;
	data: number;
};
type TFetchPlayerSeasonHitterGradesEvent = {
	type: typeof FETCH_PLAYER_SEASON_HITTER_GRADES_DONE;
	data: Array<IPlayerSeasonHitterGrades> | undefined;
};
type TFetchTwentyEightyGradeDistributionEvent = {
	type: typeof FETCH_TWENTY_EIGHTY_GRADE_DISTRIBUTION_DONE;
	data: Array<ITwentyEightyGradeDistribution> | undefined;
};
type TFetchSwingMetricsInferredReferenceEvent = {
	type: typeof FETCH_SWING_METRICS_INFERRED_REFERENCE_DONE;
	data: Array<ISwingMetricsInferredReferenceApiResponse> | undefined;
};
type TFetchSwingMetricsReferenceEvent = {
	type: typeof FETCH_SWING_METRICS_REFERENCE_DONE;
	data: Array<ISwingMetricsReferenceApiResponse> | undefined;
};
type TFetchThreeGradesThresholdEvent = {
	type: typeof FETCH_THREE_GRADES_THRESHOLD_DONE;
	data: Array<IThreeGradesThresholdApiResponse> | undefined;
};
type TFetchPositionStatDistributionsPlayerEvent = {
	type: typeof FETCH_POSITION_STAT_DISTRIBUTIONS_PLAYER_DONE;
	data: TPositionStatDistributionsPlayer | undefined;
};

type TPositionStatDistributionsEvent =
	| TSetPlayerSeasonHitterGradesEvent
	| TSetTwentyEightyGradeDistributionEvent
	| TSetSwingMetricsInferredReferenceEvent
	| TSetSwingMetricsReferenceEvent
	| TSetThreeGradesThresholdEvent
	| TSetPositionStatDistributionsPlayerEvent
	| TSetPlayerIdEvent
	| TSetPlayingLevelEvent
	| TSetSeasonFilterEvent
	| TFetchPlayerSeasonHitterGradesEvent
	| TFetchTwentyEightyGradeDistributionEvent
	| TFetchSwingMetricsInferredReferenceEvent
	| TFetchSwingMetricsReferenceEvent
	| TFetchThreeGradesThresholdEvent
	| TFetchPositionStatDistributionsPlayerEvent;

export type TPositionStatDistributionsSend = Interpreter<
	TPositionStatDistributionsContext,
	TPositionStatDistributionsStateSchema,
	TPositionStatDistributionsEvent
>["send"];

const PositionStatDistributionsMachine = (
	seasonFilterProp: number,
	playerIdProp?: number,
	playingLevelProp?: TPlayingLevel,
	shouldFetchData = true,
	data?: TPositionStatDistributionsData,
	toastProp?: CreateToastFnReturn
) =>
	Machine<TPositionStatDistributionsContext, TPositionStatDistributionsStateSchema, TPositionStatDistributionsEvent>(
		{
			id: "PositionStatDistributions",
			initial: "initializing",
			context: {
				playerId: playerIdProp,
				lastPlayerId: playerIdProp,
				playingLevel: playingLevelProp,
				lastPlayingLevel: playingLevelProp,
				seasonFilter: seasonFilterProp,
				lastSeasonFilter: seasonFilterProp,
				shouldFetchData: shouldFetchData,
				playerSeasonHitterGrades: data?.playerSeasonHitterGrades,
				twentyEightyGradeDistribution: data?.twentyEightyGradeDistribution,
				swingMetricsInferredReference: data?.swingMetricsInferredReference,
				swingMetricsReference: data?.swingMetricsReference,
				threeGradesThreshold: data?.threeGradesThreshold,
				positionStatDistributionsPlayer: data?.positionStatDistributionsPlayer,
				cancelSources: {},
				toast: toastProp
			},
			states: {
				initializing: {
					always: "initialized"
				},
				initialized: {
					type: "parallel",
					on: {
						[SET_PLAYER_SEASON_HITTER_GRADES]: { actions: "setPlayerSeasonHitterGrades" },
						[SET_TWENTY_EIGHTY_GRADE_DISTRIBUTION]: { actions: "setTwentyEightyGradeDistribution" },
						[SET_SWING_METRICS_INFERRED_REFERENCE]: { actions: "setSwingMetricsInferredReference" },
						[SET_SWING_METRICS_REFERENCE]: { actions: "setSwingMetricsReference" },
						[SET_THREE_GRADES_THRESHOLD]: { actions: "setThreeGradesThreshold" },
						[SET_POSITION_STAT_DISTRIBUTIONS_PLAYER]: { actions: "setPositionStatDistributionsPlayer" },
						[SET_PLAYER_ID]: {
							actions: [
								"setPlayerId",
								"clearPositionStatDistributionsPlayer",
								"clearPlayerSeasonHitterGrades"
							],
							cond: "shouldSetPlayerId"
						},
						[SET_PLAYING_LEVEL]: {
							actions: ["setPlayingLevel", "clearPlayerSeasonHitterGrades"],
							cond: "shouldSetPlayingLevel"
						},
						[SET_SEASON_FILTER]: {
							actions: [
								"setSeasonFilter",
								"clearPlayerSeasonHitterGrades",
								"clearSwingMetricsInferredReference",
								"clearSwingMetricsReference"
							],
							cond: "shouldSetSeasonFilter"
						}
					},
					states: {
						playerSeasonHitterGrades: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchingPlayerSeasonHitterGrades",
												cond: "shouldFetchPlayerSeasonHitterGrades"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchingPlayerSeasonHitterGrades",
									entry: ["refreshPlayerSeasonHitterGradesCancelSource"],
									invoke: {
										src: "fetchPlayerSeasonHitterGrades",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchPlayerSeasonHitterGradesSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchPlayerSeasonHitterGradesErrored"
										}
									}
								}
							}
						},
						twentyEightyGradeDistribution: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchingTwentyEightyGradeDistribution",
												cond: "shouldFetchTwentyEightyGradeDistribution"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchingTwentyEightyGradeDistribution",
									entry: ["refreshTwentyEightyGradeDistributionCancelSource"],
									invoke: {
										src: "fetchTwentyEightyGradeDistribution",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchTwentyEightyGradeDistributionSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchTwentyEightyGradeDistributionErrored"
										}
									}
								}
							}
						},
						swingMetricsInferredReference: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchingSwingMetricsInferredReference",
												cond: "shouldFetchSwingMetricsInferredReference"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchingSwingMetricsInferredReference",
									entry: ["refreshSwingMetricsInferredReferenceCancelSource"],
									invoke: {
										src: "fetchSwingMetricsInferredReference",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchSwingMetricsInferredReferenceSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchSwingMetricsInferredReferenceErrored"
										}
									}
								}
							}
						},
						swingMetricsReference: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchingSwingMetricsReference",
												cond: "shouldFetchSwingMetricsReference"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchingSwingMetricsReference",
									entry: ["refreshSwingMetricsReferenceCancelSource"],
									invoke: {
										src: "fetchSwingMetricsReference",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchSwingMetricsReferenceSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchSwingMetricsReferenceErrored"
										}
									}
								}
							}
						},
						threeGradesThreshold: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchingThreeGradesThreshold",
												cond: "shouldFetchThreeGradesThreshold"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchingThreeGradesThreshold",
									entry: ["refreshThreeGradesThresholdCancelSource"],
									invoke: {
										src: "fetchThreeGradesThreshold",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchThreeGradesThresholdSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchThreeGradesThresholdErrored"
										}
									}
								}
							}
						},
						positionStatDistributionsPlayer: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchingPositionStatDistributionsPlayer",
												cond: "shouldFetchPositionStatDistributionsPlayer"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchingPositionStatDistributionsPlayer",
									entry: ["refreshPositionStatDistributionsPlayerCancelSource"],
									invoke: {
										src: "fetchPositionStatDistributionsPlayer",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchPositionStatDistributionsPlayerSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchPositionStatDistributionsPlayerErrored"
										}
									}
								}
							}
						}
					}
				}
			}
		},
		{
			guards: {
				shouldSetPlayerId: (
					context: TPositionStatDistributionsContext,
					event: TPositionStatDistributionsEvent
				) => context.playerId !== event.data,
				shouldSetPlayingLevel: (
					context: TPositionStatDistributionsContext,
					event: TPositionStatDistributionsEvent
				) => context.playingLevel !== event.data,
				shouldSetSeasonFilter: (
					context: TPositionStatDistributionsContext,
					event: TPositionStatDistributionsEvent
				) => context.seasonFilter !== event.data,
				shouldFetchPlayerSeasonHitterGrades: (
					context: TPositionStatDistributionsContext,
					_event: TPositionStatDistributionsEvent
				) =>
					context.playerSeasonHitterGrades === undefined &&
					shouldFetchData &&
					context.playerId !== undefined &&
					context.playingLevel !== undefined,
				shouldFetchTwentyEightyGradeDistribution: (
					context: TPositionStatDistributionsContext,
					_event: TPositionStatDistributionsEvent
				) =>
					context.twentyEightyGradeDistribution === undefined &&
					shouldFetchData &&
					context.playerId !== undefined,
				shouldFetchSwingMetricsInferredReference: (
					context: TPositionStatDistributionsContext,
					_event: TPositionStatDistributionsEvent
				) =>
					context.swingMetricsInferredReference === undefined &&
					shouldFetchData &&
					context.playerId !== undefined,
				shouldFetchSwingMetricsReference: (
					context: TPositionStatDistributionsContext,
					_event: TPositionStatDistributionsEvent
				) => context.swingMetricsReference === undefined && shouldFetchData && context.playerId !== undefined,
				shouldFetchThreeGradesThreshold: (
					context: TPositionStatDistributionsContext,
					_event: TPositionStatDistributionsEvent
				) =>
					context.threeGradesThreshold === undefined &&
					shouldFetchData &&
					context.playerId !== undefined &&
					(context.playingLevel === PLAYING_LEVEL_AMA || context.playingLevel === PLAYING_LEVEL_INTL),
				shouldFetchPositionStatDistributionsPlayer: (
					context: TPositionStatDistributionsContext,
					_event: TPositionStatDistributionsEvent
				) =>
					context.positionStatDistributionsPlayer === undefined &&
					shouldFetchData &&
					context.playerId !== undefined
			},
			actions: {
				setPlayerSeasonHitterGrades: assign<TPositionStatDistributionsContext, TPositionStatDistributionsEvent>(
					{
						playerSeasonHitterGrades: (
							context: TPositionStatDistributionsContext,
							event: TPositionStatDistributionsEvent
						) => {
							if (event.type !== SET_PLAYER_SEASON_HITTER_GRADES) return context.playerSeasonHitterGrades;
							return event.data;
						},
						cancelSources: (
							context: TPositionStatDistributionsContext,
							event: TPositionStatDistributionsEvent
						) => {
							if (event.type !== SET_PLAYER_SEASON_HITTER_GRADES) return context.cancelSources;
							if (context.cancelSources[PLAYER_SEASON_HITTER_GRADES_CANCEL_SOURCE] != null)
								context.cancelSources[PLAYER_SEASON_HITTER_GRADES_CANCEL_SOURCE].cancel();
							delete context.cancelSources[PLAYER_SEASON_HITTER_GRADES_CANCEL_SOURCE];
							return context.cancelSources;
						}
					}
				),
				setTwentyEightyGradeDistribution: assign<
					TPositionStatDistributionsContext,
					TPositionStatDistributionsEvent
				>({
					twentyEightyGradeDistribution: (
						context: TPositionStatDistributionsContext,
						event: TPositionStatDistributionsEvent
					) => {
						if (event.type !== SET_TWENTY_EIGHTY_GRADE_DISTRIBUTION)
							return context.twentyEightyGradeDistribution;
						return event.data;
					},
					cancelSources: (
						context: TPositionStatDistributionsContext,
						event: TPositionStatDistributionsEvent
					) => {
						if (event.type !== SET_TWENTY_EIGHTY_GRADE_DISTRIBUTION) return context.cancelSources;
						if (context.cancelSources[TWENTY_EIGHTY_GRADE_DISTRIBUTION_CANCEL_SOURCE] != null)
							context.cancelSources[TWENTY_EIGHTY_GRADE_DISTRIBUTION_CANCEL_SOURCE].cancel();
						delete context.cancelSources[TWENTY_EIGHTY_GRADE_DISTRIBUTION_CANCEL_SOURCE];
						return context.cancelSources;
					}
				}),
				setSwingMetricsInferredReference: assign<
					TPositionStatDistributionsContext,
					TPositionStatDistributionsEvent
				>({
					swingMetricsInferredReference: (
						context: TPositionStatDistributionsContext,
						event: TPositionStatDistributionsEvent
					) => {
						if (event.type !== SET_SWING_METRICS_INFERRED_REFERENCE)
							return context.swingMetricsInferredReference;
						return event.data;
					},
					cancelSources: (
						context: TPositionStatDistributionsContext,
						event: TPositionStatDistributionsEvent
					) => {
						if (event.type !== SET_SWING_METRICS_INFERRED_REFERENCE) return context.cancelSources;
						if (context.cancelSources[SWING_METRICS_INFERRED_REFERENCE_CANCEL_SOURCE] != null)
							context.cancelSources[SWING_METRICS_INFERRED_REFERENCE_CANCEL_SOURCE].cancel();
						delete context.cancelSources[SWING_METRICS_INFERRED_REFERENCE_CANCEL_SOURCE];
						return context.cancelSources;
					}
				}),
				setSwingMetricsReference: assign<TPositionStatDistributionsContext, TPositionStatDistributionsEvent>({
					swingMetricsReference: (
						context: TPositionStatDistributionsContext,
						event: TPositionStatDistributionsEvent
					) => {
						if (event.type !== SET_SWING_METRICS_REFERENCE) return context.swingMetricsReference;
						return event.data;
					},
					cancelSources: (
						context: TPositionStatDistributionsContext,
						event: TPositionStatDistributionsEvent
					) => {
						if (event.type !== SET_SWING_METRICS_REFERENCE) return context.cancelSources;
						if (context.cancelSources[SWING_METRICS_REFERENCE_CANCEL_SOURCE] != null)
							context.cancelSources[SWING_METRICS_REFERENCE_CANCEL_SOURCE].cancel();
						delete context.cancelSources[SWING_METRICS_REFERENCE_CANCEL_SOURCE];
						return context.cancelSources;
					}
				}),
				setThreeGradesThreshold: assign<TPositionStatDistributionsContext, TPositionStatDistributionsEvent>({
					threeGradesThreshold: (
						context: TPositionStatDistributionsContext,
						event: TPositionStatDistributionsEvent
					) => {
						if (event.type !== SET_THREE_GRADES_THRESHOLD) return context.threeGradesThreshold;
						return event.data;
					},
					cancelSources: (
						context: TPositionStatDistributionsContext,
						event: TPositionStatDistributionsEvent
					) => {
						if (event.type !== SET_THREE_GRADES_THRESHOLD) return context.cancelSources;
						if (context.cancelSources[THREE_GRADES_THRESHOLD_CANCEL_SOURCE] != null)
							context.cancelSources[THREE_GRADES_THRESHOLD_CANCEL_SOURCE].cancel();
						delete context.cancelSources[THREE_GRADES_THRESHOLD_CANCEL_SOURCE];
						return context.cancelSources;
					}
				}),
				setPositionStatDistributionsPlayer: assign<
					TPositionStatDistributionsContext,
					TPositionStatDistributionsEvent
				>({
					positionStatDistributionsPlayer: (
						context: TPositionStatDistributionsContext,
						event: TPositionStatDistributionsEvent
					) => {
						if (event.type !== SET_POSITION_STAT_DISTRIBUTIONS_PLAYER)
							return context.positionStatDistributionsPlayer;
						return event.data;
					},
					cancelSources: (
						context: TPositionStatDistributionsContext,
						event: TPositionStatDistributionsEvent
					) => {
						if (event.type !== SET_POSITION_STAT_DISTRIBUTIONS_PLAYER) return context.cancelSources;
						if (context.cancelSources[POSITION_STAT_DISTRIBUTIONS_PLAYER_CANCEL_SOURCE] != null)
							context.cancelSources[POSITION_STAT_DISTRIBUTIONS_PLAYER_CANCEL_SOURCE].cancel();
						delete context.cancelSources[POSITION_STAT_DISTRIBUTIONS_PLAYER_CANCEL_SOURCE];
						return context.cancelSources;
					}
				}),
				setPlayerId: assign<TPositionStatDistributionsContext, TPositionStatDistributionsEvent>({
					lastPlayerId: (
						context: TPositionStatDistributionsContext,
						event: TPositionStatDistributionsEvent
					) => {
						if (event.type !== SET_PLAYER_ID) return context.lastPlayerId;
						return context.playerId;
					},
					playerId: (context: TPositionStatDistributionsContext, event: TPositionStatDistributionsEvent) => {
						if (event.type !== SET_PLAYER_ID) return context.playerId;
						return event.data;
					}
				}),
				setPlayingLevel: assign<TPositionStatDistributionsContext, TPositionStatDistributionsEvent>({
					lastPlayingLevel: (
						context: TPositionStatDistributionsContext,
						event: TPositionStatDistributionsEvent
					) => {
						if (event.type !== SET_PLAYING_LEVEL) return context.lastPlayingLevel;
						return context.playingLevel;
					},
					playingLevel: (
						context: TPositionStatDistributionsContext,
						event: TPositionStatDistributionsEvent
					) => {
						if (event.type !== SET_PLAYING_LEVEL) return context.playingLevel;
						return event.data;
					}
				}),
				setSeasonFilter: assign<TPositionStatDistributionsContext, TPositionStatDistributionsEvent>({
					lastSeasonFilter: (
						context: TPositionStatDistributionsContext,
						event: TPositionStatDistributionsEvent
					) => {
						if (event.type !== SET_SEASON_FILTER) return context.lastSeasonFilter;
						return context.seasonFilter;
					},
					seasonFilter: (
						context: TPositionStatDistributionsContext,
						event: TPositionStatDistributionsEvent
					) => {
						if (event.type !== SET_SEASON_FILTER) return context.seasonFilter;
						return event.data;
					}
				}),
				clearPlayerSeasonHitterGrades: assign<
					TPositionStatDistributionsContext,
					TPositionStatDistributionsEvent
				>({
					playerSeasonHitterGrades: (
						_context: TPositionStatDistributionsContext,
						_event: TPositionStatDistributionsEvent
					) => undefined,
					cancelSources: (
						context: TPositionStatDistributionsContext,
						_event: TPositionStatDistributionsEvent
					) => {
						if (context.cancelSources[PLAYER_SEASON_HITTER_GRADES_CANCEL_SOURCE] != null) {
							context.cancelSources[PLAYER_SEASON_HITTER_GRADES_CANCEL_SOURCE].cancel();
							delete context.cancelSources[PLAYER_SEASON_HITTER_GRADES_CANCEL_SOURCE];
						}
						return context.cancelSources;
					}
				}),
				clearPositionStatDistributionsPlayer: assign<
					TPositionStatDistributionsContext,
					TPositionStatDistributionsEvent
				>({
					positionStatDistributionsPlayer: (
						_context: TPositionStatDistributionsContext,
						_event: TPositionStatDistributionsEvent
					) => undefined,
					cancelSources: (
						context: TPositionStatDistributionsContext,
						_event: TPositionStatDistributionsEvent
					) => {
						if (context.cancelSources[POSITION_STAT_DISTRIBUTIONS_PLAYER_CANCEL_SOURCE] != null) {
							context.cancelSources[POSITION_STAT_DISTRIBUTIONS_PLAYER_CANCEL_SOURCE].cancel();
							delete context.cancelSources[POSITION_STAT_DISTRIBUTIONS_PLAYER_CANCEL_SOURCE];
						}
						return context.cancelSources;
					}
				}),
				clearSwingMetricsInferredReference: assign<
					TPositionStatDistributionsContext,
					TPositionStatDistributionsEvent
				>({
					swingMetricsInferredReference: (
						_context: TPositionStatDistributionsContext,
						_event: TPositionStatDistributionsEvent
					) => undefined,
					cancelSources: (
						context: TPositionStatDistributionsContext,
						_event: TPositionStatDistributionsEvent
					) => {
						if (context.cancelSources[SWING_METRICS_INFERRED_REFERENCE_CANCEL_SOURCE] != null) {
							context.cancelSources[SWING_METRICS_INFERRED_REFERENCE_CANCEL_SOURCE].cancel();
							delete context.cancelSources[SWING_METRICS_INFERRED_REFERENCE_CANCEL_SOURCE];
						}
						return context.cancelSources;
					}
				}),
				clearSwingMetricsReference: assign<TPositionStatDistributionsContext, TPositionStatDistributionsEvent>({
					swingMetricsReference: (
						_context: TPositionStatDistributionsContext,
						_event: TPositionStatDistributionsEvent
					) => undefined,
					cancelSources: (
						context: TPositionStatDistributionsContext,
						_event: TPositionStatDistributionsEvent
					) => {
						if (context.cancelSources[SWING_METRICS_REFERENCE_CANCEL_SOURCE] != null) {
							context.cancelSources[SWING_METRICS_REFERENCE_CANCEL_SOURCE].cancel();
							delete context.cancelSources[SWING_METRICS_REFERENCE_CANCEL_SOURCE];
						}
						return context.cancelSources;
					}
				}),
				clearThreeGradesThreshold: assign<TPositionStatDistributionsContext, TPositionStatDistributionsEvent>({
					threeGradesThreshold: (
						_context: TPositionStatDistributionsContext,
						_event: TPositionStatDistributionsEvent
					) => undefined,
					cancelSources: (
						context: TPositionStatDistributionsContext,
						_event: TPositionStatDistributionsEvent
					) => {
						if (context.cancelSources[THREE_GRADES_THRESHOLD_CANCEL_SOURCE] != null) {
							context.cancelSources[THREE_GRADES_THRESHOLD_CANCEL_SOURCE].cancel();
							delete context.cancelSources[THREE_GRADES_THRESHOLD_CANCEL_SOURCE];
						}
						return context.cancelSources;
					}
				}),
				// Cancel Source Actions
				refreshPlayerSeasonHitterGradesCancelSource: assign<
					TPositionStatDistributionsContext,
					TPositionStatDistributionsEvent
				>({
					cancelSources: (
						context: TPositionStatDistributionsContext,
						_event: TPositionStatDistributionsEvent
					) => {
						if (context.cancelSources[PLAYER_SEASON_HITTER_GRADES_CANCEL_SOURCE] != null)
							context.cancelSources[PLAYER_SEASON_HITTER_GRADES_CANCEL_SOURCE].cancel();
						context.cancelSources[PLAYER_SEASON_HITTER_GRADES_CANCEL_SOURCE] = getCancelSource();
						return context.cancelSources;
					}
				}),
				refreshTwentyEightyGradeDistributionCancelSource: assign<
					TPositionStatDistributionsContext,
					TPositionStatDistributionsEvent
				>({
					cancelSources: (
						context: TPositionStatDistributionsContext,
						_event: TPositionStatDistributionsEvent
					) => {
						if (context.cancelSources[TWENTY_EIGHTY_GRADE_DISTRIBUTION_CANCEL_SOURCE] != null)
							context.cancelSources[TWENTY_EIGHTY_GRADE_DISTRIBUTION_CANCEL_SOURCE].cancel();
						context.cancelSources[TWENTY_EIGHTY_GRADE_DISTRIBUTION_CANCEL_SOURCE] = getCancelSource();
						return context.cancelSources;
					}
				}),
				refreshSwingMetricsInferredReferenceCancelSource: assign<
					TPositionStatDistributionsContext,
					TPositionStatDistributionsEvent
				>({
					cancelSources: (
						context: TPositionStatDistributionsContext,
						_event: TPositionStatDistributionsEvent
					) => {
						if (context.cancelSources[SWING_METRICS_INFERRED_REFERENCE_CANCEL_SOURCE] != null)
							context.cancelSources[SWING_METRICS_INFERRED_REFERENCE_CANCEL_SOURCE].cancel();
						context.cancelSources[SWING_METRICS_INFERRED_REFERENCE_CANCEL_SOURCE] = getCancelSource();
						return context.cancelSources;
					}
				}),
				refreshSwingMetricsReferenceCancelSource: assign<
					TPositionStatDistributionsContext,
					TPositionStatDistributionsEvent
				>({
					cancelSources: (
						context: TPositionStatDistributionsContext,
						_event: TPositionStatDistributionsEvent
					) => {
						if (context.cancelSources[SWING_METRICS_REFERENCE_CANCEL_SOURCE] != null)
							context.cancelSources[SWING_METRICS_REFERENCE_CANCEL_SOURCE].cancel();
						context.cancelSources[SWING_METRICS_REFERENCE_CANCEL_SOURCE] = getCancelSource();
						return context.cancelSources;
					}
				}),
				refreshThreeGradesThresholdCancelSource: assign<
					TPositionStatDistributionsContext,
					TPositionStatDistributionsEvent
				>({
					cancelSources: (
						context: TPositionStatDistributionsContext,
						_event: TPositionStatDistributionsEvent
					) => {
						if (context.cancelSources[THREE_GRADES_THRESHOLD_CANCEL_SOURCE] != null)
							context.cancelSources[THREE_GRADES_THRESHOLD_CANCEL_SOURCE].cancel();
						context.cancelSources[THREE_GRADES_THRESHOLD_CANCEL_SOURCE] = getCancelSource();
						return context.cancelSources;
					}
				}),
				refreshPositionStatDistributionsPlayerCancelSource: assign<
					TPositionStatDistributionsContext,
					TPositionStatDistributionsEvent
				>({
					cancelSources: (
						context: TPositionStatDistributionsContext,
						_event: TPositionStatDistributionsEvent
					) => {
						if (context.cancelSources[POSITION_STAT_DISTRIBUTIONS_PLAYER_CANCEL_SOURCE] != null)
							context.cancelSources[POSITION_STAT_DISTRIBUTIONS_PLAYER_CANCEL_SOURCE].cancel();
						context.cancelSources[POSITION_STAT_DISTRIBUTIONS_PLAYER_CANCEL_SOURCE] = getCancelSource();
						return context.cancelSources;
					}
				}),
				// Fetch Success Actions
				handleFetchPlayerSeasonHitterGradesSuccess: assign<
					TPositionStatDistributionsContext,
					TPositionStatDistributionsEvent
				>({
					playerSeasonHitterGrades: (
						context: TPositionStatDistributionsContext,
						event: TPositionStatDistributionsEvent
					) => {
						if (event.type !== FETCH_PLAYER_SEASON_HITTER_GRADES_DONE)
							return context.playerSeasonHitterGrades;
						return event.data;
					}
				}),
				handleFetchTwentyEightyGradeDistributionSuccess: assign<
					TPositionStatDistributionsContext,
					TPositionStatDistributionsEvent
				>({
					twentyEightyGradeDistribution: (
						context: TPositionStatDistributionsContext,
						event: TPositionStatDistributionsEvent
					) => {
						if (event.type !== FETCH_TWENTY_EIGHTY_GRADE_DISTRIBUTION_DONE)
							return context.twentyEightyGradeDistribution;
						return event.data;
					}
				}),
				handleFetchSwingMetricsInferredReferenceSuccess: assign<
					TPositionStatDistributionsContext,
					TPositionStatDistributionsEvent
				>({
					swingMetricsInferredReference: (
						context: TPositionStatDistributionsContext,
						event: TPositionStatDistributionsEvent
					) => {
						if (event.type !== FETCH_SWING_METRICS_INFERRED_REFERENCE_DONE)
							return context.swingMetricsInferredReference;
						// season + level is the primary key in the table, so it's a safe assumption that we only want the first result
						return extractFromEventDataArray<TPositionStatDistributionsEvent>(event);
					}
				}),
				handleFetchSwingMetricsReferenceSuccess: assign<
					TPositionStatDistributionsContext,
					TPositionStatDistributionsEvent
				>({
					swingMetricsReference: (
						context: TPositionStatDistributionsContext,
						event: TPositionStatDistributionsEvent
					) => {
						if (event.type !== FETCH_SWING_METRICS_REFERENCE_DONE) return context.swingMetricsReference;
						// season + level is the primary key in the table, so it's a safe assumption that we only want the first result
						return extractFromEventDataArray<TPositionStatDistributionsEvent>(event);
					}
				}),
				handleFetchThreeGradesThresholdSuccess: assign<
					TPositionStatDistributionsContext,
					TPositionStatDistributionsEvent
				>({
					threeGradesThreshold: (
						context: TPositionStatDistributionsContext,
						event: TPositionStatDistributionsEvent
					) => {
						if (event.type !== FETCH_THREE_GRADES_THRESHOLD_DONE) return context.threeGradesThreshold;
						return event.data;
					}
				}),
				handleFetchPositionStatDistributionsPlayerSuccess: assign<
					TPositionStatDistributionsContext,
					TPositionStatDistributionsEvent
				>({
					positionStatDistributionsPlayer: (
						context: TPositionStatDistributionsContext,
						event: TPositionStatDistributionsEvent
					) => {
						if (event.type !== FETCH_POSITION_STAT_DISTRIBUTIONS_PLAYER_DONE)
							return context.positionStatDistributionsPlayer;
						return event.data;
					}
				}),
				// Fetch Errored Actions
				handleFetchPlayerSeasonHitterGradesErrored: (
					context: TPositionStatDistributionsContext,
					_event: TPositionStatDistributionsEvent
				) => {
					if (context.toast)
						context.toast({
							title: "Player Season Hitter Grades",
							description: "Error fetching player season hitter grades data.",
							...DEFAULT_TOAST_ERROR_PROPS
						});
				},
				handleFetchTwentyEightyGradeDistributionErrored: (
					context: TPositionStatDistributionsContext,
					_event: TPositionStatDistributionsEvent
				) => {
					if (context.toast)
						context.toast({
							title: "Twenty Eighty Grade Distribution",
							description: "Error fetching twenty eighty grade distribution data.",
							...DEFAULT_TOAST_ERROR_PROPS
						});
				},
				handleFetchSwingMetricsInferredReferenceErrored: (
					context: TPositionStatDistributionsContext,
					_event: TPositionStatDistributionsEvent
				) => {
					if (context.toast)
						context.toast({
							title: "Swing Metrics Inferred Reference",
							description: "Error fetching attack angle vertical distribution data.",
							...DEFAULT_TOAST_ERROR_PROPS
						});
				},
				handleFetchSwingMetricsReferenceErrored: (
					context: TPositionStatDistributionsContext,
					_event: TPositionStatDistributionsEvent
				) => {
					if (context.toast)
						context.toast({
							title: "Swing Metrics Reference",
							description: "Error fetching attack angle vertical distribution data.",
							...DEFAULT_TOAST_ERROR_PROPS
						});
				},
				handleFetchThreeGradesThresholdErrored: (
					context: TPositionStatDistributionsContext,
					_event: TPositionStatDistributionsEvent
				) => {
					if (context.toast)
						context.toast({
							title: "Three Grade Threshold",
							description: "Error fetching hitting grade threshold data.",
							...DEFAULT_TOAST_ERROR_PROPS
						});
				},
				handleFetchPositionStatDistributionsPlayerErrored: (
					context: TPositionStatDistributionsContext,
					_event: TPositionStatDistributionsEvent
				) => {
					if (context.toast)
						context.toast({
							title: "Player Data - Position Stat Distributions",
							description: "Error fetching player data for position stat distributions.",
							...DEFAULT_TOAST_ERROR_PROPS
						});
				}
			},
			services: {
				fetchPlayerSeasonHitterGrades: (context: TPositionStatDistributionsContext, _event: AnyEventObject) => {
					const fetchFunc = () =>
						fetchPlayerSeasonHitterGrades(
							{
								playerId: context.playerId,
								season: context.seasonFilter,
								playingLevel: context.playingLevel,
								gameType: GAME_TYPE_OVERALL,
								isUseCache: true
							},
							context.cancelSources[PLAYER_SEASON_HITTER_GRADES_CANCEL_SOURCE]?.token
						);
					return promiseWRetry(fetchFunc);
				},
				fetchTwentyEightyGradeDistribution: (
					context: TPositionStatDistributionsContext,
					_event: AnyEventObject
				) => {
					const fetchFunc = () =>
						fetchTwentyEightyGradeDistribution(
							context.cancelSources[TWENTY_EIGHTY_GRADE_DISTRIBUTION_CANCEL_SOURCE]?.token
						);
					return promiseWRetry(fetchFunc);
				},
				fetchSwingMetricsInferredReference: (
					context: TPositionStatDistributionsContext,
					_event: AnyEventObject
				) => {
					const fetchFunc = () =>
						fetchSwingMetricsInferredReference(
							{
								season: context.seasonFilter,
								level: "MLB",
								includeAttackAngleVerticalNormalDistribution: true,
								isUseCache: true
							},
							context.cancelSources[SWING_METRICS_INFERRED_REFERENCE_CANCEL_SOURCE]?.token
						);
					return promiseWRetry(fetchFunc);
				},
				fetchSwingMetricsReference: (context: TPositionStatDistributionsContext, _event: AnyEventObject) => {
					const fetchFunc = () =>
						fetchSwingMetricsReference(
							{
								season: context.seasonFilter,
								level: "MLB",
								includeAttackAngleVerticalNormalDistribution: true,
								isUseCache: true
							},
							context.cancelSources[SWING_METRICS_REFERENCE_CANCEL_SOURCE]?.token
						);
					return promiseWRetry(fetchFunc);
				},
				fetchThreeGradesThreshold: (context: TPositionStatDistributionsContext, _event: AnyEventObject) => {
					const fetchFunc = () =>
						fetchThreeGradesThreshold(context.cancelSources[THREE_GRADES_THRESHOLD_CANCEL_SOURCE]?.token);
					return promiseWRetry(fetchFunc);
				},
				fetchPositionStatDistributionsPlayer: (
					context: TPositionStatDistributionsContext,
					_event: AnyEventObject
				) => {
					if (context.playerId === undefined) return Promise.resolve(undefined);
					const fetchFunc = () =>
						fetchPlayer<TPositionStatDistributionsPlayer>(
							{
								id: context.playerId as number, // We know this is a number based on the if statement above
								isUseCombinedId: true,
								fields: "bats"
							},
							context.cancelSources[POSITION_STAT_DISTRIBUTIONS_PLAYER_CANCEL_SOURCE]?.token
						);
					return promiseWRetry(fetchFunc);
				}
			}
		}
	);

export default PositionStatDistributionsMachine;
