import React, { useEffect, useMemo, useState } from "react";
import { SystemStyleObject, Text, Tooltip, useToast } from "@chakra-ui/react";
import { useMachine } from "@xstate/react";
import { HStack, VStack, Checkbox, Box, Table, Tbody, Td, Tr } from "@chakra-ui/react";
import dayjs from "dayjs";

import { sortPitchTypesByPitchGroup } from "_react/shared/_helpers/pitch_types";
import { getStuffThresholdPitchTypesToDisplay } from "_react/shared/data_models/arsenal_scores/_helpers";
import {
	IArsenalScoresThresholdApiResponse,
	IPlayerSeasonArsenalScoresSchema
} from "_react/shared/data_models/arsenal_scores/_types";
import {
	BATS_OVERALL,
	PLAYING_LEVEL_AMA,
	PLAYING_LEVEL_INTL
} from "_react/shared/data_models/seasonal_grades/_constants";
import { PITCH_MOVEMENT_GROUP, RELEASE_POINT_GROUP } from "_react/shared/data_models/metric/_constants";
import { TPitchTypes } from "_react/shared/_types/pitch_types";
import { PITCH_TYPE_CHAKRA_COLOR_SCHEME } from "_react/shared/dataviz/_constants";
import OutlineWarning from "_react/shared/ui/icons/OutlineWarning";
import PitchMetricContourPlot from "_react/shared/ui/presentation/plots/PitchMetricContourPlot/PitchMetricContourPlot";
import { TPitchTypeDistributionData } from "_react/shared/ui/presentation/plots/PitchMetricContourPlot/_types";
import { IArmSlotsMonthly } from "_react/shared/data_models/arm_slots/_types";
import {
	IMetricJointProbabilityDensities,
	TMetricJointProbabilityGroup
} from "_react/shared/data_models/metric/_types";
import ToggleButton from "_react/shared/ui/presentation/components/ToggleButton/ToggleButton";

import createPitcherMetricContourPlotMachine, {
	TPitcherMetricContourPlotContext,
	TJointProbabilityDensities,
	TPlayerHeight,
	SET_ARM_SLOTS_MONTHLY,
	SET_JOINT_PROBABILITY_DENSITIES,
	SET_ARSENAL_SCORES_THRESHOLD,
	SET_PLAYER_SEASON_ARSENAL_SCORES,
	SET_PLAYER_ID,
	SET_PLAYER_CLASSIFICATION,
	SET_METRIC_GROUP,
	FETCHING_ARM_SLOTS_MONTHLY,
	FETCHING_JOINT_PROBABILITY_DENSITIES,
	FETCHING_ARSENAL_SCORES_THRESHOLD,
	FETCHING_PLAYER_SEASON_ARSENAL_SCORES,
	SET_BATS_FILTER,
	SET_THROWS_FILTER,
	SET_SEASON_FILTER,
	FETCHING_PLAYER_HEIGHT,
	SET_PLAYER_HEIGHT
} from "_react/shared/ui/data/plots/PitcherMetricContourPlot/_machine";
import { PRO } from "_react/shared/ui/data/plots/PitcherMetricContourPlot/_constants";

export type TPitcherMetricContourPrimaryPlotData = {
	armSlotsMonthly?: Array<IArmSlotsMonthly>;
	jointProbabilityDensities: TJointProbabilityDensities;
	metricGroup: TMetricJointProbabilityGroup;
	isLoading?: boolean;
};

export type TPitcherMetricContourSecondaryPlotData = {
	playerSeasonArsenalScores?: Array<IPlayerSeasonArsenalScoresSchema>;
	arsenalScoresThreshold?: Array<IArsenalScoresThresholdApiResponse> | null;
	playerHeight?: TPlayerHeight;
	isLoading?: boolean;
};

type TPitcherMetricContourPlotStyle = {
	container?: SystemStyleObject;
};

type TPitcherMetricContourPlotProps = {
	title?: string;
	playerId?: number;
	playerClassification?: string;
	playingLevel?: string;
	metricGroup: TMetricJointProbabilityGroup;
	seasonFilter?: number;
	primaryData?: TPitcherMetricContourPrimaryPlotData;
	shouldFetchPrimaryData?: boolean;
	secondaryData?: TPitcherMetricContourSecondaryPlotData;
	shouldFetchSecondaryData?: boolean;
	batsFilter?: string;
	throwsFilter?: string;
	width?: number;
	style?: TPitcherMetricContourPlotStyle;
	plotType?: "gaussian" | "scatter";
};

type TSelectedPitchTypes = {
	[key in TPitchTypes]: boolean;
};

const PitcherMetricContourPlot = ({
	title,
	playerId,
	playerClassification,
	playingLevel,
	metricGroup,
	seasonFilter = dayjs().year(),
	primaryData,
	shouldFetchPrimaryData = true,
	secondaryData,
	shouldFetchSecondaryData = true,
	batsFilter = BATS_OVERALL,
	throwsFilter,
	plotType = "gaussian",
	width,
	style
}: TPitcherMetricContourPlotProps) => {
	const [selectedPitchTypes, setSelectedPitchTypes] = useState<TSelectedPitchTypes>({
		CB: true,
		CH: true,
		SP: true,
		SL: true,
		SW: true,
		SV: true,
		SI: true,
		CT: true,
		FF: true,
		KN: true
	});
	const [selectedPlotType, setSelectedPlotType] = useState<"gaussian" | "scatter">(plotType);
	const toast = useToast();
	const [current, send] = useMachine(
		createPitcherMetricContourPlotMachine(
			batsFilter,
			seasonFilter,
			throwsFilter,
			playerId,
			playerClassification,
			playingLevel,
			metricGroup,
			shouldFetchPrimaryData,
			primaryData,
			shouldFetchSecondaryData,
			secondaryData,
			toast
		)
	);
	const {
		armSlotsMonthly,
		jointProbabilityDensities,
		playerHeight,
		arsenalScoresThreshold,
		playerSeasonArsenalScores
	} = current.context as TPitcherMetricContourPlotContext;

	const fetchingArmSlotsMonthly: boolean = current.matches(FETCHING_ARM_SLOTS_MONTHLY);
	const fetchingProbabilityDensities: boolean = current.matches(FETCHING_JOINT_PROBABILITY_DENSITIES);

	const fetchingArsenalScoresThreshold: boolean = current.matches(FETCHING_ARSENAL_SCORES_THRESHOLD);
	const fetchingPlayerSeasonArsenalScores: boolean = current.matches(FETCHING_PLAYER_SEASON_ARSENAL_SCORES);
	const fetchingPlayerHeight: boolean = current.matches(FETCHING_PLAYER_HEIGHT);

	const isLoadingPrimary: boolean = shouldFetchPrimaryData
		? fetchingProbabilityDensities || (playerClassification === PRO && fetchingArmSlotsMonthly)
		: primaryData?.isLoading ?? false;

	const isLoadingSecondary: boolean = shouldFetchSecondaryData
		? ((playingLevel === PLAYING_LEVEL_AMA || playingLevel === PLAYING_LEVEL_INTL) &&
				(fetchingArsenalScoresThreshold || fetchingPlayerSeasonArsenalScores)) ||
		  fetchingPlayerHeight
		: secondaryData?.isLoading ?? false;

	const isLoading = isLoadingPrimary || isLoadingSecondary;

	// Update machine context when data prop changes
	useEffect(() => {
		send({ type: SET_PLAYER_ID, data: playerId });
	}, [playerId, send]);

	useEffect(() => {
		send({ type: SET_PLAYER_CLASSIFICATION, data: playerClassification });
	}, [playerClassification, send]);

	useEffect(() => {
		send({ type: SET_METRIC_GROUP, data: metricGroup });
	}, [metricGroup, send]);

	useEffect(() => {
		send({ type: SET_BATS_FILTER, data: batsFilter });
	}, [batsFilter, send]);

	useEffect(() => {
		send({ type: SET_THROWS_FILTER, data: throwsFilter });
	}, [throwsFilter, send]);

	useEffect(() => {
		send({ type: SET_SEASON_FILTER, data: seasonFilter });
	}, [seasonFilter, send]);

	useEffect(() => {
		if (primaryData?.armSlotsMonthly !== armSlotsMonthly && !shouldFetchPrimaryData) {
			send({ type: SET_ARM_SLOTS_MONTHLY, data: primaryData?.armSlotsMonthly ?? [] });
		}
	}, [primaryData?.armSlotsMonthly, armSlotsMonthly, shouldFetchPrimaryData, send]);

	useEffect(() => {
		if (primaryData?.jointProbabilityDensities !== jointProbabilityDensities && !shouldFetchPrimaryData) {
			send({ type: SET_JOINT_PROBABILITY_DENSITIES, data: primaryData?.jointProbabilityDensities ?? {} });
		}
	}, [primaryData?.jointProbabilityDensities, jointProbabilityDensities, shouldFetchPrimaryData, send]);
	useEffect(() => {
		if (secondaryData?.arsenalScoresThreshold !== arsenalScoresThreshold && !shouldFetchSecondaryData) {
			send({ type: SET_ARSENAL_SCORES_THRESHOLD, data: secondaryData?.arsenalScoresThreshold });
		}
	}, [secondaryData?.arsenalScoresThreshold, arsenalScoresThreshold, shouldFetchSecondaryData, send]);
	useEffect(() => {
		if (secondaryData?.playerSeasonArsenalScores !== playerSeasonArsenalScores && !shouldFetchSecondaryData) {
			send({ type: SET_PLAYER_SEASON_ARSENAL_SCORES, data: secondaryData?.playerSeasonArsenalScores });
		}
	}, [secondaryData?.playerSeasonArsenalScores, playerSeasonArsenalScores, shouldFetchSecondaryData, send]);
	useEffect(() => {
		if (secondaryData?.playerHeight !== playerHeight && !shouldFetchSecondaryData) {
			send({ type: SET_PLAYER_HEIGHT, data: secondaryData?.playerHeight });
		}
	}, [secondaryData?.playerHeight, playerHeight, shouldFetchSecondaryData, send]);

	const pitchTypesToDisplay: Array<string> | undefined = useMemo(() => {
		return getStuffThresholdPitchTypesToDisplay(
			batsFilter,
			throwsFilter,
			arsenalScoresThreshold,
			playerSeasonArsenalScores
		);
	}, [playerSeasonArsenalScores, arsenalScoresThreshold, batsFilter, throwsFilter]);

	const distributionData: TPitchTypeDistributionData = useMemo(() => {
		const isScatterPlot = selectedPlotType === "scatter";
		const probabilityDensityRecord: TPitchTypeDistributionData = {};
		jointProbabilityDensities[`${batsFilter}-${throwsFilter}`]?.forEach(
			(metricProbabilityDensities: IMetricJointProbabilityDensities) => {
				const pitchType = metricProbabilityDensities?.requestArgs?.pitchType;
				if (
					pitchType &&
					pitchType in selectedPitchTypes &&
					// If isScatterPlot, we aren't using the pitchTypesToDisplay threshold data
					(!pitchTypesToDisplay || pitchTypesToDisplay?.includes(pitchType) || isScatterPlot) &&
					// If isScatterPlot, we aren't displaying either of these, so they can be falsy
					(metricProbabilityDensities.jointProbabilityDensity || isScatterPlot) &&
					(metricProbabilityDensities.jointProbabilityDensityMetadata || isScatterPlot)
				)
					probabilityDensityRecord[pitchType as TPitchTypes] = {
						densityData: metricProbabilityDensities.jointProbabilityDensity ?? [[]],
						densityMetadata: metricProbabilityDensities.jointProbabilityDensityMetadata ?? {
							xMin: null,
							xMax: null,
							yMin: null,
							yMax: null
						},
						pitchData: metricProbabilityDensities.jointProbabilityDensityPitches
					};
			}
		);
		return probabilityDensityRecord;
	}, [
		jointProbabilityDensities,
		batsFilter,
		throwsFilter,
		selectedPitchTypes,
		pitchTypesToDisplay,
		selectedPlotType
	]);

	const filteredDistributionData = useMemo(() => {
		const filteredData: TPitchTypeDistributionData = {};
		(Object.keys(distributionData) as Array<keyof TPitchTypeDistributionData>).forEach((pitchType: TPitchTypes) => {
			if (selectedPitchTypes[pitchType]) {
				filteredData[pitchType] = distributionData[pitchType];
			}
		});
		return filteredData;
	}, [distributionData, selectedPitchTypes]);

	const expectedMovementData: Array<{ x: number; y: number }> | undefined = useMemo(() => {
		const expectedMovementData: Array<{ x: number; y: number }> = [];
		if (metricGroup === PITCH_MOVEMENT_GROUP && armSlotsMonthly) {
			// This slope of expected movement is mean(mesa.arm_slots_monthly.ex_fastball_break_z_dragless) / mean(mesa.arm_slots_monthly.ex_fastball_break_x_dragless)
			// The y intercept is 0
			const filteredArmSlotsMonthly = armSlotsMonthly.filter(
				(armSlot: IArmSlotsMonthly) =>
					armSlot.exFastballBreakXDragless != null &&
					armSlot.exFastballBreakZDragless != null &&
					(armSlot.throws === throwsFilter || throwsFilter === undefined)
			);
			const meanBreakX =
				filteredArmSlotsMonthly.reduce(
					(totalBreakX: number, armSlot: IArmSlotsMonthly) => totalBreakX + armSlot.exFastballBreakXDragless!,
					0
				) / filteredArmSlotsMonthly.length;
			const meanBreakZ =
				filteredArmSlotsMonthly.reduce(
					(totalBreakX: number, armSlot: IArmSlotsMonthly) => totalBreakX + armSlot.exFastballBreakZDragless!,
					0
				) / filteredArmSlotsMonthly.length;
			const expectedMovementSlope = meanBreakZ / meanBreakX;
			// x values larger than the plot min/max to accommodate for padding on the axes in Plotly
			const minX = -40;
			const maxX = 40;
			expectedMovementData.push(
				{ x: minX, y: minX * expectedMovementSlope },
				{ x: maxX, y: maxX * expectedMovementSlope }
			);
			return expectedMovementData;
		}
		return undefined;
	}, [armSlotsMonthly, metricGroup, throwsFilter]);

	return (
		<>
			{isLoading && <Box className="loading-item" height="xs" width="lg" sx={style?.container} />}
			{!isLoading && (
				<VStack align="start">
					<HStack justifyContent="space-between" width="100%">
						{title && (
							<Box fontFamily="heading" fontSize="md" fontWeight="bold">
								{title}
							</Box>
						)}
						<ToggleButton<string>
							toggleOptions={[
								{ value: "gaussian", label: "Contour" },
								{ value: "scatter", label: "Scatter" }
							]}
							value={selectedPlotType}
							onSelect={(value: string) => {
								const valueTyped = value === "gaussian" || value === "scatter" ? value : "gaussian";
								setSelectedPlotType(valueTyped);
							}}
							style={{ button: { fontSize: "xs" } }}
						/>
					</HStack>
					<HStack align="center" sx={style?.container} marginRight={5}>
						<PitchMetricContourPlot
							metricGroup={metricGroup}
							data={{
								distributionData: filteredDistributionData,
								expectedMovement: expectedMovementData,
								playerHeight: playerHeight
							}}
							xAxisLabel={`${batsFilter === BATS_OVERALL ? "" : `v${batsFilter}HB `}${
								metricGroup === RELEASE_POINT_GROUP ? "Release Side (ft.)" : "Horizontal Break (in.)"
							}`}
							plotType={selectedPlotType}
							width={width}
						/>
						<VStack>
							<Table variant="unstyled" size="sm">
								<Tbody>
									{distributionData &&
										(Object.keys(distributionData) as Array<keyof TPitchTypeDistributionData>)
											.sort(sortPitchTypesByPitchGroup)
											.map((pitchType: TPitchTypes) => {
												const isDisplayWarningSymbol =
													// If we are using pitchTypesToDisplay, check whether it includes the pitchType
													(pitchTypesToDisplay &&
														!pitchTypesToDisplay?.includes(pitchType)) ||
													// Check whether the contour plot data is equal to [[]]
													(distributionData[pitchType]?.densityData?.length === 1 &&
														distributionData[pitchType]?.densityData[0]?.length === 0);
												return (
													<Tr key={pitchType}>
														<Td padding="0">
															<Checkbox
																colorScheme={PITCH_TYPE_CHAKRA_COLOR_SCHEME[pitchType]}
																isChecked={selectedPitchTypes[pitchType]}
																onChange={() => {
																	const isPitchTypeSelected =
																		selectedPitchTypes[pitchType];
																	const selectedPitchTypesCopy = {
																		...selectedPitchTypes
																	};
																	selectedPitchTypesCopy[
																		pitchType
																	] = !isPitchTypeSelected;
																	setSelectedPitchTypes(selectedPitchTypesCopy);
																}}
															/>
														</Td>
														<Td fontWeight="xs" padding="1">
															<HStack justifyContent="space-between" gap="1">
																<Text>{pitchType}</Text>
																<Tooltip
																	hasArrow
																	label="Due to a small sample size, this pitch type is not available on the Contour Plot"
																	sx={{
																		position: "relative",
																		visibility: isDisplayWarningSymbol
																			? undefined
																			: "hidden"
																	}}
																>
																	<Box>
																		<OutlineWarning
																			sx={{
																				color: "gray.500",
																				visibility: isDisplayWarningSymbol
																					? undefined
																					: "hidden"
																			}}
																		></OutlineWarning>
																	</Box>
																</Tooltip>
															</HStack>
														</Td>
													</Tr>
												);
											})}
								</Tbody>
							</Table>
						</VStack>
					</HStack>
				</VStack>
			)}
		</>
	);
};

export default PitcherMetricContourPlot;
