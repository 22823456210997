import dayjs, { Dayjs } from "dayjs";

import { round10 } from "_react/shared/_helpers/numbers";
import { TPlayerPageCombinedPlayer } from "_react/playerpage/_types";
import { PLAYER_TYPE_BATTER, PLAYER_TYPE_PITCHER } from "_react/shared/data_models/phred/_constants";
import { IIntlProspectValue } from "_react/shared/data_models/phred/_types";
import { $TSFixMeEval } from "_react/evals/shared/_types";
import { INTL_PITCH, INTL_HIT } from "_react/evals/_constants";

export const getProjectionsIntlSeason = (player: TPlayerPageCombinedPlayer): number => {
	// For INTL players, return eligible year or current year
	return player.intlProfile?.eligibleYear ?? dayjs().year();
};

export const formatIntlSurplusValue = (value: IIntlProspectValue | undefined) => {
	return value ? round10(value?.xsurplusMillions, -1) : undefined;
};

export const getHasTwoWayProjections = (intlProspectValues: Array<IIntlProspectValue> | undefined): boolean => {
	// Filter for player's R4 Year
	const currentIntlProspectValues = intlProspectValues;

	// Check for batting projection
	const hasBattingProjection = Boolean(
		currentIntlProspectValues?.find((pv: IIntlProspectValue) => pv.playerType === PLAYER_TYPE_BATTER)
	);

	// Check for pitching projection
	const hasPitchingProjection = Boolean(
		currentIntlProspectValues?.find((pv: IIntlProspectValue) => pv.playerType === PLAYER_TYPE_PITCHER)
	);

	// If both exist
	return hasBattingProjection && hasPitchingProjection;
};

export const getIsTwoWayPlayer = (
	intlProspectValues: Array<IIntlProspectValue> | undefined,
	evals: $TSFixMeEval
): boolean => {
	// Check for two way projections
	if (getHasTwoWayProjections(intlProspectValues)) {
		return true;
	}

	// Check for both pitch and hit evals in the past two years
	const evalTypes =
		evals
			?.filter(
				(evaluation: $TSFixMeEval) =>
					evaluation.seen_date != null && evaluation.seen_date.year() >= dayjs().year() - 1
			)
			.map((evaluation: $TSFixMeEval) => evaluation.eval_type) ?? [];

	// Two way if both types exist
	return evalTypes.includes(INTL_HIT) && evalTypes.includes(INTL_PITCH);
};

export const getObservedIntlSeason = (_player: TPlayerPageCombinedPlayer): number => {
	// INTL players this is just the current year
	const today = dayjs();
	const thisYear = today.year();
	return thisYear;
};

export const getIntlDraftDate = (eligibleYear?: number | null): Dayjs => {
	return eligibleYear ? dayjs(`${eligibleYear}-08-31`) : dayjs(`${dayjs().year()}-08-31`);
};
