import { Machine, assign, Interpreter, AnyEventObject } from "xstate";
import { AxiosError, CancelTokenSource } from "axios";
import { CreateToastFnReturn } from "@chakra-ui/react";

import { DEFAULT_TOAST_ERROR_PROPS } from "_react/shared/_constants/toast";
import { displayAxiosErrorToast } from "_react/shared/_helpers/axios";
import { fetchPlayerMetricProbabilityDensities } from "_react/shared/data_models/metric/_network";
import { promiseWRetry } from "utils/helpers";
import { getCancelSource } from "utils/url_helpers";
import { IMetricProbabilityDensities, TMetricGroup } from "_react/shared/data_models/metric/_types";
import {
	IArsenalScoresThresholdApiResponse,
	IPlayerSeasonArsenalScoresSchema
} from "_react/shared/data_models/arsenal_scores/_types";
import {
	GAME_TYPE_OVERALL,
	PITCH_TYPE_OVERALL,
	BATS_OVERALL,
	COUNT_SPLIT_OVERALL,
	PLAYING_LEVEL_AMA,
	PLAYING_LEVEL_INTL
} from "_react/shared/data_models/arsenal_scores/_constants";

import { TXAxisExtremaProps } from "_react/shared/ui/presentation/plots/PitchMetricRidgelinePlot/PitchMetricRidgelinePlot";
import {
	TPitcherMetricRidgelinePrimaryPlotData,
	TPitcherMetricRidgelineSecondaryPlotData
} from "_react/shared/ui/data/plots/PitcherMetricRidgelinePlot/PitcherMetricRidgelinePlot";
import {
	fetchArsenalScoresThreshold,
	fetchPlayerSeasonArsenalScores
} from "_react/shared/data_models/arsenal_scores/_network";
import { TPlayingLevel } from "_react/shared/data_models/hitter_grades/_types";

const PROBABILITY_DENSITIES_CANCEL_SOURCE = "probabilityDensities";
const PLAYER_SEASON_ARSENAL_SCORES_CANCEL_SOURCE = "playerSeasonArsenalScores";
const ARSENAL_SCORES_THRESHOLD_CANCEL_SOURCE = "arsenalScoresThreshold";

export type TPitcherMetricRidgelinePlotCancelSource = {
	[PROBABILITY_DENSITIES_CANCEL_SOURCE]?: CancelTokenSource;
	[PLAYER_SEASON_ARSENAL_SCORES_CANCEL_SOURCE]?: CancelTokenSource;
	[ARSENAL_SCORES_THRESHOLD_CANCEL_SOURCE]?: CancelTokenSource;
};

export type TProbabilityDensities = Record<string, Array<IMetricProbabilityDensities> | null>;

export type TPitcherMetricRidgelinePlotContext = {
	batsFilter: string;
	throwsFilter?: string;
	xAxisExtrema?: TXAxisExtremaProps;
	playerId?: number;
	lastPlayerId?: number;
	playerClassification?: string;
	lastPlayerClassification?: string;
	playingLevel?: TPlayingLevel;
	lastPlayingLevel?: TPlayingLevel;
	metricGroup?: TMetricGroup;
	lastMetricGroup?: TMetricGroup;
	seasonFilter: number;
	lastSeasonFilter: number;
	shouldFetchPrimaryData?: boolean;
	shouldFetchSecondaryData?: boolean;
	probabilityDensities: TProbabilityDensities;
	playerSeasonArsenalScores: Array<IPlayerSeasonArsenalScoresSchema> | undefined;
	arsenalScoresThreshold?: Array<IArsenalScoresThresholdApiResponse> | null;
	cancelSources: TPitcherMetricRidgelinePlotCancelSource;
	toast?: CreateToastFnReturn;
};

interface IPitcherMetricRidgelinePlotStateSchema {
	states: {
		initializing: {};
		initialized: {
			states: {
				// Refreshes the context when the playerId prop changes
				playerIdRefresh: {
					states: {
						idle: {};
						clearing: {};
					};
				};
				// Fetches probability densities data for a player and metric group
				probabilityDensities: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
				playerSeasonArsenalScores: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
				// Fetches arsenal scores thresholds data
				arsenalScoresThreshold: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
			};
		};
	};
}

export const SET_PROBABILITY_DENSITIES = "SET_PROBABILITY_DENSITIES";
export const SET_PLAYER_SEASON_ARSENAL_SCORES = "SET_PLAYER_SEASON_ARSENAL_SCORES";
export const SET_ARSENAL_SCORES_THRESHOLD = "SET_ARSENAL_SCORES_THRESHOLD";
export const SET_PLAYER_ID = "SET_PLAYER_ID";
export const SET_PLAYER_CLASSIFICATION = "SET_PLAYER_CLASSIFICATION";
export const SET_PLAYING_LEVEL = "SET_PLAYING_LEVEL";
export const SET_METRIC_GROUP = "SET_METRIC_GROUP";
export const SET_BATS_FILTER = "SET_BATS_FILTER";
export const SET_THROWS_FILTER = "SET_THROWS_FILTER";
export const SET_SEASON_FILTER = "SET_SEASON_FILTER";
export const SET_X_AXIS_EXTREMA = "SET_X_AXIS_EXTREMA";
export const FETCHING_PROBABILITY_DENSITIES = {
	initialized: { probabilityDensities: "fetching" }
};
export const FETCHING_PLAYER_SEASON_ARSENAL_SCORES = {
	initialized: { playerSeasonArsenalScores: "fetching" }
};
export const FETCHING_ARSENAL_SCORES_THRESHOLD = { initialized: { arsenalScoresThreshold: "fetching" } };
const FETCH_PROBABILITY_DENSITIES_DONE = "done.invoke.fetchingProbabilityDensities:invocation[0]";
const FETCH_PLAYER_SEASON_ARSENAL_SCORES_DONE = "done.invoke.fetchingPlayerSeasonArsenalScores:invocation[0]";
const FETCH_ARSENAL_SCORES_THRESHOLD_DONE = "done.invoke.fetchingArsenalScoresThreshold:invocation[0]";

const FETCH_PROBABILITY_DENSITIES_ERROR = "error.platform.fetchingProbabilityDensities:invocation[0]";

type TSetProbabilityDensitiesEvent = {
	type: typeof SET_PROBABILITY_DENSITIES;
	data: TProbabilityDensities;
};
type TPlayerSeasonArsenalScoresEvent = {
	type: typeof SET_PLAYER_SEASON_ARSENAL_SCORES;
	data: Array<IPlayerSeasonArsenalScoresSchema> | undefined;
};
type TArsenalScoresThresholdEvent = {
	type: typeof SET_ARSENAL_SCORES_THRESHOLD;
	data: Array<IArsenalScoresThresholdApiResponse> | null | undefined;
};
type TSetPlayerIdEvent = {
	type: typeof SET_PLAYER_ID;
	data: number | undefined;
};
type TSetPlayerClassificationEvent = {
	type: typeof SET_PLAYER_CLASSIFICATION;
	data: string | undefined;
};
type TSetPlayingLevelEvent = {
	type: typeof SET_PLAYING_LEVEL;
	data: TPlayingLevel | undefined;
};
type TSetMetricGroupEvent = {
	type: typeof SET_METRIC_GROUP;
	data: TMetricGroup | undefined;
};
type TSetBatsFilterEvent = {
	type: typeof SET_BATS_FILTER;
	data: string;
};
type TSetThrowsFilterEvent = {
	type: typeof SET_THROWS_FILTER;
	data: string | undefined;
};
type TSetSeasonFilterEvent = {
	type: typeof SET_SEASON_FILTER;
	data: number;
};
type TSetXAxisExtremaEvent = {
	type: typeof SET_X_AXIS_EXTREMA;
	data: TXAxisExtremaProps;
};
type TFetchProbabilityDensitiesEvent = {
	type: typeof FETCH_PROBABILITY_DENSITIES_DONE;
	data?: Array<IMetricProbabilityDensities>;
};
type TFetchPlayerSeasonArsenalScoresEvent = {
	type: typeof FETCH_PLAYER_SEASON_ARSENAL_SCORES_DONE;
	data?: Array<IPlayerSeasonArsenalScoresSchema>;
};
type TFetchArsenalScoresThresholdEvent = {
	type: typeof FETCH_ARSENAL_SCORES_THRESHOLD_DONE;
	data?: Array<IArsenalScoresThresholdApiResponse>;
};
type TFetchProbabilityDensitiesErrorEvent = {
	type: typeof FETCH_PROBABILITY_DENSITIES_ERROR;
	data?: AxiosError | Error;
};

type TPitcherMetricRidgelinePlotEvent =
	| TSetProbabilityDensitiesEvent
	| TPlayerSeasonArsenalScoresEvent
	| TArsenalScoresThresholdEvent
	| TSetPlayerIdEvent
	| TSetPlayerClassificationEvent
	| TSetPlayingLevelEvent
	| TSetMetricGroupEvent
	| TSetBatsFilterEvent
	| TSetThrowsFilterEvent
	| TSetSeasonFilterEvent
	| TSetXAxisExtremaEvent
	| TFetchProbabilityDensitiesEvent
	| TFetchPlayerSeasonArsenalScoresEvent
	| TFetchArsenalScoresThresholdEvent
	| TFetchProbabilityDensitiesErrorEvent;

export type TPitcherMetricRidgelinePlotSend = Interpreter<
	TPitcherMetricRidgelinePlotContext,
	IPitcherMetricRidgelinePlotStateSchema,
	TPitcherMetricRidgelinePlotEvent
>["send"];

const PitcherMetricRidgelinePlotMachine = (
	batsFilterProp: string,
	seasonFilterProp: number,
	throwsFilterProp?: string,
	xAxisExtremaProp?: TXAxisExtremaProps,
	playerIdProp?: number,
	playerClassificationProp?: string,
	playingLevelProp?: TPlayingLevel,
	metricGroupProp?: TMetricGroup,
	shouldFetchPrimaryData = true,
	primaryData?: TPitcherMetricRidgelinePrimaryPlotData,
	shouldFetchSecondaryData = true,
	secondaryData?: TPitcherMetricRidgelineSecondaryPlotData,
	toastProp?: CreateToastFnReturn
) =>
	Machine<
		TPitcherMetricRidgelinePlotContext,
		IPitcherMetricRidgelinePlotStateSchema,
		TPitcherMetricRidgelinePlotEvent
	>(
		{
			id: "PitcherMetricRidgelinePlot",
			initial: "initializing",
			context: {
				batsFilter: batsFilterProp,
				throwsFilter: throwsFilterProp,
				seasonFilter: seasonFilterProp,
				lastSeasonFilter: seasonFilterProp,
				playerId: playerIdProp,
				lastPlayerId: playerIdProp,
				playerClassification: playerClassificationProp,
				lastPlayerClassification: playerClassificationProp,
				playingLevel: playingLevelProp,
				lastPlayingLevel: playingLevelProp,
				xAxisExtrema: xAxisExtremaProp,
				metricGroup: metricGroupProp,
				lastMetricGroup: metricGroupProp,
				shouldFetchPrimaryData: shouldFetchPrimaryData,
				shouldFetchSecondaryData: shouldFetchSecondaryData,
				probabilityDensities: primaryData?.probabilityDensities ?? {},
				playerSeasonArsenalScores: secondaryData?.playerSeasonArsenalScores,
				arsenalScoresThreshold: secondaryData?.arsenalScoresThreshold,
				cancelSources: {},
				toast: toastProp
			},
			states: {
				initializing: {
					always: {
						target: "initialized"
					}
				},
				initialized: {
					type: "parallel",
					on: {
						[SET_PROBABILITY_DENSITIES]: { actions: "setProbabilityDensities" },
						[SET_PLAYER_SEASON_ARSENAL_SCORES]: { actions: "setPlayerSeasonArsenalScores" },
						[SET_ARSENAL_SCORES_THRESHOLD]: { actions: "setArsenalScoresThreshold" },
						[SET_PLAYER_ID]: { actions: "setPlayerId" },
						[SET_PLAYER_CLASSIFICATION]: { actions: "setPlayerClassification" },
						[SET_PLAYING_LEVEL]: { actions: "setPlayingLevel" },
						[SET_METRIC_GROUP]: { actions: "setMetricGroup" },
						[SET_BATS_FILTER]: { actions: "setBatsFilter" },
						[SET_THROWS_FILTER]: { actions: "setThrowsFilter" },
						[SET_SEASON_FILTER]: { actions: "setSeasonFilter" },
						[SET_X_AXIS_EXTREMA]: { actions: "setXAxisExtrema" }
					},
					states: {
						playerIdRefresh: {
							initial: "idle",
							states: {
								idle: {
									always: { target: "clearing", cond: "shouldClearContext" }
								},
								clearing: {
									always: { target: "idle", actions: "clearContext" }
								}
							}
						},
						probabilityDensities: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchingProbabilityDensities",
												cond: "shouldFetchProbabilityDensities"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchingProbabilityDensities",
									entry: ["refreshProbabilityDensitiesCancelSource"],
									invoke: {
										src: "fetchProbabilityDensities",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchProbabilityDensitiesSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchProbabilityDensitiesErrored"
										}
									}
								}
							}
						},
						playerSeasonArsenalScores: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchingPlayerSeasonArsenalScores",
												cond: "shouldFetchPlayerSeasonArsenalScores"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchingPlayerSeasonArsenalScores",
									entry: ["refreshPlayerSeasonArsenalScoresCancelSource"],
									invoke: {
										src: "fetchPlayerSeasonArsenalScores",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchPlayerSeasonArsenalScoresSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchPlayerSeasonArsenalScoresErrored"
										}
									}
								}
							}
						},
						arsenalScoresThreshold: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchingArsenalScoresThreshold",
												cond: "shouldFetchArsenalScoresThreshold"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchingArsenalScoresThreshold",
									entry: ["refreshArsenalScoresThresholdCancelSource"],
									invoke: {
										src: "fetchArsenalScoresThreshold",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchArsenalScoresThresholdSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchArsenalScoresThresholdErrored"
										}
									}
								}
							}
						}
					}
				}
			}
		},
		{
			guards: {
				shouldClearContext: (
					context: TPitcherMetricRidgelinePlotContext,
					_event: TPitcherMetricRidgelinePlotEvent
				) =>
					context.playerId !== context.lastPlayerId ||
					context.playerClassification !== context.lastPlayerClassification ||
					context.playingLevel !== context.lastPlayingLevel ||
					context.metricGroup !== context.lastMetricGroup ||
					context.seasonFilter !== context.lastSeasonFilter,
				shouldFetchProbabilityDensities: (
					context: TPitcherMetricRidgelinePlotContext,
					_event: TPitcherMetricRidgelinePlotEvent
				) => {
					const { probabilityDensities, batsFilter, throwsFilter, playerId } = context;
					return (
						probabilityDensities[`${batsFilter}-${throwsFilter}`] === undefined &&
						shouldFetchPrimaryData &&
						playerId !== undefined
					);
				},
				shouldFetchPlayerSeasonArsenalScores: (
					context: TPitcherMetricRidgelinePlotContext,
					_event: TPitcherMetricRidgelinePlotEvent
				) => {
					const { playerSeasonArsenalScores, playerId, playingLevel } = context;
					return (
						playerSeasonArsenalScores === undefined &&
						shouldFetchSecondaryData &&
						playerId !== undefined &&
						playingLevel !== undefined
					);
				},
				shouldFetchArsenalScoresThreshold: (
					context: TPitcherMetricRidgelinePlotContext,
					_event: TPitcherMetricRidgelinePlotEvent
				) =>
					context.arsenalScoresThreshold === undefined &&
					(context.playingLevel === PLAYING_LEVEL_AMA || context.playingLevel === PLAYING_LEVEL_INTL) &&
					shouldFetchSecondaryData
			},
			actions: {
				setProbabilityDensities: assign<TPitcherMetricRidgelinePlotContext, TPitcherMetricRidgelinePlotEvent>({
					probabilityDensities: (
						context: TPitcherMetricRidgelinePlotContext,
						event: TPitcherMetricRidgelinePlotEvent
					) => {
						if (event.type !== SET_PROBABILITY_DENSITIES) return context.probabilityDensities;
						return event.data;
					},
					cancelSources: (
						context: TPitcherMetricRidgelinePlotContext,
						event: TPitcherMetricRidgelinePlotEvent
					) => {
						if (event.type !== SET_PROBABILITY_DENSITIES) return context.cancelSources;
						if (context.cancelSources[PROBABILITY_DENSITIES_CANCEL_SOURCE] != null)
							context.cancelSources[PROBABILITY_DENSITIES_CANCEL_SOURCE].cancel();
						delete context.cancelSources[PROBABILITY_DENSITIES_CANCEL_SOURCE];
						return context.cancelSources;
					}
				}),
				setPlayerSeasonArsenalScores: assign<
					TPitcherMetricRidgelinePlotContext,
					TPitcherMetricRidgelinePlotEvent
				>({
					playerSeasonArsenalScores: (
						context: TPitcherMetricRidgelinePlotContext,
						event: TPitcherMetricRidgelinePlotEvent
					) => {
						if (event.type !== SET_PLAYER_SEASON_ARSENAL_SCORES) return context.playerSeasonArsenalScores;
						return event.data;
					},
					cancelSources: (
						context: TPitcherMetricRidgelinePlotContext,
						event: TPitcherMetricRidgelinePlotEvent
					) => {
						if (event.type !== SET_PLAYER_SEASON_ARSENAL_SCORES) return context.cancelSources;
						if (context.cancelSources[PLAYER_SEASON_ARSENAL_SCORES_CANCEL_SOURCE] != null)
							context.cancelSources[PLAYER_SEASON_ARSENAL_SCORES_CANCEL_SOURCE].cancel();
						delete context.cancelSources[PLAYER_SEASON_ARSENAL_SCORES_CANCEL_SOURCE];
						return context.cancelSources;
					}
				}),
				setArsenalScoresThreshold: assign<TPitcherMetricRidgelinePlotContext, TPitcherMetricRidgelinePlotEvent>(
					{
						arsenalScoresThreshold: (
							context: TPitcherMetricRidgelinePlotContext,
							event: TPitcherMetricRidgelinePlotEvent
						) => {
							if (event.type !== SET_ARSENAL_SCORES_THRESHOLD) return context.arsenalScoresThreshold;
							return event.data;
						},
						cancelSources: (
							context: TPitcherMetricRidgelinePlotContext,
							event: TPitcherMetricRidgelinePlotEvent
						) => {
							if (event.type !== SET_ARSENAL_SCORES_THRESHOLD) return context.cancelSources;
							if (context.cancelSources[ARSENAL_SCORES_THRESHOLD_CANCEL_SOURCE] != null)
								context.cancelSources[ARSENAL_SCORES_THRESHOLD_CANCEL_SOURCE].cancel();
							delete context.cancelSources[ARSENAL_SCORES_THRESHOLD_CANCEL_SOURCE];
							return context.cancelSources;
						}
					}
				),
				setPlayerId: assign<TPitcherMetricRidgelinePlotContext, TPitcherMetricRidgelinePlotEvent>({
					playerId: (
						context: TPitcherMetricRidgelinePlotContext,
						event: TPitcherMetricRidgelinePlotEvent
					) => {
						if (event.type !== SET_PLAYER_ID) return context.playerId;
						return event.data;
					}
				}),
				setPlayerClassification: assign<TPitcherMetricRidgelinePlotContext, TPitcherMetricRidgelinePlotEvent>({
					playerClassification: (
						context: TPitcherMetricRidgelinePlotContext,
						event: TPitcherMetricRidgelinePlotEvent
					) => {
						if (event.type !== SET_PLAYER_CLASSIFICATION) return context.playerClassification;
						return event.data;
					}
				}),
				setPlayingLevel: assign<TPitcherMetricRidgelinePlotContext, TPitcherMetricRidgelinePlotEvent>({
					playingLevel: (
						context: TPitcherMetricRidgelinePlotContext,
						event: TPitcherMetricRidgelinePlotEvent
					) => {
						if (event.type !== SET_PLAYING_LEVEL) return context.playingLevel;
						return event.data;
					}
				}),
				setMetricGroup: assign<TPitcherMetricRidgelinePlotContext, TPitcherMetricRidgelinePlotEvent>({
					metricGroup: (
						context: TPitcherMetricRidgelinePlotContext,
						event: TPitcherMetricRidgelinePlotEvent
					) => {
						if (event.type !== SET_METRIC_GROUP) return context.metricGroup;
						return event.data;
					}
				}),
				setBatsFilter: assign<TPitcherMetricRidgelinePlotContext, TPitcherMetricRidgelinePlotEvent>({
					batsFilter: (
						context: TPitcherMetricRidgelinePlotContext,
						event: TPitcherMetricRidgelinePlotEvent
					) => {
						if (event.type !== SET_BATS_FILTER) return context.batsFilter;
						return event.data;
					}
				}),
				setThrowsFilter: assign<TPitcherMetricRidgelinePlotContext, TPitcherMetricRidgelinePlotEvent>({
					throwsFilter: (
						context: TPitcherMetricRidgelinePlotContext,
						event: TPitcherMetricRidgelinePlotEvent
					) => {
						if (event.type !== SET_THROWS_FILTER) return context.throwsFilter;
						return event.data;
					}
				}),
				setSeasonFilter: assign<TPitcherMetricRidgelinePlotContext, TPitcherMetricRidgelinePlotEvent>({
					seasonFilter: (
						context: TPitcherMetricRidgelinePlotContext,
						event: TPitcherMetricRidgelinePlotEvent
					) => {
						if (event.type !== SET_SEASON_FILTER) return context.seasonFilter;
						return event.data;
					}
				}),
				setXAxisExtrema: assign<TPitcherMetricRidgelinePlotContext, TPitcherMetricRidgelinePlotEvent>({
					xAxisExtrema: (
						context: TPitcherMetricRidgelinePlotContext,
						event: TPitcherMetricRidgelinePlotEvent
					) => {
						if (event.type !== SET_X_AXIS_EXTREMA) return context.xAxisExtrema;
						return event.data;
					}
				}),
				clearContext: assign<TPitcherMetricRidgelinePlotContext, TPitcherMetricRidgelinePlotEvent>({
					lastPlayerId: (
						context: TPitcherMetricRidgelinePlotContext,
						_event: TPitcherMetricRidgelinePlotEvent
					) => context.playerId,
					lastPlayerClassification: (
						context: TPitcherMetricRidgelinePlotContext,
						_event: TPitcherMetricRidgelinePlotEvent
					) => context.playerClassification,
					lastPlayingLevel: (
						context: TPitcherMetricRidgelinePlotContext,
						_event: TPitcherMetricRidgelinePlotEvent
					) => context.lastPlayingLevel,
					lastMetricGroup: (
						context: TPitcherMetricRidgelinePlotContext,
						_event: TPitcherMetricRidgelinePlotEvent
					) => context.metricGroup,
					lastSeasonFilter: (
						context: TPitcherMetricRidgelinePlotContext,
						_event: TPitcherMetricRidgelinePlotEvent
					) => context.seasonFilter,
					probabilityDensities: (
						_context: TPitcherMetricRidgelinePlotContext,
						_event: TPitcherMetricRidgelinePlotEvent
					) => {
						return {};
					},
					playerSeasonArsenalScores: (
						_context: TPitcherMetricRidgelinePlotContext,
						_event: TPitcherMetricRidgelinePlotEvent
					) => {
						return undefined;
					},
					cancelSources: (
						context: TPitcherMetricRidgelinePlotContext,
						_event: TPitcherMetricRidgelinePlotEvent
					) => {
						Object.values(context.cancelSources).forEach((tokenSource: CancelTokenSource) =>
							tokenSource.cancel()
						);
						return {};
					}
				}),
				// Cancel Source Actions
				refreshProbabilityDensitiesCancelSource: assign<
					TPitcherMetricRidgelinePlotContext,
					TPitcherMetricRidgelinePlotEvent
				>({
					cancelSources: (
						context: TPitcherMetricRidgelinePlotContext,
						_event: TPitcherMetricRidgelinePlotEvent
					) => {
						if (context.cancelSources[PROBABILITY_DENSITIES_CANCEL_SOURCE] != null)
							context.cancelSources[PROBABILITY_DENSITIES_CANCEL_SOURCE].cancel();
						context.cancelSources[PROBABILITY_DENSITIES_CANCEL_SOURCE] = getCancelSource();
						return context.cancelSources;
					}
				}),
				refreshPlayerSeasonArsenalScoresCancelSource: assign<
					TPitcherMetricRidgelinePlotContext,
					TPitcherMetricRidgelinePlotEvent
				>({
					cancelSources: (
						context: TPitcherMetricRidgelinePlotContext,
						_event: TPitcherMetricRidgelinePlotEvent
					) => {
						if (context.cancelSources[PLAYER_SEASON_ARSENAL_SCORES_CANCEL_SOURCE] != null)
							context.cancelSources[PLAYER_SEASON_ARSENAL_SCORES_CANCEL_SOURCE].cancel();
						context.cancelSources[PLAYER_SEASON_ARSENAL_SCORES_CANCEL_SOURCE] = getCancelSource();
						return context.cancelSources;
					}
				}),
				refreshArsenalScoresThresholdCancelSource: assign<
					TPitcherMetricRidgelinePlotContext,
					TPitcherMetricRidgelinePlotEvent
				>({
					cancelSources: (
						context: TPitcherMetricRidgelinePlotContext,
						_event: TPitcherMetricRidgelinePlotEvent
					) => {
						if (context.cancelSources[ARSENAL_SCORES_THRESHOLD_CANCEL_SOURCE] != null)
							context.cancelSources[ARSENAL_SCORES_THRESHOLD_CANCEL_SOURCE].cancel();
						context.cancelSources[ARSENAL_SCORES_THRESHOLD_CANCEL_SOURCE] = getCancelSource();
						return context.cancelSources;
					}
				}),
				// Fetch Success Actions
				handleFetchProbabilityDensitiesSuccess: assign<
					TPitcherMetricRidgelinePlotContext,
					TPitcherMetricRidgelinePlotEvent
				>({
					probabilityDensities: (
						context: TPitcherMetricRidgelinePlotContext,
						event: TPitcherMetricRidgelinePlotEvent
					) => {
						const { probabilityDensities, batsFilter, throwsFilter } = context;
						if (event.type !== FETCH_PROBABILITY_DENSITIES_DONE || event.data === undefined)
							return probabilityDensities;
						// Use the bats filter of the returned data to make sure we have the correct data
						// otherwise default to the context's bats filter
						const bats = event.data?.length ? event.data[0].requestArgs.bats ?? BATS_OVERALL : batsFilter;
						// Repeat the process with the throws filter
						const throws = event.data?.length
							? event.data[0].requestArgs.throws ?? throwsFilter
							: throwsFilter;
						return {
							...probabilityDensities,
							[`${bats}-${throws}`]: event.data
						};
					}
				}),
				handleFetchPlayerSeasonArsenalScoresSuccess: assign<
					TPitcherMetricRidgelinePlotContext,
					TPitcherMetricRidgelinePlotEvent
				>({
					playerSeasonArsenalScores: (
						context: TPitcherMetricRidgelinePlotContext,
						event: TPitcherMetricRidgelinePlotEvent
					) => {
						const { playerSeasonArsenalScores } = context;
						if (event.type !== FETCH_PLAYER_SEASON_ARSENAL_SCORES_DONE) return playerSeasonArsenalScores;
						return event.data;
					}
				}),
				handleFetchArsenalScoresThresholdSuccess: assign<
					TPitcherMetricRidgelinePlotContext,
					TPitcherMetricRidgelinePlotEvent
				>({
					arsenalScoresThreshold: (
						context: TPitcherMetricRidgelinePlotContext,
						event: TPitcherMetricRidgelinePlotEvent
					) => {
						if (event.type !== FETCH_ARSENAL_SCORES_THRESHOLD_DONE) return context.arsenalScoresThreshold;
						return event.data;
					}
				}),
				// Fetch Errored Actions
				handleFetchProbabilityDensitiesErrored: (
					context: TPitcherMetricRidgelinePlotContext,
					event: TPitcherMetricRidgelinePlotEvent
				) => {
					displayAxiosErrorToast(
						event.type === FETCH_PROBABILITY_DENSITIES_ERROR ? event.data : undefined,
						context.toast,
						"Probability Densities",
						"Error fetching probability densities data."
					);
				},
				handleFetchPlayerSeasonArsenalScoresErrored: (
					context: TPitcherMetricRidgelinePlotContext,
					_event: TPitcherMetricRidgelinePlotEvent
				) => {
					if (context.toast)
						context.toast({
							title: "Seasonal Arsenal Scores Pitch Type",
							description: "Error fetching seasonal arsenal scores pitch type.",
							...DEFAULT_TOAST_ERROR_PROPS
						});
				},
				handleFetchArsenalScoresThresholdErrored: (
					context: TPitcherMetricRidgelinePlotContext,
					_event: TPitcherMetricRidgelinePlotEvent
				) => {
					if (context.toast)
						context.toast({
							title: "Arsenal Scores Thresholds",
							description: "Error fetching arsenal scores thresholds data.",
							...DEFAULT_TOAST_ERROR_PROPS
						});
				}
			},
			services: {
				fetchProbabilityDensities: (context: TPitcherMetricRidgelinePlotContext, _event: AnyEventObject) => {
					const {
						playerId,
						playerClassification,
						metricGroup,
						batsFilter,
						throwsFilter,
						seasonFilter,
						probabilityDensities
					} = context;
					const existingData = probabilityDensities[`${batsFilter}-${throwsFilter}`];
					if (existingData) return Promise.resolve(existingData);
					if (!playerId || !metricGroup) return Promise.resolve(undefined);
					const fetchFunc = () =>
						fetchPlayerMetricProbabilityDensities(
							{
								playerId: playerId,
								playerClassification: playerClassification,
								// BATS_OVERALL is equivalent to having no `bats` filter
								bats: batsFilter !== BATS_OVERALL ? batsFilter : undefined,
								throws: throwsFilter,
								metricGroup: metricGroup,
								season: seasonFilter,
								minPrediction: xAxisExtremaProp?.min,
								maxPrediction: xAxisExtremaProp?.max,
								isUseCache: true
							},
							context.cancelSources[PROBABILITY_DENSITIES_CANCEL_SOURCE]?.token
						);
					return promiseWRetry(fetchFunc);
				},
				fetchPlayerSeasonArsenalScores: (
					context: TPitcherMetricRidgelinePlotContext,
					_event: AnyEventObject
				) => {
					const { playerId, playingLevel, seasonFilter } = context;
					if (!playerId || !playingLevel) return Promise.resolve(undefined);
					const fetchFunc = () =>
						fetchPlayerSeasonArsenalScores(
							{
								playerId: playerId,
								playingLevel: playingLevel,
								season: seasonFilter,
								gameType: GAME_TYPE_OVERALL,
								"pitchType[neq]": PITCH_TYPE_OVERALL,
								countSplit: COUNT_SPLIT_OVERALL,
								isUseCache: true
							},
							context.cancelSources[PLAYER_SEASON_ARSENAL_SCORES_CANCEL_SOURCE]?.token
						);
					return promiseWRetry(fetchFunc);
				},
				fetchArsenalScoresThreshold: (context: TPitcherMetricRidgelinePlotContext, _event: AnyEventObject) => {
					const fetchFunc = () =>
						fetchArsenalScoresThreshold(
							context.cancelSources[ARSENAL_SCORES_THRESHOLD_CANCEL_SOURCE]?.token
						);
					return promiseWRetry(fetchFunc);
				}
			}
		}
	);

export default PitcherMetricRidgelinePlotMachine;
