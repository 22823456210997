export const getLocationThresholdTooltip = (sampleSize?: number | null, threshold?: number): string | undefined => {
	if (sampleSize && threshold) {
		return `Location grades are not reliable when there are less than ${threshold} pitches in the sample. \n\nThis location grade contains ${sampleSize} pitches.`;
	}
	return undefined;
};

export const getStuffThresholdTooltip = (sampleSize?: number | null, threshold?: number): string | undefined => {
	if (sampleSize && threshold) {
		return `Stuff grades are not reliable when there are less than ${threshold} pitches in the sample. \n\nThis stuff grade contains ${sampleSize} pitches.`;
	}
	return undefined;
};

export const getAttackAngleThresholdTooltip = (sampleSize?: number | null, threshold?: number): string | undefined => {
	if (sampleSize && threshold) {
		return `Attack angle is not reliable when there are less than ${threshold} balls in play (BIP) in the sample. \n\nThis sample contains ${sampleSize} BIP.`;
	}
	return undefined;
};

export const getBatSpeedThresholdTooltip = (sampleSize?: number | null, threshold?: number): string | undefined => {
	if (sampleSize && threshold) {
		return `Bat speed is not reliable when there are less than ${threshold} balls in play (BIP) in the sample. \n\nThis sample contains ${sampleSize} BIP.`;
	}
	return undefined;
};

export const getBatToBallThresholdTooltip = (sampleSize?: number | null, threshold?: number): string | undefined => {
	if (sampleSize && threshold) {
		return `Bat to ball is not reliable when there are less than ${threshold} swings in the sample. \n\nThis sample contains ${sampleSize} swings.`;
	}
	return undefined;
};

export const getSwingDecisionThresholdTooltip = (
	sampleSize?: number | null,
	threshold?: number
): string | undefined => {
	if (sampleSize && threshold) {
		return `Swing decisions are not reliable when there are less than ${threshold} pitches in the sample. \n\nThis sample contains ${sampleSize} pitches.`;
	}
	return undefined;
};

export const getManualPitchTypeMapBySeasonTooltip = (pitchTypeFrom?: string): string | undefined => {
	if (pitchTypeFrom) {
		return `Includes pitches originally tagged as ${pitchTypeFrom}`;
	}
};
