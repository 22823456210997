import React from "react";
import { useToast } from "@chakra-ui/react";

import { $TSFixMeEval } from "_react/evals/shared/_types";
import { PlayerProSummary } from "_react/shared/legacy/globalcontext/player/_types";
import { useCurrentSeason, useProjectionsSeason } from "_react/shared/_helpers/hooks";
import { TPlayerPagePlayerClassification } from "_react/playerpage/_types";

import { TPlayerPageCombinedPlayer } from "_react/playerpage/_types";
import PlayerPageProContent from "_react/playerpage/pro/content/PlayerPageProContent";
import PlayerPageProProvider from "_react/playerpage/pro/PlayerPageProProvider";

type PlayerPageProProps = {
	player?: TPlayerPageCombinedPlayer | null;
	// TODO get rid of this playerLegacy prop completely when legacy components are refactored or removed
	playerLegacy?: PlayerProSummary;
	evals: $TSFixMeEval[] | null | undefined;
	noteCount: number;
	isNotesLoading?: boolean;
	isNotesFailed?: boolean;
	setViewClassification?: (viewClassification?: TPlayerPagePlayerClassification) => void;
	viewClassification: string | null;
	setView?: (key: string, isReplaceState?: boolean) => void;
	view?: string;
	setIsBeta?: (isBeta: string | null, isReplaceState?: boolean) => void;
	hasProjectionsPermissions?: boolean;
	hasPlayerPlanPermissions?: boolean;
	hasPitchTypeMappingEditPermissions?: boolean;
	hasIntlPlayerPagesBetaPermissions?: boolean;
};

const PlayerPagePro = ({
	player,
	playerLegacy,
	evals,
	noteCount,
	isNotesLoading,
	isNotesFailed,
	setViewClassification,
	viewClassification,
	setView,
	view,
	setIsBeta,
	hasProjectionsPermissions = false,
	hasPlayerPlanPermissions = false,
	hasPitchTypeMappingEditPermissions = false,
	hasIntlPlayerPagesBetaPermissions = false
}: PlayerPageProProps) => {
	const toast = useToast();
	const projectionsSeasonData = useProjectionsSeason();
	const [currentSeason, isFetchingCurrentSeason] = useCurrentSeason();

	return (
		<PlayerPageProProvider
			playerId={player?.id}
			projectionsSeasonData={projectionsSeasonData}
			currentSeasonData={{
				currentSeason: currentSeason,
				isFetching: isFetchingCurrentSeason
			}}
			toast={toast}
		>
			<PlayerPageProContent
				player={player}
				playerLegacy={playerLegacy}
				evals={evals}
				noteCount={noteCount}
				isNotesLoading={isNotesLoading}
				isNotesFailed={isNotesFailed}
				setViewClassification={setViewClassification}
				viewClassification={viewClassification}
				setView={setView}
				view={view}
				setIsBeta={setIsBeta}
				hasProjectionsPermissions={hasProjectionsPermissions}
				hasPlayerPlanPermissions={hasPlayerPlanPermissions}
				hasPitchTypeMappingEditPermissions={hasPitchTypeMappingEditPermissions}
				hasIntlPlayerPagesBetaPermissions={hasIntlPlayerPagesBetaPermissions}
			/>
		</PlayerPageProProvider>
	);
};

export default PlayerPagePro;
