import React, { useEffect } from "react";
import { SystemStyleObject, useToast } from "@chakra-ui/react";
import { useMachine } from "@xstate/react";
import { HStack, Box } from "@chakra-ui/react";
import dayjs from "dayjs";

import { PITCH_TYPE_LABEL_MAP } from "_react/shared/_constants/pitch_types";
import { sortPitchTypesByPitchGroup } from "_react/shared/_helpers/pitch_types";
import { TPitchTypes } from "_react/shared/_types/pitch_types";
import { ICON_SQUARE } from "_react/shared/ui/presentation/components/PitchTypeLabel/_constants";
import PitchTypeLabel from "_react/shared/ui/presentation/components/PitchTypeLabel/PitchTypeLabel";

import createPlayerPitchTypeLabelsMachine, {
	TPlayerPitchTypeLabelsContext,
	SET_PLAYER_ID,
	SET_SEASON_FILTER,
	SET_PITCH_TYPES,
	FETCHING_PITCH_TYPES,
	SET_PLAYER_CLASSIFICATION
} from "_react/shared/ui/data/other/PlayerPitchTypeLabels/_machine";

export type TPlayerPitchTypeLabelsStyle = {
	container?: SystemStyleObject;
};

export type TPlayerPitchTypeLabelsData = {
	pitchTypes?: Array<TPitchTypes> | null;
	isLoading?: boolean;
};

type TPlayerPitchTypeLabelsProps = {
	playerId?: number;
	playerClassification?: string;
	seasonFilter?: number;
	shouldFetchData?: boolean;
	data?: TPlayerPitchTypeLabelsData;
	style?: TPlayerPitchTypeLabelsStyle;
};

const PlayerPitchTypeLabels = ({
	playerId,
	playerClassification,
	seasonFilter = dayjs().year(),
	data,
	shouldFetchData = true,
	style
}: TPlayerPitchTypeLabelsProps) => {
	const toast = useToast();
	const [current, send] = useMachine(
		createPlayerPitchTypeLabelsMachine(seasonFilter, playerId, playerClassification, shouldFetchData, data, toast)
	);
	const { pitchTypes } = current.context as TPlayerPitchTypeLabelsContext;

	const fetchingPitchTypes = current.matches(FETCHING_PITCH_TYPES);
	const isLoading = shouldFetchData ? fetchingPitchTypes : data?.isLoading;

	// Update machine context when props change
	useEffect(() => {
		send({ type: SET_PLAYER_ID, data: playerId });
	}, [playerId, send]);

	useEffect(() => {
		send({ type: SET_PLAYER_CLASSIFICATION, data: playerClassification });
	}, [playerClassification, send]);

	useEffect(() => {
		send({ type: SET_SEASON_FILTER, data: seasonFilter });
	}, [seasonFilter, send]);

	useEffect(() => {
		if (data?.pitchTypes !== pitchTypes && !shouldFetchData) {
			send({ type: SET_PITCH_TYPES, data: data?.pitchTypes });
		}
	}, [data?.pitchTypes, shouldFetchData, send, pitchTypes]);

	return (
		<>
			{isLoading && <Box className="loading-item" height="5" width="md" sx={style?.container} />}
			{!isLoading && (
				<HStack gap="6" flexWrap="wrap" sx={style?.container}>
					{pitchTypes?.sort(sortPitchTypesByPitchGroup)?.map((pitchType: TPitchTypes) => (
						<PitchTypeLabel
							key={pitchType}
							label={PITCH_TYPE_LABEL_MAP[pitchType]}
							abbreviation={pitchType}
							shape={ICON_SQUARE}
						/>
					))}
				</HStack>
			)}
		</>
	);
};

export default PlayerPitchTypeLabels;
