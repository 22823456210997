/* eslint-disable no-useless-computed-key */
import React from "react";
import dayjs from "dayjs";
import { Box, Flex, Text, Tooltip } from "@chakra-ui/react";

import Info from "_react/shared/legacy/ui/icons/Info";
import BarChart from "_react/shared/ui/icons/BarChart";
import Batter from "_react/shared/ui/icons/Batter";
import BatSpeed from "_react/shared/ui/icons/BatSpeed";
import BatToBall from "_react/shared/ui/icons/BatToBall";
import Catcher from "_react/shared/ui/icons/Catcher";
import ChestProtector from "_react/shared/ui/icons/ChestProtector";
import FuelPump from "_react/shared/ui/icons/FuelPump";
import Grounder from "_react/shared/ui/icons/Grounder";
import HammerAndWrench from "_react/shared/ui/icons/HammerAndWrench";
import PersonDeciding from "_react/shared/ui/icons/PersonDeciding";
import PersonThrowing from "_react/shared/ui/icons/PersonThrowing";
import SwingDecisions from "_react/shared/ui/icons/SwingDecisions";
import Target from "_react/shared/ui/icons/Target";
import TeamLevelBadge from "_react/shared/ui/presentation/components/TeamLevelBadge/TeamLevelBadge";
import { TagStyle } from "_react/playerplan/shared/_styles";
import {
	TPlayerPlanTarget,
	TPlayerPlanNote,
	TGoalForm,
	TSecondaryGoalForm,
	TChange
} from "_react/playerplan/shared/_types";
import {
	createGoalStatement,
	getChangeDateCellSortValue,
	getChangeDateCellValue,
	getDepartmentCellValue,
	getFocusAreaCellValue,
	getGoalCellValue,
	getSupportingActionCellValue
} from "_react/playerplan/shared/_helpers";

// IAM groups
export const PLAYER_PLAN_FO_IAM_GROUP = 87;
export const PLAYER_PLAN_FOP_IAM_GROUP = 95;
export const PLAYER_PLAN_COORDINATOR_IAM_GROUP = 96;
export const PLAYER_PLAN_ANALYST_IAM_GROUP = 103;
export const PLAYER_PLAN_COACH_IAM_GROUP = 97;
export const PLAYER_PLAN_READ_ONLY_IAM_GROUP = 116;
export const PLAYER_PLAN_SUBCOMMITTEE_GROUP = 125;
export const PLAYER_PLAN_GOAL_APPROVAL_GROUP = 126;

export const PLAYER_PLAN_CALL_LOG_USER_SELECT_GROUPS = [
	PLAYER_PLAN_FO_IAM_GROUP,
	PLAYER_PLAN_FOP_IAM_GROUP,
	PLAYER_PLAN_COORDINATOR_IAM_GROUP,
	PLAYER_PLAN_COACH_IAM_GROUP,
	PLAYER_PLAN_SUBCOMMITTEE_GROUP,
	PLAYER_PLAN_GOAL_APPROVAL_GROUP
];

export const PLAYER_PLAN_NOTE_USER_SELECT_GROUPS = [
	PLAYER_PLAN_FO_IAM_GROUP,
	PLAYER_PLAN_FOP_IAM_GROUP,
	PLAYER_PLAN_COORDINATOR_IAM_GROUP,
	PLAYER_PLAN_ANALYST_IAM_GROUP,
	PLAYER_PLAN_COACH_IAM_GROUP,
	PLAYER_PLAN_SUBCOMMITTEE_GROUP,
	PLAYER_PLAN_GOAL_APPROVAL_GROUP
];

export const PLAYER_PLAN_IAM_GROUPS = [
	PLAYER_PLAN_FO_IAM_GROUP,
	PLAYER_PLAN_FOP_IAM_GROUP,
	PLAYER_PLAN_COORDINATOR_IAM_GROUP,
	PLAYER_PLAN_ANALYST_IAM_GROUP,
	PLAYER_PLAN_COACH_IAM_GROUP,
	PLAYER_PLAN_READ_ONLY_IAM_GROUP,
	PLAYER_PLAN_SUBCOMMITTEE_GROUP,
	PLAYER_PLAN_GOAL_APPROVAL_GROUP
];

// Style constants
export const DISABLED_OPACITY = "0.3";

// Date constants

// Manuels has a new framework for data starting in the 2023 season.
// On this date, we mass uploaded data for the 2023 season, all of which fit the 2023 framework
// Using this constant, we can filter out any data that was created before then
export const NEW_FRAMEWORK_YEAR_2023 = 2023;
export const NEW_FRAMEWORK_DATE_2023 = `${NEW_FRAMEWORK_YEAR_2023}-03-29`;
// Min Recent Changes table date is 1 day after the new framework date,
// so that users don't see the mass uploaded data on that day
export const MIN_RECENT_CHANGES_DATE = dayjs(NEW_FRAMEWORK_DATE_2023)
	.add(1, "day")
	.format("YYYY-MM-DD");

// NULL text filler
export const NULL_TEXT = "-";

// Goal Statuses
export const DRAFT = "draft";
export const IN_PROGRESS = "in_progress";
export const COMPLETE = "complete";
export const CANCELED = "canceled";

export const FETCH_STATUSES = [DRAFT, IN_PROGRESS, COMPLETE];

export const ALL_STATUSES = [DRAFT, IN_PROGRESS, COMPLETE, CANCELED];

export const LK_SELECT_STATUSES = [IN_PROGRESS, COMPLETE, CANCELED];

// Masked label for draft statuses
export const DRAFT_MASKED_LABEL = "Pending";

// Goal Status Options
export const GOAL_STATUS_FILTER_ALL = "all";
export const GOAL_STATUS_FILTER_IN_PROGRESS = "in_progress";
export const GOAL_STATUS_FILTER_OPTIONS = [
	{ value: GOAL_STATUS_FILTER_ALL, display: "All" },
	{ value: GOAL_STATUS_FILTER_IN_PROGRESS, display: "In Progress" }
];

// Metric Types
export const ATHLETIC_TRAINING = "ath_train";
export const CATCHING = "catching";
export const HIT = "hit";
export const INFIELD = "infield";
export const MENTAL_PERFORMANCE = "mental";
export const NUTRITION = "nutrition";
export const OUTFIELD = "outfield";
export const PITCH = "pitch";
export const STRENGTH_AND_CONDITIONING = "str_cond";
export const BASERUNNING = "baserunning";

// Baseball vs non-baseball metric types
export const BASEBALL_METRIC_LKS = [HIT, PITCH, CATCHING, INFIELD, OUTFIELD];

export const NON_BASEBALL_METRIC_LKS = [ATHLETIC_TRAINING, MENTAL_PERFORMANCE, NUTRITION, STRENGTH_AND_CONDITIONING];

// Metric Type Options
export const METRIC_TYPE_OPTIONS = [
	{ value: "", display: "All" },
	{ value: ATHLETIC_TRAINING, display: "Athletic Training" },
	{ value: BASERUNNING, display: "Baserunning" },
	{ value: CATCHING, display: "Catching" },
	{ value: HIT, display: "Hitting" },
	{ value: INFIELD, display: "Infield" },
	{ value: MENTAL_PERFORMANCE, display: "Mental Performance" },
	{ value: NUTRITION, display: "Nutrition" },
	{ value: OUTFIELD, display: "Outfield" },
	{ value: PITCH, display: "Pitching" },
	{ value: STRENGTH_AND_CONDITIONING, display: "Str & Cond" }
];

// Metric Type to Color for Notes
export const METRIC_TYPE_TO_COLOR = {
	[HIT]: "#000075", // navy
	[PITCH]: "#000075", // navy
	[BASERUNNING]: "#9A6324", // brown
	[CATCHING]: "#029614", // green
	[INFIELD]: "#029614", // green
	[OUTFIELD]: "#029614", // green
	[ATHLETIC_TRAINING]: "#800000", // maroon
	[STRENGTH_AND_CONDITIONING]: "#808000", // olive
	[MENTAL_PERFORMANCE]: "#911eb4", // purple
	[NUTRITION]: "#469990" // teal
} as Record<string, string>;

// Metric Subtypes
export const BAT_PATH_SUBTYPE = "bat_path";
export const BAT_SPEED_SUBTYPE = "bat_speed";
export const BAT_TO_BALL_SUBTYPE = "bat_to_ball";
export const BLOCKING_SUBTYPE = "blocking";
export const COMMAND_SUBTYPE = "command";
export const DECISIONS_SUBTYPE = "decisions";
export const ENGINE_SUBTYPE = "engine";
export const GLOVE_WORK_SUBTYPE = "glove_work";
export const RANGE_SUBTYPE = "range";
export const RECEIVING_SUBTYPE = "receiving";
export const STUFF_SUBTYPE = "stuff";
export const SWING_DECISIONS_SUBTYPE = "swing_dec";
export const THROWING_SUBTYPE = "throwing";

// Icons for goal statements
export const METRIC_SUBTYPE_TO_ICON_MAPPING: Record<string, React.ReactElement> = {
	[BAT_PATH_SUBTYPE]: <Batter />,
	[BAT_SPEED_SUBTYPE]: <BatSpeed />,
	[BAT_TO_BALL_SUBTYPE]: <BatToBall />,
	[BLOCKING_SUBTYPE]: <ChestProtector />,
	[COMMAND_SUBTYPE]: <Target />,
	[DECISIONS_SUBTYPE]: <BarChart />,
	[ENGINE_SUBTYPE]: <FuelPump />,
	[GLOVE_WORK_SUBTYPE]: <Grounder />,
	[RANGE_SUBTYPE]: <PersonDeciding />,
	[RECEIVING_SUBTYPE]: <Catcher />,
	[STUFF_SUBTYPE]: <HammerAndWrench />,
	[SWING_DECISIONS_SUBTYPE]: <SwingDecisions />,
	[THROWING_SUBTYPE]: <PersonThrowing />
};

// Metric IDs
export const BAT_SPEED_ID = 3;
export const BAT_TO_BALL_ID = 5;
export const SWING_DEC_ID = 6;
export const STUFF_ID = 12;
export const ATT_ANG_ID = 42;
export const LOCATIONS_ID = 90;
export const USAGE_ID = 108;
export const FB_VELO_ID = 271;

//
// Metric Thresholds
//

// Thresholds for 2080 metrics
export const THRESHOLD_GREAT_2080 = 5;
export const THRESHOLD_GOOD_2080 = 0;
export const THRESHOLD_NEUTRAL_2080 = -10;
export const THRESHOLD_BAD_2080 = -15;

// Thresholds for FB Velo
export const THRESHOLD_GREAT_FB_VELO = 1;
export const THRESHOLD_GOOD_FB_VELO = 0;
export const THRESHOLD_NEUTRAL_FB_VELO = -1;
export const THRESHOLD_BAD_FB_VELO = -2;

// Thresholds for Usage (Absolute Value)
export const THRESHOLD_GREAT_USAGE = 0.025;
export const THRESHOLD_GOOD_USAGE = 0.05;
export const THRESHOLD_NEUTRAL_USAGE = 0.1;
export const THRESHOLD_BAD_USAGE = 0.15;

// Thresholds for Attack Angle (Absolute Value)
export const THRESHOLD_GREAT_ATT_ANG = 1;
export const THRESHOLD_GOOD_ATT_ANG = 3;
export const THRESHOLD_NEUTRAL_ATT_ANG = 5;
export const THRESHOLD_BAD_ATT_ANG = 7;

// Season select
export const SEASON_SELECT_OPTIONS = [
	{ label: dayjs().year(), value: dayjs().year() },
	{ label: dayjs().year() + 1, value: dayjs().year() + 1 }
];

// Split types
export const PITCH_TYPE = "pitch_type";
export const PITCH_TYPE_GROUP = "pitch_type_group";
export const COUNT = "count";
export const HANDEDNESS = "handedness";

// Modal Types
export const NOTES_MODAL_TYPE = "notes";
export const ATTACHMENTS_MODAL_TYPE = "attachments";
export const ADD_GOAL_MODAL_TYPE = "add_goal";
export const APPROVE_GOAL_MODAL_TYPE = "approve_goal";
export const EDIT_GOAL_MODAL_TYPE = "edit_goal";
export const DECLINE_GOAL_MODAL_TYPE = "decline_goal";

// Note Types
export const AD_HOC = "ad_hoc";
export const MAP = "map";
export const ACTION = "action";
export const ANALYSIS = "analysis";
export const CAGES = "cages";
export const CALL = "call";
export const CALL_OTHER_ANYTHING = "call_other_anything";
export const CALL_OTHER_BASEBALL = "call_other_baseball";
export const CALL_OTHER_INJURY = "call_other_injury";
export const CATCH_PLAY = "catch_play";
export const CONVO = "convo";
export const DELIVERY = "delivery";
export const EXPLANATION = "explanation";
export const EXTRA_HITTING_CAGE = "extra_hitting_cage";
export const EXTRA_HITTING_OF = "extra_hitting_of";
export const GAME = "game";
export const IN_GAME = "in_game";
export const LIVE_AB = "live_ab";
export const LIVE_BP = "live_bp";
export const MINI_POD = "mini_pod";
export const ON_FIELD_BP = "on_field_bp";
export const PEN = "pen";
export const PHYSICAL = "physical";
export const WARM_UP = "warm_up";

export const CALL_OTHER_BASEBALL_LABEL = "(Other) Baseball Related";
export const CALL_OTHER_INJURY_LABEL = "(Other) Injury Related";
export const CALL_OTHER_ANYTHING_LABEL = "(Other) Anything Else";

// Call Log Table Columns
export const CALL_LOG_COLUMNS = [
	{
		title: "Date",
		name: "createDate",
		width: "150px",
		// https://stackoverflow.com/questions/61368563/day-js-is-not-converting-utc-to-local-time
		getCellValue: (row: TPlayerPlanNote) =>
			dayjs
				.utc(row.createDate.substring(0, 23))
				.local()
				.format("YYYY-MM-DD hh:mm A"),
		sortFunc: (a: TPlayerPlanNote, b: TPlayerPlanNote) => {
			const aValue = dayjs.utc(a.createDate.substring(0, 23)).local();
			const bValue = dayjs.utc(b.createDate.substring(0, 23)).local();
			return aValue.diff(bValue);
		}
	},
	{
		title: "Staff",
		name: "lastChangeUser",
		width: "10%",
		getCellValue: (row: TPlayerPlanNote) => `${row.lastChangeUser?.firstName} ${row.lastChangeUser?.lastName}`,
		sortFunc: (a: TPlayerPlanNote, b: TPlayerPlanNote) => {
			const aValue = `${a.lastChangeUser?.lastName} ${a.lastChangeUser?.firstName}`;
			const bValue = `${b.lastChangeUser?.lastName} ${b.lastChangeUser?.firstName}`;
			return aValue.localeCompare(bValue);
		}
	},
	{
		title: "Player",
		name: "player",
		width: "10%",
		getCellValue: (row: TPlayerPlanNote) => {
			if (row.player)
				return `${row.player?.firstName ?? row.player?.firstNameLegal} ${row.player?.lastName ??
					row.player?.lastNameExtended}`;
			if (row.playerPlanGoal)
				return `${row.playerPlanGoal?.player?.firstName ?? row.playerPlanGoal?.player?.firstNameLegal} ${row
					.playerPlanGoal?.player?.lastName ?? row.playerPlanGoal?.player?.lastNameExtended}`;
			return "";
		},
		sortFunc: (a: TPlayerPlanNote, b: TPlayerPlanNote) => {
			let aValue = "";
			let bValue = "";

			if (a.player)
				aValue = `${a.player?.lastName ?? a.player?.lastNameExtended} ${a.player?.firstName ??
					a.player?.firstNameLegal}`;
			else if (a.playerPlanGoal)
				aValue = `${a.playerPlanGoal?.player?.lastName ?? a.playerPlanGoal?.player?.lastNameExtended} ${a
					.playerPlanGoal?.player?.firstName ?? a.playerPlanGoal?.player?.firstNameLegal}`;

			if (b.player)
				bValue = `${b.player?.lastName ?? b.player?.lastNameExtended} ${b.player?.firstName ??
					b.player?.firstNameLegal}`;
			else if (b.playerPlanGoal)
				bValue = `${b.playerPlanGoal?.player?.lastName ?? b.playerPlanGoal?.player?.lastNameExtended} ${b
					.playerPlanGoal?.player?.firstName ?? b.playerPlanGoal?.player?.firstNameLegal}`;

			return aValue.localeCompare(bValue);
		}
	},
	{
		title: "Goal",
		name: "goal",
		width: "15%",
		getCellValue: (row: TPlayerPlanNote) => {
			if (!row.playerPlanGoal) return row.type?.label ?? "";
			// TODO Should this be active target or target when they made the note? or both?
			const activeTarget = row.playerPlanGoal?.playerPlanTargets?.find((t: TPlayerPlanTarget) => t.isActive);
			return createGoalStatement(
				row.playerPlanGoal?.playerPlanMetric?.label,
				row.playerPlanGoal?.playerPlanMetric?.metricSubtype?.label,
				row.playerPlanGoal?.playerPlanSplits,
				activeTarget?.direction?.label,
				activeTarget?.value,
				row.playerPlanGoal?.processUsed,
				row.playerPlanGoal?.playerPlanMetric?.format
			);
		}
	},
	{
		title: "Note",
		name: "content"
	},
	{
		title: "Trend",
		name: "isPositive",
		width: "60px",
		getCellValue: (row: TPlayerPlanNote) => (row.isPositive ? "Up" : row.isPositive === false ? "Down" : "Neutral")
	},
	{
		title: "Unresp.",
		name: "isNoContact",
		width: "60px",
		getCellValue: (row: TPlayerPlanNote) => (row.isNoContact ? "Yes" : ""),
		sortFunc: (a: TPlayerPlanNote, b: TPlayerPlanNote) => {
			const aValue = a.isNoContact ? 1 : 0;
			const bValue = b.isNoContact ? 1 : 0;
			return aValue - bValue;
		}
	},
	{
		title: "Contact",
		name: "isIncorrectContact",
		width: "60px",
		getCellValue: (row: TPlayerPlanNote) => (row.isIncorrectContact ? "Need" : ""),
		sortFunc: (a: TPlayerPlanNote, b: TPlayerPlanNote) => {
			const aValue = a.isIncorrectContact ? 1 : 0;
			const bValue = b.isIncorrectContact ? 1 : 0;
			return aValue - bValue;
		}
	},
	{
		title: "Equip.",
		name: "isLackingEquipment",
		width: "60px",
		getCellValue: (row: TPlayerPlanNote) => (row.isLackingEquipment ? "Need" : ""),
		sortFunc: (a: TPlayerPlanNote, b: TPlayerPlanNote) => {
			const aValue = a.isLackingEquipment ? 1 : 0;
			const bValue = b.isLackingEquipment ? 1 : 0;
			return aValue - bValue;
		}
	},
	{
		title: "Video",
		name: "videoGcsUri",
		width: "60px",
		getCellValue: (row: TPlayerPlanNote) =>
			row.videoGcsUri ? (
				<a href={`/gcsredirect?uri=${row.videoGcsUri}`} target="_blank" rel="noopener noreferrer">
					Video
				</a>
			) : (
				""
			),
		sortFunc: (a: TPlayerPlanNote, b: TPlayerPlanNote) => {
			const aValue = a.videoGcsUri ? 1 : 0;
			const bValue = b.videoGcsUri ? 1 : 0;
			return aValue - bValue;
		}
	}
];

// Recent Change Table Columns
export const FILTER_WIDTH = "44";

export const TEAM_LEVEL_NULL_FILLER_TEXT = "No Level";
export const MAX_TOOLTIP_CHARS = 200;

export const PLAYER_COLUMN = {
	label: "Player",
	value: "playerName",
	id: "playerName",
	isMobile: true,
	getValueFunction: (p: TChange) => (
		<Text>
			{p.lastName}, {p.firstName ?? p.firstNameLegal}
		</Text>
	),
	getSortValueFunction: (p: TChange) => `${p.lastName} ${p.firstName ?? p.firstNameLegal}`,
	onClickFunction: (p: TChange) => {
		window.open(`../../player?id=${p.playerId}&view=plan&viewClassification=pro`, "_blank");
	}
};
export const MOBILE_PLAYER_COLUMN = {
	label: "Player",
	value: "playerName",
	id: "playerName",
	isMobile: true,
	getValueFunction: (p: TChange) => (
		<Text>
			{p.lastName}, {p.firstName ?? p.firstNameLegal}
		</Text>
	),
	getSortValueFunction: (p: TChange) => `${p.lastName} ${p.firstName ?? p.firstNameLegal}`
};
export const LEVEL_COLUMN = {
	label: "Lvl",
	value: "level",
	id: "level",
	isMobile: true,
	getValueFunction: (p: TChange) => <TeamLevelBadge level={p?.teamLevel} nullFillerText={"-"} />,
	getSortValueFunction: (p: TChange) => p?.teamLevel ?? null
};
export const DEPARTMENT_COLUMN = {
	label: "Department",
	value: "department",
	id: "department",
	isMobile: true,
	getValueFunction: getDepartmentCellValue
};
export const GOAL_COLUMN = {
	label: "Goal",
	value: "goal",
	id: "goal",
	isMobile: true,
	getValueFunction: getGoalCellValue
};
export const FOCUS_AREA_COLUMN = {
	label: "Focus Area",
	value: "focusArea",
	id: "focusArea",
	isMobile: true,
	getValueFunction: getFocusAreaCellValue
};
export const SUPPORTING_ACTION_COLUMN = {
	label: "Supporting Action",
	value: "supportingAction",
	id: "supportingAction",
	isMobile: true,
	getValueFunction: getSupportingActionCellValue
};
export const CHANGE_TYPE_COLUMN = {
	label: "Change Type",
	value: "changeType",
	id: "changeType",
	isMobile: true,
	getValueFunction: (c: TChange) => {
		const tooltipContent: string | null = c.noteContent ?? c.attachmentName ?? null;
		return tooltipContent ? (
			<Tooltip
				hasArrow
				placement={"top"}
				label={`${tooltipContent.substring(0, Math.min(MAX_TOOLTIP_CHARS, tooltipContent.length))}${
					tooltipContent.length > MAX_TOOLTIP_CHARS ? "..." : ""
				}`}
				whiteSpace="pre-wrap"
			>
				<Flex align="start" color="black" gap="1">
					<Box>{c.changeType}</Box>
					<Info style={TagStyle} />
				</Flex>
			</Tooltip>
		) : (
			c.changeType
		);
	},
	getSortValueFunction: (c: TChange) => c.changeType
};
export const DATE_COLUMN = {
	label: "Date",
	value: "date",
	id: "date",
	isMobile: true,
	getValueFunction: getChangeDateCellValue,
	getSortValueFunction: getChangeDateCellSortValue
};

export const RECENT_CHANGE_COLUMNS_MOBILE = [
	MOBILE_PLAYER_COLUMN,
	LEVEL_COLUMN,
	DEPARTMENT_COLUMN,
	CHANGE_TYPE_COLUMN,
	DATE_COLUMN
];
export const RECENT_CHANGE_COLUMNS = [
	PLAYER_COLUMN,
	LEVEL_COLUMN,
	DEPARTMENT_COLUMN,
	GOAL_COLUMN,
	FOCUS_AREA_COLUMN,
	SUPPORTING_ACTION_COLUMN,
	CHANGE_TYPE_COLUMN,
	DATE_COLUMN
];

// Default Form Values
export const DEFAULT_SECONDARY_GOAL_FORM: TSecondaryGoalForm = {
	direction: null,
	directionLabel: null,
	metric: null,
	isQualitative: false,
	metricLabel: null,
	metricFormat: null,
	target: null,
	description: null
};

export const DEFAULT_ADD_GOAL_FORM: TGoalForm = {
	parentGoalId: null,
	parentGoalStatement: null,
	metricType: null,
	metricSubtype: null,
	metricSubtypeLabel: null,
	direction: null,
	directionLabel: null,
	metric: null,
	isQualitative: false,
	metricLabel: null,
	metricFormat: null,
	target: null,
	description: null,
	secondaryGoal: null,
	splits: null,
	splitOpts: null,
	drills: null,
	needsApproval: true,
	isStrength: false,
	isPriority: false,
	isLead: false,
	isInactive: false,
	isParentGoal: false,
	season: null,
	primaryGoalStatus: null,
	primaryGoalId: null
};

// Goal Types
export const PARENT = "parent";
export const PARENT_PRIORITY = "parent_priority";
export const CHILD = "child";
export const STRENGTH = "strength";

export const PHP = "php";
export const FOCUS_AREA = "focus_area";

//
// HARDCODING the list order for the Player Manuel home page
//
export const PD_LIST_ORDER = {
	// Entire Organization
	[15060]: 1,
	// Positional
	[13632]: 2,
	[13631]: 3,
	[13630]: 4,
	[13629]: 5,
	[13628]: 6,
	// Team
	[13478]: 7,
	[13479]: 8,
	[13480]: 9,
	[13481]: 10,
	[13482]: 11,
	[13666]: 12,
	[16305]: 13,
	[13682]: 14,
	[13664]: 15,
	[13665]: 16,
	// Team Positional
	// PHI
	[13637]: 17,
	[13636]: 18,
	[13635]: 19,
	[13634]: 20,
	[13633]: 21,
	// LHV
	[13642]: 22,
	[13641]: 23,
	[13640]: 24,
	[13639]: 25,
	[13638]: 26,
	// Reading
	[13647]: 27,
	[13646]: 28,
	[13645]: 29,
	[13644]: 30,
	[13643]: 31,
	// JS
	[13652]: 32,
	[13651]: 33,
	[13650]: 34,
	[13649]: 35,
	[13648]: 36,
	// Clearwater
	[13657]: 37,
	[13656]: 38,
	[13655]: 39,
	[13654]: 40,
	[13653]: 41,
	// FCL
	[13681]: 42,
	[13680]: 43,
	[13679]: 44,
	[13678]: 45,
	[13677]: 46,
	// DSLW
	[13671]: 47,
	[13670]: 48,
	[13669]: 49,
	[13668]: 50,
	[13667]: 51,
	// DSLR
	[13676]: 52,
	[13675]: 53,
	[13674]: 54,
	[13673]: 55,
	[13672]: 56,
	// Domestic Overall
	[16310]: 57,
	[16309]: 58,
	[16308]: 59,
	[16307]: 60,
	[16306]: 61,
	// DSL Overall
	[16304]: 62,
	[16303]: 63,
	[16302]: 64,
	[16301]: 65,
	[16300]: 66,
	// IL
	[15138]: 67
} as Record<number, number>;

// Metric Formats
export const MPH_FORMAT = "mph";
export const PERCENTAGE_FORMAT = "percentage";
export const THREE_DECIMAL_FORMAT = "three_decimal";
export const POUNDS_FORMAT = "pounds";
export const NEWTONS_FORMAT = "newtons";
export const INCHES_FORMAT = "inches";
export const SECONDS_FORMAT = "seconds";
export const DEGREES_FORMAT = "degrees";
export const W_FORMAT = "watts";
export const M_S_FORMAT = "m_s";

//
// Constants used for Goal Graphs
//

// Scales to use that pin Notes/Transactions to top/bottom of graph
export const VERTICAL_BAR_AXIS_SCALE = [0, 100];
export const VERTICAL_BAR_BOTTOM = 1;
export const VERTICAL_BAR_TOP = 99;

// Overlay Axis Labels
export const HIT_OVERLAY_LABEL = "Hitter 3 Grades";
export const PITCH_OVERLAY_LABEL = "Arsenal Scores";

// Metric Axis Ranges, tick0, tickFormat, and dTick used for goal grpahs axis properties
export const AXIS_RANGE_20_80 = [15, 85];

export const TICK0_20_80 = 20;

export const TICKFORMAT_THREE_DECIMAL = ".3f";

export const DTICK_20_80 = 10;

// Metric Scale Labels
export const LABEL_20_80 = "20-80";

// Metric Scale Means
export const MEAN_20_80 = 50;

// Metric Scale Splits
export const METRIC_SCALE_OVR = "OVR";
