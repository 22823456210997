import { IManualPitchTypeMapBySeasonPitchType } from "_react/shared/data_models/manual_pitch_type_map_byseason/_types";

export const parsePitchTypeLabel = (value: IManualPitchTypeMapBySeasonPitchType): string => {
	return value.label ?? value.value;
};

export const getSeasonOptions = (currentSeason: number | undefined) => {
	if (currentSeason) {
		const offsets = [0, 1, 2];
		return offsets.map(offset => {
			const season = currentSeason - offset;
			return { label: `${season}`, value: season };
		});
	}
};
