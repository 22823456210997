import React, { useMemo, useState, useEffect, useCallback } from "react";
import {
	useToast,
	VStack,
	HStack,
	Box,
	IconButton,
	ButtonGroup,
	Menu,
	MenuButton,
	MenuList,
	MenuOptionGroup,
	MenuItemOption,
	MenuDivider,
	Portal,
	Text,
	RangeSlider,
	RangeSliderTrack,
	RangeSliderFilledTrack,
	RangeSliderThumb,
	Tooltip,
	Spacer,
	Flex,
	SystemStyleObject
} from "@chakra-ui/react";
import { useMachine } from "@xstate/react";

import CloseIcon from "_react/shared/legacy/ui/icons/Clear";
import { isDefaultFilters, DEFAULT_STATS_TABLE_FILTERS } from "_react/shared/ui/data/tables/shared/Filters";
import OutlineInfo from "_react/shared/ui/icons/OutlineInfo";
import { getMinAndMaxSeason, getSeasonFilters, updateFilters } from "_react/shared/_helpers/stats";
import Table from "_react/shared/ui/presentation/components/Table/Table";
import { TTableProps, TColumn } from "_react/shared/ui/presentation/components/Table/_types";
import { ASC, DESC } from "_react/shared/ui/presentation/components/Table/_constants";
import { ISeasonalSwingMetrics } from "_react/shared/data_models/seasonal_grades/_types";
import { VALID_PRO_LEVELS } from "_react/shared/data_models/stats/_constants";
import { IStatsPlayerBattingByTeamLkLevel } from "_react/shared/data_models/stats/_types";
import FilterAlt from "_react/shared/ui/icons/FilterAlt";
import {
	THROWS_L,
	THROWS_OVERALL,
	THROWS_R,
	GAME_TYPE_OVERALL,
	GAME_TYPE_POSTSEASON,
	GAME_TYPE_REGULAR_SEASON,
	GAME_TYPE_SPRING_TRAINING
} from "_react/shared/data_models/baseline_hit_probs/_constants";

import { NUM_DISPLAY_SEASONS, getSwingMetricsColumns } from "_react/shared/ui/data/tables/SwingMetricsTable/_constants";
import SwingMetricsTableMachine, {
	TSwingMetricsTableContext,
	FETCHING_SEASONAL_SWING_METRICS,
	FETCHING_STATS_PLAYER_BATTING,
	FETCHING_STATS_PLAYER_BATTING_BYTEAM,
	SET_PLAYER_ID,
	SET_SEASONAL_SWING_METRICS,
	SET_STATS_PLAYER_BATTING,
	SET_STATS_PLAYER_BATTING_BYTEAM,
	SET_FILTERS
} from "_react/shared/ui/data/tables/SwingMetricsTable/_machine";
import {
	ICombinedSwingMetricsData,
	TSwingMetricsTableData,
	TSwingMetricsRow
} from "_react/shared/ui/data/tables/SwingMetricsTable/_types";
import {
	aggregateCombinedSwingMetricsDataRows,
	appendGenericSeasonalSwingMetricsData,
	createStatsPlayerBattingByTeamRecord,
	createStatsPlayerBattingRecord,
	dedupePlayerStatsPlayerBattingBySource,
	dedupePlayerStatsPlayerBattingByTeamAndSource,
	getLevelsFromRow
} from "_react/shared/ui/data/tables/SwingMetricsTable/_helpers";
import TeamLevelBadge from "_react/shared/ui/presentation/components/TeamLevelBadge/TeamLevelBadge";

type TSwingMetricsTableStyle = {
	container?: SystemStyleObject;
	tableContainer?: SystemStyleObject;
};

type TSwingMetricsTableProps = {
	title?: string;
	playerId?: number;
	data?: TSwingMetricsTableData;
	columns?: Array<string>;
	shouldFetchData?: boolean;
	isShowFilters?: boolean;
	tableProps?: TTableProps<TSwingMetricsRow, keyof TSwingMetricsRow>;
	style?: TSwingMetricsTableStyle;
};

const SwingMetricsTable = ({
	title,
	playerId: playerIdProp,
	data,
	columns,
	shouldFetchData = true,
	isShowFilters = true,
	tableProps,
	style
}: TSwingMetricsTableProps) => {
	const toast = useToast();
	const [showSeasonRangeTooltip, setShowSeasonRangeTooltip] = useState(false);

	const [current, send] = useMachine(SwingMetricsTableMachine(playerIdProp, data, shouldFetchData, toast));
	const isFetchingSeasonalSwingMetrics = current.matches(FETCHING_SEASONAL_SWING_METRICS);
	const isFetchingStatsPlayerBatting = current.matches(FETCHING_STATS_PLAYER_BATTING);
	const isFetchingStatsPlayerBattingByTeam = current.matches(FETCHING_STATS_PLAYER_BATTING_BYTEAM);
	const isLoading =
		isFetchingSeasonalSwingMetrics || isFetchingStatsPlayerBatting || isFetchingStatsPlayerBattingByTeam;

	const context = current.context as TSwingMetricsTableContext;
	const { playerId, filters, seasonalSwingMetrics, statsPlayerBatting, statsPlayerBattingByTeam } = context;

	// Update machine context when props change
	useEffect(() => {
		if (playerIdProp !== playerId) send({ type: SET_PLAYER_ID, value: playerIdProp });
	}, [send, playerIdProp, playerId, shouldFetchData]);

	useEffect(() => {
		if (data?.seasonalSwingMetrics !== seasonalSwingMetrics && shouldFetchData === false)
			send({ type: SET_SEASONAL_SWING_METRICS, value: data?.seasonalSwingMetrics });
	}, [send, data?.seasonalSwingMetrics, seasonalSwingMetrics, shouldFetchData]);

	useEffect(() => {
		if (data?.statsPlayerBatting !== statsPlayerBatting && shouldFetchData === false)
			send({ type: SET_STATS_PLAYER_BATTING, value: data?.statsPlayerBatting });
	}, [send, data?.statsPlayerBatting, statsPlayerBatting, shouldFetchData]);

	useEffect(() => {
		if (data?.statsPlayerBattingByTeam !== statsPlayerBattingByTeam && shouldFetchData === false)
			send({ type: SET_STATS_PLAYER_BATTING_BYTEAM, value: data?.statsPlayerBattingByTeam });
	}, [send, data?.statsPlayerBattingByTeam, statsPlayerBattingByTeam, shouldFetchData]);

	//
	// PREPROCESSING statsPlayerBatting and seasonalSwingMetrics
	//

	const statsPlayerBattingRecord: Record<string, ICombinedSwingMetricsData> = useMemo(() => {
		if (!statsPlayerBatting) return {};
		// Part 1: Dedupe Stats Player Batting
		const dedupedStatsPlayerBatting = dedupePlayerStatsPlayerBattingBySource(statsPlayerBatting);
		// Part 2: Aggregate Stats Player Batting -> Combined Position Player Swing Metrics Data
		// using the game types from seasonal_swing_metrics to create a record mapping
		// season-gameType to ICombinedSwingMetricsData
		return createStatsPlayerBattingRecord(dedupedStatsPlayerBatting);
	}, [statsPlayerBatting]);

	const combinedSwingMetricsData: Array<ICombinedSwingMetricsData> | undefined = useMemo(() => {
		// Part 3: Iterate over seasonal swing metrics, and append entries to the above record
		const combinedSwingMetricsRecord = appendGenericSeasonalSwingMetricsData(
			seasonalSwingMetrics ?? [],
			(x: ISeasonalSwingMetrics) => `${x.season}-${x.gameType}`,
			statsPlayerBattingRecord
		);
		return Object.values(combinedSwingMetricsRecord);
	}, [statsPlayerBattingRecord, seasonalSwingMetrics]);

	//
	// PREPROCESSING statsPlayerBattingByTeam
	//

	// const statsPlayerBattingByTeamRecord: Record<string, ICombinedSwingMetricsData> = useMemo(() => {
	const combinedSwingMetricsDataByTeam: Array<ICombinedSwingMetricsData> = useMemo(() => {
		if (!statsPlayerBattingByTeam) return [];
		// Part 1: Dedupe Stats Player Batting By Team
		const dedupedStatsPlayerBattingByTeam = dedupePlayerStatsPlayerBattingByTeamAndSource(statsPlayerBattingByTeam);
		// Part 2: Aggregate Stats Player Batting By Team -> Combined Seasonal Swing Metrics Data
		// using the game types from seasonal_swing_metrics to create a record mapping and combined team ID
		// to create a record mapping season-teamId-gameType to ICombinedSwingMetricsData
		return Object.values(createStatsPlayerBattingByTeamRecord(dedupedStatsPlayerBattingByTeam));
	}, [statsPlayerBattingByTeam]);

	//
	// Season filter options
	//

	// Get list of seasons using byTeam data so that the min/max seasons have corresponding entries in the table,
	// as a season must have at least one associated team to be displayed
	const [minSeason, maxSeason] = useMemo(
		() => getMinAndMaxSeason<ICombinedSwingMetricsData>(combinedSwingMetricsDataByTeam ?? []),
		[combinedSwingMetricsDataByTeam]
	);
	const seasonFilters: { minSeason: number; maxSeason: number } = useMemo(
		() => getSeasonFilters(filters.minSeason, filters.maxSeason, maxSeason, NUM_DISPLAY_SEASONS),
		[filters.minSeason, filters.maxSeason, maxSeason]
	);

	//
	// Level filter options
	//

	// Compute the level filter options
	const levelFilterOptions: Array<IStatsPlayerBattingByTeamLkLevel> = useMemo(
		() =>
			combinedSwingMetricsDataByTeam
				.reduce((acc: Array<IStatsPlayerBattingByTeamLkLevel>, c: ICombinedSwingMetricsData) => {
					const levelRel: IStatsPlayerBattingByTeamLkLevel | undefined = c.team?.levelRel;
					if (
						levelRel &&
						levelRel.value &&
						!acc.some(val => val.value === levelRel.value) &&
						VALID_PRO_LEVELS.includes(levelRel.value)
					)
						acc.push(levelRel);
					return acc;
				}, [])
				?.sort(
					(a: IStatsPlayerBattingByTeamLkLevel, b: IStatsPlayerBattingByTeamLkLevel) =>
						(a.sortOrder ?? Number.MAX_SAFE_INTEGER) - (b.sortOrder ?? Number.MAX_SAFE_INTEGER)
				) ?? [],
		[combinedSwingMetricsDataByTeam]
	);

	// Once the level filter options are computed for the first time, send them to the machine
	useEffect(() => {
		if (filters.levels === undefined && levelFilterOptions.length > 0) {
			const newFilters = {
				...filters,
				levels: levelFilterOptions.map((option: IStatsPlayerBattingByTeamLkLevel) => option.value)
			};
			send({ type: SET_FILTERS, value: newFilters });
		}
	}, [filters, send, levelFilterOptions]);

	//
	// Filter data for table
	//

	// Combine game type and throws filters into "OVR" when possible
	const gameTypeFilters: Array<string> = useMemo(() => {
		return filters.gameTypes.includes(GAME_TYPE_REGULAR_SEASON) &&
			filters.gameTypes.includes(GAME_TYPE_POSTSEASON) &&
			!filters.gameTypes.includes(GAME_TYPE_SPRING_TRAINING)
			? [GAME_TYPE_OVERALL]
			: filters.gameTypes;
	}, [filters.gameTypes]);
	const throwsFilter: string = useMemo(() => (filters.throws.length === 2 ? THROWS_OVERALL : filters.throws?.[0]), [
		filters.throws
	]);

	// Get columns based on handedness filter
	const swingMetricsColumns = useMemo(() => getSwingMetricsColumns(throwsFilter), [throwsFilter]);

	// Filter columns based on prop
	const filteredColumns = useMemo(() => {
		if (!columns) return swingMetricsColumns;
		return swingMetricsColumns.filter((col: TColumn<TSwingMetricsRow, keyof TSwingMetricsRow>) =>
			columns.includes(col.value)
		);
	}, [columns, swingMetricsColumns]);

	const filteredCombinedPositionPlayerPerformanceData: Array<ICombinedSwingMetricsData> | undefined = useMemo(() => {
		if (isLoading || !throwsFilter) return undefined;
		return combinedSwingMetricsData?.filter(
			(c: ICombinedSwingMetricsData) =>
				c.season <= seasonFilters.maxSeason &&
				c.season >= seasonFilters.minSeason &&
				gameTypeFilters.includes(c.gameType)
		);
	}, [isLoading, seasonFilters, gameTypeFilters, throwsFilter, combinedSwingMetricsData]);

	// Check for default filters
	const defaultFiltersSet: boolean = useMemo(() => {
		const availableLevels = levelFilterOptions.map((option: IStatsPlayerBattingByTeamLkLevel) => option.value);

		return isDefaultFilters(filters, undefined, availableLevels, maxSeason, NUM_DISPLAY_SEASONS);
	}, [filters, levelFilterOptions, maxSeason]);

	const resetFilters = useCallback(() => {
		send({ type: SET_FILTERS, value: DEFAULT_STATS_TABLE_FILTERS });
	}, [send]);

	//
	// Combine data for table
	//

	const combinedTableData: Array<TSwingMetricsRow> | undefined = useMemo(() => {
		if (!filteredCombinedPositionPlayerPerformanceData) return undefined;
		const combinedData: Array<TSwingMetricsRow> = [];
		const uniqueSeasons = [
			...new Set(filteredCombinedPositionPlayerPerformanceData.map((c: ICombinedSwingMetricsData) => c.season))
		];
		uniqueSeasons.forEach((season: number) => {
			// Create an object with the overall combined position player performance data and all related
			// position player performance data by team.

			// For certain game type combinations, we need to aggregate the data into a single row
			const swingMetricsData: Array<ICombinedSwingMetricsData> = filteredCombinedPositionPlayerPerformanceData.filter(
				(c: ICombinedSwingMetricsData) => c.season === season
			);
			const swingMetricsDataByTeam: Array<ICombinedSwingMetricsData> =
				combinedSwingMetricsDataByTeam?.filter(
					(c: ICombinedSwingMetricsData) => c.season === season && gameTypeFilters.includes(c.gameType)
				) ?? [];

			// Only one top-level row is returned, so just use those stats
			if (swingMetricsData.length === 1) {
				const combinedParentData = swingMetricsData[0];
				const combinedChildData: Array<TSwingMetricsRow> | undefined = swingMetricsDataByTeam.map(
					(c: ICombinedSwingMetricsData) => {
						return { combinedSwingMetricsData: c };
					}
				);
				// If there is only one child row, then we want to display its team as part of the overall row
				if (swingMetricsDataByTeam && swingMetricsDataByTeam.length === 1)
					combinedData.push({
						combinedSwingMetricsData: {
							...combinedParentData,
							team: swingMetricsDataByTeam[0].team,
							teamId: swingMetricsDataByTeam[0].teamId
						},
						// We don't use this data because this table does not have team level data yet
						// When we add team level data we will need to adjust this block
						childData: combinedChildData
					});
				// If there are no child rows, then the overall row must be invalid, and we should not push data.
				// Therefore, only push the data if the length of the byTeam is non-zero
				else if (swingMetricsDataByTeam && swingMetricsDataByTeam.length > 0)
					combinedData.push({
						combinedSwingMetricsData: combinedParentData,
						childData: combinedChildData
					});
			}
			// Multiple top-level rows are returned, aggregate all of the data
			else if (swingMetricsData?.length) {
				// Aggregate position player performance data across different game types into a single top-level row
				let combinedParentData = swingMetricsData.reduce(
					(prev: ICombinedSwingMetricsData, curr: ICombinedSwingMetricsData) => {
						return aggregateCombinedSwingMetricsDataRows(prev, curr);
					}
				);
				// Aggregate team level position player performance data different game types into singular team-level rows
				let combinedChildData: Array<TSwingMetricsRow> | undefined = swingMetricsDataByTeam?.reduce(
					(combinedDataByTeam: Array<TSwingMetricsRow>, dataByTeam: ICombinedSwingMetricsData) => {
						if (!combinedDataByTeam.length) return [{ combinedSwingMetricsData: dataByTeam }];
						const combinedDataMatchingTeamIndex = combinedDataByTeam.findIndex(
							(combinedData: TSwingMetricsRow) =>
								"teamId" in combinedData.combinedSwingMetricsData &&
								combinedData.combinedSwingMetricsData.teamId === dataByTeam.teamId
						);
						if (combinedDataMatchingTeamIndex === -1)
							combinedDataByTeam.push({ combinedSwingMetricsData: dataByTeam });
						else {
							const combinedDataMatchingTeamData =
								combinedDataByTeam[combinedDataMatchingTeamIndex].combinedSwingMetricsData;
							combinedDataByTeam[combinedDataMatchingTeamIndex] = {
								combinedSwingMetricsData: aggregateCombinedSwingMetricsDataRows(
									combinedDataMatchingTeamData,
									dataByTeam
								)
							};
						}
						return combinedDataByTeam;
					},
					[]
				);
				// If there are no child rows, then the overall row must be invalid, and we should not push data.
				// Therefore, only push the data if the length of the byTeam is non-zero
				if (!combinedChildData || combinedChildData.length === 0) return;
				// If there is only one child row, then we want to display it as the overall row
				if (combinedChildData && combinedChildData.length === 1) {
					(combinedParentData = {
						...combinedParentData,
						team: swingMetricsDataByTeam[0].team,
						teamId: swingMetricsDataByTeam[0].teamId
					}),
						(combinedChildData = undefined);
				}
				combinedData.push({
					combinedSwingMetricsData: combinedParentData,
					// We don't use this data because this table does not have team level data yet
					// When we add team level data we will need to adjust this block
					childData: combinedChildData
				});
			}
		});

		// Apply level filters
		return combinedData.filter((row: TSwingMetricsRow) => {
			const uniqueLevels: Array<string> = getLevelsFromRow(row);
			return uniqueLevels.every(level => filters.levels?.includes(level));
		});
	}, [
		filters.levels,
		gameTypeFilters,
		filteredCombinedPositionPlayerPerformanceData,
		combinedSwingMetricsDataByTeam
	]);

	// Filtering
	const handleGameTypeSelect = (value: string) => {
		const newFilters = {
			...filters,
			gameTypes: updateFilters(filters.gameTypes, value)
		};
		send({ type: SET_FILTERS, value: newFilters });
	};

	const handleThrowsSelect = (value: string) => {
		const newFilters = {
			...filters,
			throws: updateFilters(filters.throws, value)
		};
		send({ type: SET_FILTERS, value: newFilters });
	};

	const handleLevelSelect = (value: string) => {
		const newFilters = {
			...filters,
			levels: updateFilters(filters.levels ?? [], value)
		};
		send({ type: SET_FILTERS, value: newFilters });
	};

	return (
		<VStack alignItems="start" sx={style?.container}>
			<HStack w="100%" justify="space-between">
				<HStack gap={1}>
					{title && (
						<Box fontFamily="heading" fontSize="md" fontWeight="bold">
							{title}
						</Box>
					)}
				</HStack>
				{isShowFilters && (
					<Menu closeOnSelect={false} placement="left-start">
						<ButtonGroup
							isAttached
							variant={defaultFiltersSet ? "outline" : "solid"}
							colorScheme={defaultFiltersSet ? undefined : "blue"}
						>
							{!defaultFiltersSet && (
								<IconButton
									aria-label="Close"
									icon={<CloseIcon fill="white" />}
									onClick={resetFilters}
								/>
							)}
							<MenuButton
								as={IconButton}
								aria-label="Options"
								icon={<FilterAlt color={defaultFiltersSet ? "gray.500" : "white"} boxSize={5} />}
							>
								MenuItem
							</MenuButton>
						</ButtonGroup>
						<Portal>
							<MenuList minWidth="240px" maxHeight="md" overflow="scroll">
								<MenuOptionGroup title="Pitcher Throws" type="checkbox" value={filters.throws}>
									<MenuItemOption value={THROWS_L} onClick={() => handleThrowsSelect(THROWS_L)}>
										Left
									</MenuItemOption>
									<MenuItemOption value={THROWS_R} onClick={() => handleThrowsSelect(THROWS_R)}>
										Right
									</MenuItemOption>
								</MenuOptionGroup>
								<MenuDivider />
								<MenuOptionGroup title="Game Type" type="checkbox" value={filters.gameTypes}>
									<MenuItemOption
										value={GAME_TYPE_SPRING_TRAINING}
										onClick={() => handleGameTypeSelect(GAME_TYPE_SPRING_TRAINING)}
									>
										Spring Training
									</MenuItemOption>
									<MenuItemOption
										value={GAME_TYPE_REGULAR_SEASON}
										onClick={() => handleGameTypeSelect(GAME_TYPE_REGULAR_SEASON)}
									>
										Regular Season
									</MenuItemOption>
									<MenuItemOption
										value={GAME_TYPE_POSTSEASON}
										onClick={() => handleGameTypeSelect(GAME_TYPE_POSTSEASON)}
									>
										Postseason
									</MenuItemOption>
								</MenuOptionGroup>
								<MenuOptionGroup title="Seasons">
									<VStack paddingLeft={4} paddingRight={4} sx={{ alignItems: "leading" }}>
										{minSeason === maxSeason && (
											<Tooltip hasArrow placement="top" label="Only one season of data exists">
												<HStack>
													<OutlineInfo color="gray.500" />
													<Text>{minSeason}</Text>
												</HStack>
											</Tooltip>
										)}
										{minSeason !== maxSeason && (
											<VStack>
												<RangeSlider
													value={[seasonFilters.minSeason, seasonFilters.maxSeason]}
													min={minSeason}
													max={maxSeason}
													step={1}
													onChange={(seasons: number[]) => {
														send({
															type: SET_FILTERS,
															value: {
																...filters,
																minSeason: seasons[0],
																maxSeason: seasons[1]
															}
														});
													}}
													onMouseEnter={() => setShowSeasonRangeTooltip(true)}
													onMouseLeave={() => setShowSeasonRangeTooltip(false)}
												>
													<RangeSliderTrack>
														<RangeSliderFilledTrack bg="black" />
													</RangeSliderTrack>
													<Tooltip
														hasArrow
														placement="top"
														isOpen={showSeasonRangeTooltip}
														label={seasonFilters.minSeason}
													>
														<RangeSliderThumb bg="gray.500" boxSize={3} index={0} />
													</Tooltip>
													<Tooltip
														hasArrow
														placement="top"
														isOpen={showSeasonRangeTooltip}
														label={seasonFilters.maxSeason}
													>
														<RangeSliderThumb bg="gray.500" boxSize={3} index={1} />
													</Tooltip>
												</RangeSlider>
												<Flex sx={{ width: "100%" }}>
													<Text fontSize="sm">{minSeason}</Text>
													<Spacer />
													<Text fontSize="sm">{maxSeason}</Text>
												</Flex>
											</VStack>
										)}
									</VStack>
								</MenuOptionGroup>
								<MenuOptionGroup
									title="Levels"
									type="checkbox"
									value={filters.levels ?? VALID_PRO_LEVELS}
								>
									{levelFilterOptions.map((option: IStatsPlayerBattingByTeamLkLevel) => (
										<MenuItemOption
											value={option.value}
											onClick={() => handleLevelSelect(option.value)}
										>
											<TeamLevelBadge level={option.value} />
										</MenuItemOption>
									))}
								</MenuOptionGroup>
							</MenuList>
						</Portal>
					</Menu>
				)}
			</HStack>
			<Box sx={style?.tableContainer}>
				<Table<TSwingMetricsRow, keyof TSwingMetricsRow>
					columns={filteredColumns}
					data={combinedTableData}
					emptyDataDisplayText={"No Swing Metrics Data Found"}
					isLoadingData={isLoading || (!shouldFetchData && data?.isLoading)}
					defaultSortColumns={[
						{
							columnValue: "season",
							sortDirection: ASC
						},
						{
							columnValue: "swings",
							sortDirection: DESC
						}
					]}
					style={{ th: { textTransform: "none" }, parentTh: { textTransform: "none" } }}
					{...tableProps}
				/>
			</Box>
		</VStack>
	);
};

export default SwingMetricsTable;
