import React, { useMemo, useContext } from "react";
import dayjs from "dayjs";
import { useSelector } from "@xstate/react";
import { Box, VStack, HStack } from "@chakra-ui/react";

import { $TSFixMeEval } from "_react/evals/shared/_types";
import { getAgeFromBirthDate } from "utils/helpers";
import { PLAYER_TYPE_BATTER, PLAYER_TYPE_PITCHER } from "_react/shared/data_models/phred/_constants";
import { IIntlProspectValue } from "_react/shared/data_models/phred/_types";
import { FORMAT_CURRENCY_MILLIONS, SM } from "_react/shared/ui/presentation/components/stat/shared/_constants";
import { TMinimalGridListRow } from "_react/shared/ui/presentation/components/MinimalGridList/_types";
import MinimalGridList from "_react/shared/ui/presentation/components/MinimalGridList/MinimalGridList";
import SecondaryStat from "_react/shared/ui/presentation/components/stat/SecondaryStat/SecondaryStat";
import Baseball from "_react/shared/ui/icons/Baseball";
import BaseballBat from "_react/shared/ui/icons/BaseballBat";
import { INTL } from "utils/tsutils";

import { TPlayerPageCombinedPlayer } from "_react/playerpage/_types";
import { getAgentName } from "_react/playerpage/_helpers";
import { AgentValueFlex, TrackmanDupeValueFlex, MinimalGridListContainerStyle } from "_react/playerpage/_styles";
import { NULL_FILLER_TEXT_PROFILE, SV_COLOR_GRADIENT } from "_react/playerpage/_constants";
import { PlayerPageIntlContext } from "_react/playerpage/intl/PlayerPageIntlProvider";
import { formatIntlSurplusValue, getIntlDraftDate } from "_react/playerpage/intl/shared/_helpers";
import { FETCHING_INTL_PROSPECT_VALUES, TPlayerPageIntlState } from "_react/playerpage/intl/_machine";
import AgentAgencyPopover from "_react/playerpage/shared/profile/AgentAgencyPopover";
import { BaseballBatIconStyle, BaseballIconStyle } from "_react/playerpage/intl/shared/_styles";
import { getProIntlAvgOfp } from "_react/playerpage/shared_js/evals/_helpers";
import PlayerDupeIdPopover from "_react/shared/ui/data/modals/PlayerDupeIdPopover/PlayerDupeIdPopover";

type PlayerPageIntlProfileProps = {
	player: TPlayerPageCombinedPlayer;
	evals?: $TSFixMeEval[] | null;
	hasProjectionsPermissions?: boolean;
};

const PlayerPageIntlProfile = ({ player, evals, hasProjectionsPermissions = false }: PlayerPageIntlProfileProps) => {
	const playerPageIntlContext = useContext(PlayerPageIntlContext);
	const birthDate = player.intlProfile?.dateOfBirth ?? player.birthDate;
	const draftDate = getIntlDraftDate(player?.intlProfile?.eligibleYear);

	const intlProspectValues: Array<IIntlProspectValue> | null | undefined = useSelector(
		playerPageIntlContext.playerPageIntlService,
		(state: TPlayerPageIntlState) => state.context.intlProspectValues
	);

	const fetchingIntlProspectValues: boolean = useSelector(
		playerPageIntlContext.playerPageIntlService,
		(state: TPlayerPageIntlState) => state.matches(FETCHING_INTL_PROSPECT_VALUES)
	);

	const currentIntlProspectValueBatter: IIntlProspectValue | undefined = intlProspectValues?.find(
		(value: IIntlProspectValue) => value.playerType === PLAYER_TYPE_BATTER
	);

	const currentIntlProspectValuePitcher: IIntlProspectValue | undefined = intlProspectValues?.find(
		(value: IIntlProspectValue) => value.playerType === PLAYER_TYPE_PITCHER
	);

	const intlInfoData: Array<TMinimalGridListRow> = useMemo(() => {
		return [
			{
				items: [
					{
						label: "OFP",
						value: getProIntlAvgOfp(evals, "INTL") ?? NULL_FILLER_TEXT_PROFILE
					},
					{
						label: "Reports",
						value: evals?.length ?? NULL_FILLER_TEXT_PROFILE
					}
				]
			},
			{
				items: [
					{
						label: "Eligible Year",
						value: player.intlProfile?.eligibleYear ?? NULL_FILLER_TEXT_PROFILE
					},
					{
						label: "Draft Age",
						value: getAgeFromBirthDate(birthDate, draftDate)
					}
				]
			},
			{
				items: [
					{
						label: "Country",
						value: player.intlProfile?.birthCountry ?? NULL_FILLER_TEXT_PROFILE
					}
				]
			}
		];
	}, [player, evals, birthDate, draftDate]);

	const bioData: Array<TMinimalGridListRow> = useMemo(() => {
		const org = player && player.proProfile?.orgCode ? player.proProfile.orgCode.toLowerCase() : "none";
		const age = getAgeFromBirthDate(birthDate);
		return [
			{
				items: [
					{
						label: "Agent",
						value: (
							<>
								<Box width="min-content" paddingRight="1">
									{getAgentName(player.intlProfile, NULL_FILLER_TEXT_PROFILE)}
								</Box>
								<AgentValueFlex>
									{player.intlProfile?.agentId && (
										<AgentAgencyPopover
											playerType="intl"
											infoKey="agent"
											infoId={player.intlProfile.agentId}
											org={org}
											playerClassification={INTL}
										/>
									)}
								</AgentValueFlex>
							</>
						)
					},
					{
						label: "Agency",
						value: (
							<>
								<Box width="min-content" paddingRight="1">
									{player.intlProfile?.agent?.agency?.agencyName ?? NULL_FILLER_TEXT_PROFILE}
								</Box>
								<AgentValueFlex>
									{player.intlProfile?.agent?.agencyId && (
										<AgentAgencyPopover
											playerType="intl"
											infoKey="agency"
											infoId={player.intlProfile.agent.agencyId}
											org={org}
											playerClassification={INTL}
										/>
									)}
								</AgentValueFlex>
							</>
						)
					}
				]
			},
			{
				items: [
					{
						label: "Scout Assigned",
						value:
							player.scoutAssigned?.firstName || player.scoutAssigned?.lastName
								? `${player.scoutAssigned?.firstName ?? ""} ${player.scoutAssigned?.lastName ?? ""}`
								: NULL_FILLER_TEXT_PROFILE
					},
					{
						label: "Birthdate (Age)",
						value:
							birthDate && birthDate != null
								? `${dayjs(birthDate).format("M/D/YYYY")} (${age})`
								: NULL_FILLER_TEXT_PROFILE
					}
				]
			}
		];
	}, [player, birthDate]);

	const idData: Array<TMinimalGridListRow> = useMemo(() => {
		return [
			{
				items: [
					{
						label: "Combined ID",
						value: player.id ?? NULL_FILLER_TEXT_PROFILE
					},
					{
						label: "Phil ID (INTL)",
						value: player.playerIntlId ?? NULL_FILLER_TEXT_PROFILE
					}
				]
			},
			{
				items: [
					{
						label: "eBIS ID",
						value: player.ebisId ?? NULL_FILLER_TEXT_PROFILE
					},
					{
						label: "Trackman ID",
						value:
							player.trackmanId != null ? (
								<>
									{player.trackmanId}
									{typeof player.trackmanId === "number" && (
										<TrackmanDupeValueFlex>
											<PlayerDupeIdPopover
												targetId={player.trackmanId}
												idType={"trackman"}
												shouldFetchData
											/>
										</TrackmanDupeValueFlex>
									)}
								</>
							) : (
								NULL_FILLER_TEXT_PROFILE
							)
					}
				]
			}
		];
	}, [player]);

	const topMinimalGridListContainerStyle = { paddingTop: { base: "0", md: "6" } };

	return (
		<VStack alignItems="start" paddingLeft="12" paddingRight="12" paddingTop="6" paddingBottom="6" gap="0">
			{hasProjectionsPermissions && (
				<HStack spacing="4" paddingBottom="3">
					{fetchingIntlProspectValues && (
						<SecondaryStat
							label={"SV"}
							nullFillerText={NULL_FILLER_TEXT_PROFILE}
							format={FORMAT_CURRENCY_MILLIONS}
							colorGradientInfo={SV_COLOR_GRADIENT}
							size={SM}
							isLoading
						/>
					)}
					{currentIntlProspectValueBatter === undefined &&
						currentIntlProspectValuePitcher === undefined &&
						fetchingIntlProspectValues === false && (
							<SecondaryStat
								label={"SV"}
								icon={<BaseballBat sx={BaseballBatIconStyle} />}
								nullFillerText={NULL_FILLER_TEXT_PROFILE}
								format={FORMAT_CURRENCY_MILLIONS}
								colorGradientInfo={SV_COLOR_GRADIENT}
								size={SM}
								isLoading={fetchingIntlProspectValues}
							/>
						)}
					{currentIntlProspectValueBatter !== undefined && (
						<SecondaryStat
							label={"SV"}
							icon={
								currentIntlProspectValuePitcher !== undefined ? (
									<BaseballBat sx={BaseballBatIconStyle} />
								) : (
									undefined
								)
							}
							value={formatIntlSurplusValue(currentIntlProspectValueBatter)}
							nullFillerText={NULL_FILLER_TEXT_PROFILE}
							format={FORMAT_CURRENCY_MILLIONS}
							colorGradientInfo={SV_COLOR_GRADIENT}
							size={SM}
							isLoading={fetchingIntlProspectValues}
						/>
					)}
					{currentIntlProspectValuePitcher !== undefined && (
						<SecondaryStat
							label={"SV"}
							icon={
								currentIntlProspectValueBatter !== undefined ? (
									<Baseball sx={BaseballIconStyle} />
								) : (
									undefined
								)
							}
							value={formatIntlSurplusValue(currentIntlProspectValuePitcher)}
							nullFillerText={NULL_FILLER_TEXT_PROFILE}
							format={FORMAT_CURRENCY_MILLIONS}
							colorGradientInfo={SV_COLOR_GRADIENT}
							size={SM}
							isLoading={fetchingIntlProspectValues}
						/>
					)}
				</HStack>
			)}
			<MinimalGridList
				label="International Information"
				data={intlInfoData}
				style={{
					container: hasProjectionsPermissions
						? {
								...MinimalGridListContainerStyle,
								...topMinimalGridListContainerStyle
						  }
						: {
								paddingTop: "0"
						  }
				}}
			/>
			<MinimalGridList
				label="Bio"
				data={bioData}
				style={{
					container: MinimalGridListContainerStyle
				}}
			/>
			<MinimalGridList
				label="System Information"
				data={idData}
				style={{
					container: MinimalGridListContainerStyle
				}}
			/>
		</VStack>
	);
};

export default PlayerPageIntlProfile;
