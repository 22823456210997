import React, { useEffect } from "react";
import { SystemStyleObject, useToast } from "@chakra-ui/react";
import { useMachine } from "@xstate/react";

import { round10 } from "_react/shared/_helpers/numbers";
import { NULL_FILLER_TEXT_PROJECTIONS_TAB } from "_react/playerpage/_constants";
import { SV_COLOR_GRADIENT_TEXT } from "_react/playerpage/_constants";
import { IPrimaryStatProps } from "_react/shared/ui/presentation/components/stat/PrimaryStat/PrimaryStat";
import { ISecondaryStatProps } from "_react/shared/ui/presentation/components/stat/SecondaryStat/SecondaryStat";
import { FORMAT_CURRENCY_MILLIONS, SM } from "_react/shared/ui/presentation/components/stat/shared/_constants";
import StatCard from "_react/shared/ui/presentation/components/stat/StatCard/StatCard";
import DollarSign from "_react/shared/ui/icons/DollarSign";
import { IIntlProspectValue } from "_react/shared/data_models/phred/_types";
import { TOOLTIP_INTL_PROSPECT_VALUE } from "_react/shared/_constants/tooltips";
import { formatDateRange } from "_react/shared/ui/data/cards/IntlProspectValuesStatCard/_helpers";

import createIntlProspectValuesStatCardMachine, {
	TIntlProspectValuesStatCardContext,
	SET_PLAYER_ID,
	SET_INTL_PROSPECT_VALUES,
	FETCHING_INTL_PROSPECT_VALUES
} from "_react/shared/ui/data/cards/IntlProspectValuesStatCard/_machine";

export type TIntlProspectValuesStatCardData = {
	intlProspectValues?: Array<IIntlProspectValue> | null;
	isLoading?: boolean;
};

type TIntlProspectValueSectionProps = {
	playerId?: number;
	playerType?: string;
	shouldFetchData?: boolean;
	data?: TIntlProspectValuesStatCardData;
	style?: SystemStyleObject;
};

const IntlProspectValuesStatCard = ({
	playerId,
	playerType,
	data,
	shouldFetchData = true,
	style
}: TIntlProspectValueSectionProps) => {
	const toast = useToast();
	const [current, send] = useMachine(createIntlProspectValuesStatCardMachine(playerId, shouldFetchData, data, toast));
	const { intlProspectValues } = current.context as TIntlProspectValuesStatCardContext;

	const fetchingIntlProspectValues = current.matches(FETCHING_INTL_PROSPECT_VALUES);
	const isLoading = shouldFetchData ? fetchingIntlProspectValues : data?.isLoading;

	useEffect(() => {
		send({ type: SET_PLAYER_ID, data: playerId });
	}, [playerId, send]);

	useEffect(() => {
		if (data?.intlProspectValues !== intlProspectValues && !shouldFetchData) {
			send({ type: SET_INTL_PROSPECT_VALUES, data: data?.intlProspectValues });
		}
	}, [data?.intlProspectValues, shouldFetchData, send, intlProspectValues]);

	const currentIntlProspectValue = intlProspectValues?.find((pv: IIntlProspectValue) => pv.playerType === playerType);

	const primaryStatIpv: IPrimaryStatProps = {
		label: "SV",
		value: round10(currentIntlProspectValue?.xsurplusMillions, -1),
		nullFillerText: NULL_FILLER_TEXT_PROJECTIONS_TAB,
		colorGradientInfo: SV_COLOR_GRADIENT_TEXT,
		format: FORMAT_CURRENCY_MILLIONS,
		isLoading: isLoading
	};
	const secondaryStatsApv: Array<ISecondaryStatProps> = [
		{
			label: "Rank",
			value: currentIntlProspectValue?.xsurplusRankBytype,
			nullFillerText: NULL_FILLER_TEXT_PROJECTIONS_TAB,
			size: SM,
			tooltipLabel: "Overall Rank",
			isLoading: isLoading
		},
		{
			label: "Time Frame",
			value: formatDateRange(currentIntlProspectValue),
			nullFillerText: NULL_FILLER_TEXT_PROJECTIONS_TAB,
			size: SM,
			isLoading: isLoading
		}
	];
	return (
		<>
			<StatCard
				statIcon={<DollarSign color="gray.600" boxSize={6} />}
				primaryStat={primaryStatIpv}
				secondaryStats={secondaryStatsApv}
				tooltipLabel={TOOLTIP_INTL_PROSPECT_VALUE}
				style={style}
			/>
		</>
	);
};

export default IntlProspectValuesStatCard;
