import { Machine, assign, Interpreter, AnyEventObject } from "xstate";
import { CancelTokenSource } from "axios";
import { CreateToastFnReturn } from "@chakra-ui/react";

import { promiseWRetry } from "utils/helpers";
import { DEFAULT_TOAST_ERROR_PROPS } from "_react/shared/_constants/toast";
import { fetchPlayer } from "_react/shared/data_models/player/_network";
import { PLAYING_LEVEL_AMA, PLAYING_LEVEL_INTL } from "_react/shared/data_models/arsenal_scores/_constants";
import {
	fetchArsenalScoresParams,
	fetchPlayerSeasonArsenalScores,
	fetchArsenalScoresThreshold
} from "_react/shared/data_models/arsenal_scores/_network";
import { getCancelSource } from "utils/url_helpers";
import { TPlayerClassification } from "utils/tsutils";
import {
	IArsenalScoresParamsApiResponse,
	IArsenalScoresThresholdApiResponse,
	IPlayerSeasonArsenalScoresSchema
} from "_react/shared/data_models/arsenal_scores/_types";
import { GAME_TYPE_OVERALL, PITCH_TYPE_OVERALL } from "_react/shared/data_models/arsenal_scores/_constants";
import { IIntrinsicValue } from "_react/shared/data_models/inference/_types";
import IntrinsicValue from "_react/shared/data_models/inference/intrinsic_value";

import { TArsenalLocationTilesPlayer } from "_react/shared/ui/data/other/ArsenalLocationTiles/_types";
import {
	TArsenalLocationTilesPrimaryData,
	TArsenalLocationTilesSecondaryData
} from "_react/shared/ui/data/other/ArsenalLocationTiles/ArsenalLocationTiles";

const PLAYER_SEASON_ARSENAL_SCORES_CANCEL_SOURCE = "playerSeasonArsenalScores";
const ARSENAL_SCORES_PARAMS_CANCEL_SOURCE = "arsenalScoresParams";
const ARSENAL_SCORES_THRESHOLD_CANCEL_SOURCE = "arsenalScoresThreshold";
const ARSENAL_LOCATION_TILES_PLAYER_CANCEL_SOURCE = "arsenalLocationTilesPlayer";
const INTRINSIC_VALUES_CANCEL_SOURCE = "intrinsicValues";

export type TArsenalLocationTilesCancelSource = {
	[PLAYER_SEASON_ARSENAL_SCORES_CANCEL_SOURCE]?: CancelTokenSource;
	[ARSENAL_SCORES_PARAMS_CANCEL_SOURCE]?: CancelTokenSource;
	[ARSENAL_SCORES_THRESHOLD_CANCEL_SOURCE]?: CancelTokenSource;
	[ARSENAL_LOCATION_TILES_PLAYER_CANCEL_SOURCE]?: CancelTokenSource;
	[INTRINSIC_VALUES_CANCEL_SOURCE]?: CancelTokenSource;
};

export type TArsenalLocationTilesContext = {
	seasonFilter: number;
	lastSeasonFilter: number;
	playerId?: number;
	lastPlayerId?: number;
	playingLevel?: TPlayerClassification;
	lastplayingLevel?: TPlayerClassification;
	shouldFetchPrimaryData?: boolean;
	shouldFetchSecondaryData?: boolean;
	playerSeasonArsenalScores?: Array<IPlayerSeasonArsenalScoresSchema> | null;
	arsenalScoresParams?: Array<IArsenalScoresParamsApiResponse> | null;
	arsenalScoresThreshold?: Array<IArsenalScoresThresholdApiResponse> | null;
	arsenalLocationTilesPlayer?: TArsenalLocationTilesPlayer;
	intrinsicValues?: Array<IIntrinsicValue> | null;
	hasIvHeatmapsPermissions?: boolean;
	cancelSources: TArsenalLocationTilesCancelSource;
	toast?: CreateToastFnReturn;
};

interface IArsenalLocationTilesStateSchema {
	states: {
		initializing: {};
		initialized: {
			states: {
				// Fetches player season arsenal scores data
				playerSeasonArsenalScores: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
				// Fetches arsenal scores params data
				arsenalScoresParams: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
				// Fetches arsenal scores thresholds data
				arsenalScoresThreshold: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
				// Fetches additional player data
				arsenalLocationTilesPlayer: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
				// Fetches intrinsic values inference data
				intrinsicValues: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
			};
		};
	};
}

export const SET_ARSENAL_LOCATION_TILES_PLAYER = "SET_ARSENAL_LOCATION_TILES_PLAYER";
export const SET_PLAYER_SEASON_ARSENAL_SCORES = "SET_PLAYER_SEASON_ARSENAL_SCORES";
export const SET_ARSENAL_SCORES_PARAMS = "SET_ARSENAL_SCORES_PARAMS";
export const SET_ARSENAL_SCORES_THRESHOLD = "SET_ARSENAL_SCORES_THRESHOLD";
export const SET_INTRINSIC_VALUES = "SET_INTRINSIC_VALUES";
export const SET_PLAYER_ID = "SET_PLAYER_ID";
export const SET_PLAYING_LEVEL = "SET_PLAYING_LEVEL";
export const SET_SEASON_FILTER = "SET_SEASON_FILTER";
export const FETCHING_PLAYER_SEASON_ARSENAL_SCORES = {
	initialized: { playerSeasonArsenalScores: "fetching" }
};
export const FETCHING_ARSENAL_SCORES_PARAMS = { initialized: { arsenalScoresParams: "fetching" } };
export const FETCHING_ARSENAL_SCORES_THRESHOLD = { initialized: { arsenalScoresThreshold: "fetching" } };
export const FETCHING_ARSENAL_LOCATION_TILES_PLAYER = {
	initialized: { arsenalLocationTilesPlayer: "fetching" }
};
export const FETCHING_INTRINSIC_VALUES = {
	initialized: { intrinsicValues: "fetching" }
};

const FETCH_PLAYER_SEASON_ARSENAL_SCORES_DONE = "done.invoke.fetchingPlayerSeasonArsenalScores:invocation[0]";
const FETCH_ARSENAL_SCORES_PARAMS_DONE = "done.invoke.fetchingArsenalScoresParams:invocation[0]";
const FETCH_ARSENAL_SCORES_THRESHOLD_DONE = "done.invoke.fetchingArsenalScoresThreshold:invocation[0]";
const FETCH_ARSENAL_LOCATION_TILES_PLAYER_DONE = "done.invoke.fetchingArsenalLocationTilesPlayer:invocation[0]";
const FETCH_INTRINSIC_VALUES_DONE = "done.invoke.fetchingIntrinsicValues:invocation[0]";

type TPlayerSeasonArsenalScoresEvent = {
	type: typeof SET_PLAYER_SEASON_ARSENAL_SCORES;
	data: Array<IPlayerSeasonArsenalScoresSchema> | null | undefined;
};
type TArsenalScoresParamsEvent = {
	type: typeof SET_ARSENAL_SCORES_PARAMS;
	data: Array<IArsenalScoresParamsApiResponse> | null | undefined;
};
type TArsenalScoresThresholdEvent = {
	type: typeof SET_ARSENAL_SCORES_THRESHOLD;
	data: Array<IArsenalScoresThresholdApiResponse> | null | undefined;
};
type TSetArsenalLocationTilesPlayerEvent = {
	type: typeof SET_ARSENAL_LOCATION_TILES_PLAYER;
	data: TArsenalLocationTilesPlayer | undefined;
};
type TSetIntrinsicValuesEvent = {
	type: typeof SET_INTRINSIC_VALUES;
	data: Array<IIntrinsicValue> | null | undefined;
};
type TSetPlayerIdEvent = {
	type: typeof SET_PLAYER_ID;
	data: number | undefined;
};
type TSetplayingLevelEvent = {
	type: typeof SET_PLAYING_LEVEL;
	data: TPlayerClassification | undefined;
};
type TSetSeasonFilterEvent = {
	type: typeof SET_SEASON_FILTER;
	data: number;
};
type TFetchPlayerSeasonArsenalScoresEvent = {
	type: typeof FETCH_PLAYER_SEASON_ARSENAL_SCORES_DONE;
	data?: Array<IPlayerSeasonArsenalScoresSchema>;
};
type TFetchArsenalScoresParamsEvent = {
	type: typeof FETCH_ARSENAL_SCORES_PARAMS_DONE;
	data?: Array<IArsenalScoresParamsApiResponse>;
};
type TFetchArsenalScoresThresholdEvent = {
	type: typeof FETCH_ARSENAL_SCORES_THRESHOLD_DONE;
	data?: Array<IArsenalScoresThresholdApiResponse>;
};
type TFetchArsenalLocationTilesPlayerEvent = {
	type: typeof FETCH_ARSENAL_LOCATION_TILES_PLAYER_DONE;
	data?: TArsenalLocationTilesPlayer;
};
type TFetchIntrinsicValuesEvent = {
	type: typeof FETCH_INTRINSIC_VALUES_DONE;
	data?: Array<IIntrinsicValue> | null;
};

type TArsenalLocationTilesEvent =
	| TPlayerSeasonArsenalScoresEvent
	| TArsenalScoresParamsEvent
	| TArsenalScoresThresholdEvent
	| TSetPlayerIdEvent
	| TSetplayingLevelEvent
	| TSetSeasonFilterEvent
	| TSetArsenalLocationTilesPlayerEvent
	| TSetIntrinsicValuesEvent
	| TFetchArsenalScoresParamsEvent
	| TFetchArsenalScoresThresholdEvent
	| TFetchArsenalLocationTilesPlayerEvent
	| TFetchPlayerSeasonArsenalScoresEvent
	| TFetchIntrinsicValuesEvent;

export type TArsenalLocationTilesSend = Interpreter<
	TArsenalLocationTilesContext,
	IArsenalLocationTilesStateSchema,
	TArsenalLocationTilesEvent
>["send"];

const ArsenalLocationTilesMachine = (
	seasonFilterProp: number,
	playerIdProp?: number,
	playingLevelProp?: TPlayerClassification,
	shouldFetchPrimaryData = true,
	primaryData?: TArsenalLocationTilesPrimaryData,
	shouldFetchSecondaryData = true,
	secondaryData?: TArsenalLocationTilesSecondaryData,
	hasIvHeatmapsPermissions?: boolean,
	toastProp?: CreateToastFnReturn
) =>
	Machine<TArsenalLocationTilesContext, IArsenalLocationTilesStateSchema, TArsenalLocationTilesEvent>(
		{
			id: "arsenalLocationTiles",
			initial: "initializing",
			context: {
				seasonFilter: seasonFilterProp,
				lastSeasonFilter: seasonFilterProp,
				playerId: playerIdProp,
				lastPlayerId: playerIdProp,
				playingLevel: playingLevelProp,
				lastplayingLevel: playingLevelProp,
				shouldFetchPrimaryData: shouldFetchPrimaryData,
				shouldFetchSecondaryData: shouldFetchSecondaryData,
				playerSeasonArsenalScores: primaryData?.playerSeasonArsenalScores,
				intrinsicValues: primaryData?.intrinsicValues,
				arsenalScoresParams: primaryData?.arsenalScoresParams,
				arsenalScoresThreshold: secondaryData?.arsenalScoresThreshold,
				arsenalLocationTilesPlayer: secondaryData?.arsenalLocationTilesPlayer,
				hasIvHeatmapsPermissions: hasIvHeatmapsPermissions ?? false,
				cancelSources: {},
				toast: toastProp
			},
			states: {
				initializing: {
					always: {
						target: "initialized"
					}
				},
				initialized: {
					type: "parallel",
					on: {
						[SET_PLAYER_SEASON_ARSENAL_SCORES]: { actions: "setPlayerSeasonArsenalScores" },
						[SET_ARSENAL_SCORES_PARAMS]: { actions: "setArsenalScoresParams" },
						[SET_ARSENAL_SCORES_THRESHOLD]: { actions: "setArsenalScoresThreshold" },
						[SET_ARSENAL_LOCATION_TILES_PLAYER]: { actions: "setArsenalLocationTilesPlayer" },
						[SET_INTRINSIC_VALUES]: { actions: "setIntrinsicValues" },
						[SET_PLAYER_ID]: {
							actions: [
								"setPlayerId",
								"clearIntrinsicValues",
								"clearArsenalLocationTilesPlayer",
								"clearPlayerSeasonArsenalScores"
							]
						},
						[SET_PLAYING_LEVEL]: { actions: ["setplayingLevel", "clearPlayerSeasonArsenalScores"] },
						[SET_SEASON_FILTER]: {
							actions: [
								"setSeasonFilter",
								"clearIntrinsicValues",
								"clearArsenalScoresParams",
								"clearPlayerSeasonArsenalScores"
							]
						}
					},
					states: {
						playerSeasonArsenalScores: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchingPlayerSeasonArsenalScores",
												cond: "shouldFetchPlayerSeasonArsenalScores"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchingPlayerSeasonArsenalScores",
									entry: ["refreshPlayerSeasonArsenalScoresCancelSource"],
									invoke: {
										src: "fetchPlayerSeasonArsenalScores",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchPlayerSeasonArsenalScoresSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchPlayerSeasonArsenalScoresErrored"
										}
									}
								}
							}
						},
						arsenalScoresParams: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchingArsenalScoresParams",
												cond: "shouldFetchArsenalScoresParams"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchingArsenalScoresParams",
									entry: ["refreshArsenalScoresParamsCancelSource"],
									invoke: {
										src: "fetchArsenalScoresParams",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchArsenalScoresParamsSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchArsenalScoresParamsErrored"
										}
									}
								}
							}
						},
						arsenalScoresThreshold: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchingArsenalScoresThreshold",
												cond: "shouldFetchArsenalScoresThreshold"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchingArsenalScoresThreshold",
									entry: ["refreshArsenalScoresThresholdCancelSource"],
									invoke: {
										src: "fetchArsenalScoresThreshold",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchArsenalScoresThresholdSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchArsenalScoresThresholdErrored"
										}
									}
								}
							}
						},
						arsenalLocationTilesPlayer: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchingArsenalLocationTilesPlayer",
												cond: "shouldFetchArsenalLocationTilesPlayer"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchingArsenalLocationTilesPlayer",
									entry: ["refreshArsenalLocationTilesPlayerCancelSource"],
									invoke: {
										src: "fetchArsenalLocationTilesPlayer",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchArsenalLocationTilesPlayerSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchArsenalLocationTilesPlayerErrored"
										}
									}
								}
							}
						},
						intrinsicValues: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchingIntrinsicValues",
												cond: "shouldFetchIntrinsicValues"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchingIntrinsicValues",
									entry: ["refreshIntrinsicValuesCancelSource"],
									invoke: {
										src: "fetchIntrinsicValues",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchIntrinsicValuesSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchIntrinsicValuesErrored"
										}
									}
								}
							}
						}
					}
				}
			}
		},
		{
			guards: {
				shouldFetchPlayerSeasonArsenalScores: (
					context: TArsenalLocationTilesContext,
					_event: TArsenalLocationTilesEvent
				) =>
					context.playerSeasonArsenalScores === undefined &&
					shouldFetchPrimaryData &&
					context.playerId !== undefined &&
					context.playingLevel !== undefined,
				shouldFetchArsenalScoresParams: (
					context: TArsenalLocationTilesContext,
					_event: TArsenalLocationTilesEvent
				) =>
					context.arsenalScoresParams === undefined &&
					shouldFetchPrimaryData &&
					context.seasonFilter !== undefined,
				shouldFetchArsenalScoresThreshold: (
					context: TArsenalLocationTilesContext,
					_event: TArsenalLocationTilesEvent
				) =>
					context.arsenalScoresThreshold === undefined &&
					(context.playingLevel === PLAYING_LEVEL_AMA || context.playingLevel === PLAYING_LEVEL_INTL) &&
					shouldFetchSecondaryData,
				shouldFetchArsenalLocationTilesPlayer: (
					context: TArsenalLocationTilesContext,
					_event: TArsenalLocationTilesEvent
				) =>
					context.arsenalLocationTilesPlayer === undefined &&
					shouldFetchSecondaryData &&
					context.playerId !== undefined &&
					context.playingLevel !== undefined,
				shouldFetchIntrinsicValues: (
					context: TArsenalLocationTilesContext,
					_event: TArsenalLocationTilesEvent
				) =>
					context.intrinsicValues === undefined &&
					shouldFetchPrimaryData &&
					context.playerId !== undefined &&
					context.seasonFilter !== undefined &&
					context.hasIvHeatmapsPermissions === true
			},
			actions: {
				setPlayerSeasonArsenalScores: assign<TArsenalLocationTilesContext, TArsenalLocationTilesEvent>({
					playerSeasonArsenalScores: (
						context: TArsenalLocationTilesContext,
						event: TArsenalLocationTilesEvent
					) => {
						if (event.type !== SET_PLAYER_SEASON_ARSENAL_SCORES) return context.playerSeasonArsenalScores;
						return event.data;
					},
					cancelSources: (context: TArsenalLocationTilesContext, event: TArsenalLocationTilesEvent) => {
						if (event.type !== SET_PLAYER_SEASON_ARSENAL_SCORES) return context.cancelSources;
						if (context.cancelSources[PLAYER_SEASON_ARSENAL_SCORES_CANCEL_SOURCE] != null)
							context.cancelSources[PLAYER_SEASON_ARSENAL_SCORES_CANCEL_SOURCE].cancel();
						delete context.cancelSources[PLAYER_SEASON_ARSENAL_SCORES_CANCEL_SOURCE];
						return context.cancelSources;
					}
				}),
				setArsenalScoresParams: assign<TArsenalLocationTilesContext, TArsenalLocationTilesEvent>({
					arsenalScoresParams: (context: TArsenalLocationTilesContext, event: TArsenalLocationTilesEvent) => {
						if (event.type !== SET_ARSENAL_SCORES_PARAMS) return context.arsenalScoresParams;
						return event.data;
					},
					cancelSources: (context: TArsenalLocationTilesContext, event: TArsenalLocationTilesEvent) => {
						if (event.type !== SET_ARSENAL_SCORES_PARAMS) return context.cancelSources;
						if (context.cancelSources[ARSENAL_SCORES_PARAMS_CANCEL_SOURCE] != null)
							context.cancelSources[ARSENAL_SCORES_PARAMS_CANCEL_SOURCE].cancel();
						delete context.cancelSources[ARSENAL_SCORES_PARAMS_CANCEL_SOURCE];
						return context.cancelSources;
					}
				}),
				setArsenalScoresThreshold: assign<TArsenalLocationTilesContext, TArsenalLocationTilesEvent>({
					arsenalScoresThreshold: (
						context: TArsenalLocationTilesContext,
						event: TArsenalLocationTilesEvent
					) => {
						if (event.type !== SET_ARSENAL_SCORES_THRESHOLD) return context.arsenalScoresThreshold;
						return event.data;
					},
					cancelSources: (context: TArsenalLocationTilesContext, event: TArsenalLocationTilesEvent) => {
						if (event.type !== SET_ARSENAL_SCORES_THRESHOLD) return context.cancelSources;
						if (context.cancelSources[ARSENAL_SCORES_THRESHOLD_CANCEL_SOURCE] != null)
							context.cancelSources[ARSENAL_SCORES_THRESHOLD_CANCEL_SOURCE].cancel();
						delete context.cancelSources[ARSENAL_SCORES_THRESHOLD_CANCEL_SOURCE];
						return context.cancelSources;
					}
				}),
				setArsenalLocationTilesPlayer: assign<TArsenalLocationTilesContext, TArsenalLocationTilesEvent>({
					arsenalLocationTilesPlayer: (
						context: TArsenalLocationTilesContext,
						event: TArsenalLocationTilesEvent
					) => {
						if (event.type !== SET_ARSENAL_LOCATION_TILES_PLAYER) return context.arsenalLocationTilesPlayer;
						return event.data;
					},
					cancelSources: (context: TArsenalLocationTilesContext, event: TArsenalLocationTilesEvent) => {
						if (event.type !== SET_ARSENAL_LOCATION_TILES_PLAYER) return context.cancelSources;
						if (context.cancelSources[ARSENAL_LOCATION_TILES_PLAYER_CANCEL_SOURCE] != null)
							context.cancelSources[ARSENAL_LOCATION_TILES_PLAYER_CANCEL_SOURCE].cancel();
						delete context.cancelSources[ARSENAL_LOCATION_TILES_PLAYER_CANCEL_SOURCE];
						return context.cancelSources;
					}
				}),
				setIntrinsicValues: assign<TArsenalLocationTilesContext, TArsenalLocationTilesEvent>({
					intrinsicValues: (context: TArsenalLocationTilesContext, event: TArsenalLocationTilesEvent) => {
						if (event.type !== SET_INTRINSIC_VALUES) return context.intrinsicValues;
						return event.data;
					},
					cancelSources: (context: TArsenalLocationTilesContext, event: TArsenalLocationTilesEvent) => {
						if (event.type !== SET_INTRINSIC_VALUES) return context.cancelSources;
						if (context.cancelSources[INTRINSIC_VALUES_CANCEL_SOURCE] != null)
							context.cancelSources[INTRINSIC_VALUES_CANCEL_SOURCE].cancel();
						delete context.cancelSources[INTRINSIC_VALUES_CANCEL_SOURCE];
						return context.cancelSources;
					}
				}),
				setPlayerId: assign<TArsenalLocationTilesContext, TArsenalLocationTilesEvent>({
					playerId: (context: TArsenalLocationTilesContext, event: TArsenalLocationTilesEvent) => {
						if (event.type !== SET_PLAYER_ID) return context.playerId;
						return event.data;
					}
				}),
				setplayingLevel: assign<TArsenalLocationTilesContext, TArsenalLocationTilesEvent>({
					playingLevel: (context: TArsenalLocationTilesContext, event: TArsenalLocationTilesEvent) => {
						if (event.type !== SET_PLAYING_LEVEL) return context.playingLevel;
						return event.data;
					}
				}),
				setSeasonFilter: assign<TArsenalLocationTilesContext, TArsenalLocationTilesEvent>({
					seasonFilter: (context: TArsenalLocationTilesContext, event: TArsenalLocationTilesEvent) => {
						if (event.type !== SET_SEASON_FILTER) return context.seasonFilter;
						return event.data;
					}
				}),
				clearIntrinsicValues: assign<TArsenalLocationTilesContext, TArsenalLocationTilesEvent>({
					intrinsicValues: (_context: TArsenalLocationTilesContext, _event: TArsenalLocationTilesEvent) =>
						undefined,
					cancelSources: (context: TArsenalLocationTilesContext, _event: TArsenalLocationTilesEvent) => {
						context.cancelSources[INTRINSIC_VALUES_CANCEL_SOURCE]?.cancel();
						delete context.cancelSources[INTRINSIC_VALUES_CANCEL_SOURCE];
						return context.cancelSources;
					}
				}),
				clearArsenalScoresParams: assign<TArsenalLocationTilesContext, TArsenalLocationTilesEvent>({
					arsenalScoresParams: (_context: TArsenalLocationTilesContext, _event: TArsenalLocationTilesEvent) =>
						undefined,
					cancelSources: (context: TArsenalLocationTilesContext, _event: TArsenalLocationTilesEvent) => {
						context.cancelSources[ARSENAL_SCORES_PARAMS_CANCEL_SOURCE]?.cancel();
						delete context.cancelSources[ARSENAL_SCORES_PARAMS_CANCEL_SOURCE];
						return context.cancelSources;
					}
				}),
				clearArsenalLocationTilesPlayer: assign<TArsenalLocationTilesContext, TArsenalLocationTilesEvent>({
					arsenalLocationTilesPlayer: (
						_context: TArsenalLocationTilesContext,
						_event: TArsenalLocationTilesEvent
					) => undefined,
					cancelSources: (context: TArsenalLocationTilesContext, _event: TArsenalLocationTilesEvent) => {
						context.cancelSources[ARSENAL_LOCATION_TILES_PLAYER_CANCEL_SOURCE]?.cancel();
						delete context.cancelSources[ARSENAL_LOCATION_TILES_PLAYER_CANCEL_SOURCE];
						return context.cancelSources;
					}
				}),
				clearPlayerSeasonArsenalScores: assign<TArsenalLocationTilesContext, TArsenalLocationTilesEvent>({
					playerSeasonArsenalScores: (
						_context: TArsenalLocationTilesContext,
						_event: TArsenalLocationTilesEvent
					) => undefined,
					cancelSources: (context: TArsenalLocationTilesContext, _event: TArsenalLocationTilesEvent) => {
						context.cancelSources[PLAYER_SEASON_ARSENAL_SCORES_CANCEL_SOURCE]?.cancel();
						delete context.cancelSources[PLAYER_SEASON_ARSENAL_SCORES_CANCEL_SOURCE];
						return context.cancelSources;
					}
				}),
				// Cancel Source Actions
				refreshPlayerSeasonArsenalScoresCancelSource: assign<
					TArsenalLocationTilesContext,
					TArsenalLocationTilesEvent
				>({
					cancelSources: (context: TArsenalLocationTilesContext, _event: TArsenalLocationTilesEvent) => {
						if (context.cancelSources[PLAYER_SEASON_ARSENAL_SCORES_CANCEL_SOURCE] != null)
							context.cancelSources[PLAYER_SEASON_ARSENAL_SCORES_CANCEL_SOURCE].cancel();
						context.cancelSources[PLAYER_SEASON_ARSENAL_SCORES_CANCEL_SOURCE] = getCancelSource();
						return context.cancelSources;
					}
				}),
				refreshArsenalScoresParamsCancelSource: assign<
					TArsenalLocationTilesContext,
					TArsenalLocationTilesEvent
				>({
					cancelSources: (context: TArsenalLocationTilesContext, _event: TArsenalLocationTilesEvent) => {
						if (context.cancelSources[ARSENAL_SCORES_PARAMS_CANCEL_SOURCE] != null)
							context.cancelSources[ARSENAL_SCORES_PARAMS_CANCEL_SOURCE].cancel();
						context.cancelSources[ARSENAL_SCORES_PARAMS_CANCEL_SOURCE] = getCancelSource();
						return context.cancelSources;
					}
				}),
				refreshArsenalScoresThresholdCancelSource: assign<
					TArsenalLocationTilesContext,
					TArsenalLocationTilesEvent
				>({
					cancelSources: (context: TArsenalLocationTilesContext, _event: TArsenalLocationTilesEvent) => {
						if (context.cancelSources[ARSENAL_SCORES_THRESHOLD_CANCEL_SOURCE] != null)
							context.cancelSources[ARSENAL_SCORES_THRESHOLD_CANCEL_SOURCE].cancel();
						context.cancelSources[ARSENAL_SCORES_THRESHOLD_CANCEL_SOURCE] = getCancelSource();
						return context.cancelSources;
					}
				}),
				refreshArsenalLocationTilesPlayerCancelSource: assign<
					TArsenalLocationTilesContext,
					TArsenalLocationTilesEvent
				>({
					cancelSources: (context: TArsenalLocationTilesContext, _event: TArsenalLocationTilesEvent) => {
						if (context.cancelSources[ARSENAL_LOCATION_TILES_PLAYER_CANCEL_SOURCE] != null)
							context.cancelSources[ARSENAL_LOCATION_TILES_PLAYER_CANCEL_SOURCE].cancel();
						context.cancelSources[ARSENAL_LOCATION_TILES_PLAYER_CANCEL_SOURCE] = getCancelSource();
						return context.cancelSources;
					}
				}),
				refreshIntrinsicValuesCancelSource: assign<TArsenalLocationTilesContext, TArsenalLocationTilesEvent>({
					cancelSources: (context: TArsenalLocationTilesContext, _event: TArsenalLocationTilesEvent) => {
						if (context.cancelSources[INTRINSIC_VALUES_CANCEL_SOURCE] != null)
							context.cancelSources[INTRINSIC_VALUES_CANCEL_SOURCE].cancel();
						context.cancelSources[INTRINSIC_VALUES_CANCEL_SOURCE] = getCancelSource();
						return context.cancelSources;
					}
				}),
				// Fetch Success Actions
				handleFetchPlayerSeasonArsenalScoresSuccess: assign<
					TArsenalLocationTilesContext,
					TArsenalLocationTilesEvent
				>({
					playerSeasonArsenalScores: (
						context: TArsenalLocationTilesContext,
						event: TArsenalLocationTilesEvent
					) => {
						if (event.type !== FETCH_PLAYER_SEASON_ARSENAL_SCORES_DONE)
							return context.playerSeasonArsenalScores;
						return event.data;
					}
				}),
				handleFetchArsenalScoresParamsSuccess: assign<TArsenalLocationTilesContext, TArsenalLocationTilesEvent>(
					{
						arsenalScoresParams: (
							context: TArsenalLocationTilesContext,
							event: TArsenalLocationTilesEvent
						) => {
							if (event.type !== FETCH_ARSENAL_SCORES_PARAMS_DONE) return context.arsenalScoresParams;
							return event.data;
						}
					}
				),
				handleFetchArsenalScoresThresholdSuccess: assign<
					TArsenalLocationTilesContext,
					TArsenalLocationTilesEvent
				>({
					arsenalScoresThreshold: (
						context: TArsenalLocationTilesContext,
						event: TArsenalLocationTilesEvent
					) => {
						if (event.type !== FETCH_ARSENAL_SCORES_THRESHOLD_DONE) return context.arsenalScoresThreshold;
						return event.data;
					}
				}),
				handleFetchArsenalLocationTilesPlayerSuccess: assign<
					TArsenalLocationTilesContext,
					TArsenalLocationTilesEvent
				>({
					arsenalLocationTilesPlayer: (
						context: TArsenalLocationTilesContext,
						event: TArsenalLocationTilesEvent
					) => {
						if (event.type !== FETCH_ARSENAL_LOCATION_TILES_PLAYER_DONE)
							return context.arsenalLocationTilesPlayer;
						return event.data;
					}
				}),
				handleFetchIntrinsicValuesSuccess: assign<TArsenalLocationTilesContext, TArsenalLocationTilesEvent>({
					intrinsicValues: (context: TArsenalLocationTilesContext, event: TArsenalLocationTilesEvent) => {
						if (event.type !== FETCH_INTRINSIC_VALUES_DONE) return context.intrinsicValues;
						return event.data;
					}
				}),
				// Fetch Errored Actions
				handleFetchPlayerSeasonArsenalScoresErrored: (
					context: TArsenalLocationTilesContext,
					_event: TArsenalLocationTilesEvent
				) => {
					if (context.toast)
						context.toast({
							title: "Seasonal Arsenal Scores by Pitch Type",
							description: "Error fetching seasonal arsenal scores by pitch type data.",
							...DEFAULT_TOAST_ERROR_PROPS
						});
				},
				handleFetchArsenalScoresParamsErrored: (
					context: TArsenalLocationTilesContext,
					_event: TArsenalLocationTilesEvent
				) => {
					if (context.toast)
						context.toast({
							title: "Arsenal Scores Params",
							description: "Error fetching arsenal scores params data.",
							...DEFAULT_TOAST_ERROR_PROPS
						});
				},
				handleFetchArsenalScoresThresholdErrored: (
					context: TArsenalLocationTilesContext,
					_event: TArsenalLocationTilesEvent
				) => {
					if (context.toast)
						context.toast({
							title: "Arsenal Scores Thresholds",
							description: "Error fetching arsenal scores thresholds data.",
							...DEFAULT_TOAST_ERROR_PROPS
						});
				},
				handleFetchArsenalLocationTilesPlayerErrored: (
					context: TArsenalLocationTilesContext,
					_event: TArsenalLocationTilesEvent
				) => {
					if (context.toast)
						context.toast({
							title: "Player Data - Arsenal Location Tiles",
							description: "Error fetching player data for arsenal location tiles.",
							...DEFAULT_TOAST_ERROR_PROPS
						});
				},
				handleFetchIntrinsicValuesErrored: (
					context: TArsenalLocationTilesContext,
					_event: TArsenalLocationTilesEvent
				) => {
					if (context.toast)
						context.toast({
							title: "Intrinsic Values - Arsenal Location Tiles",
							description: "Error fetching intrinsic values inference data for arsenal location tiles.",
							...DEFAULT_TOAST_ERROR_PROPS
						});
				}
			},
			services: {
				fetchPlayerSeasonArsenalScores: (context: TArsenalLocationTilesContext, _event: AnyEventObject) => {
					const fetchFunc = () =>
						fetchPlayerSeasonArsenalScores(
							{
								playerId: context.playerId,
								season: context.seasonFilter,
								playingLevel: context.playingLevel,
								gameType: GAME_TYPE_OVERALL,
								"pitchType[neq]": PITCH_TYPE_OVERALL,
								isUseCache: true
							},
							context.cancelSources[PLAYER_SEASON_ARSENAL_SCORES_CANCEL_SOURCE]?.token
						);
					return promiseWRetry(fetchFunc);
				},
				fetchArsenalScoresThreshold: (context: TArsenalLocationTilesContext, _event: AnyEventObject) => {
					const fetchFunc = () =>
						fetchArsenalScoresThreshold(
							context.cancelSources[ARSENAL_SCORES_THRESHOLD_CANCEL_SOURCE]?.token
						);
					return promiseWRetry(fetchFunc);
				},
				fetchArsenalScoresParams: (context: TArsenalLocationTilesContext, _event: AnyEventObject) => {
					const fetchFunc = () =>
						fetchArsenalScoresParams(
							{
								pitchTypeGrouping: PITCH_TYPE_OVERALL,
								includeEventRa9NormalDistribution: true,
								season: context.seasonFilter,
								isUseCache: true
							},
							context.cancelSources[ARSENAL_SCORES_PARAMS_CANCEL_SOURCE]?.token
						);
					return promiseWRetry(fetchFunc);
				},
				fetchArsenalLocationTilesPlayer: (context: TArsenalLocationTilesContext, _event: AnyEventObject) => {
					const { playerId } = context;
					if (playerId === undefined) return Promise.resolve(undefined);
					const fetchFunc = () =>
						fetchPlayer<TArsenalLocationTilesPlayer>(
							{
								id: playerId,
								isUseCombinedId: true,
								fields: "throws"
							},
							context.cancelSources[ARSENAL_LOCATION_TILES_PLAYER_CANCEL_SOURCE]?.token
						);
					return promiseWRetry(fetchFunc);
				},
				fetchIntrinsicValues: (context: TArsenalLocationTilesContext, _event: AnyEventObject) => {
					const { playerId, seasonFilter } = context;
					if (playerId === undefined) return Promise.resolve(undefined);
					const fetchFunc = () =>
						IntrinsicValue.postResource(
							{
								playerId: playerId,
								season: seasonFilter,
								numLocX: 24,
								numLocZ: 24,
								isUseCache: true
							},
							context.cancelSources[INTRINSIC_VALUES_CANCEL_SOURCE]?.token
						);
					return promiseWRetry(fetchFunc);
				}
			}
		}
	);

export default ArsenalLocationTilesMachine;
