import React from "react";
import { Text, VStack } from "@chakra-ui/react";

import { INTL } from "_react/playerpage/_constants";
import { POSITION_GROUPS_POSITION_PLAYER, POSITION_GROUPS_PITCHER } from "_react/shared/_constants/position_groups";
import { getPositionGroupLabel } from "_react/shared/_helpers/position_groups";
import { SV_COLOR_GRADIENT_FUNCTION } from "_react/playerpage/_constants";
import { VALUE_TYPE_NUMBER, VALUE_TYPE_STRING } from "_react/shared/ui/presentation/components/Table/_constants";
import { TValueType } from "_react/shared/ui/presentation/components/Table/_types";
import { getAgeFromBirthDate } from "utils/helpers";
import { getPlayerFullNameFromPlayerProps } from "_react/shared/data_models/player/_helpers";
import { TIntlSurplusValueSimilarPlayer } from "_react/shared/ui/data/tables/IntlSurplusValueSimilarPlayersTable/_types";
import { convertToCurrency } from "_react/shared/ui/data/tables/SeasonalSurplusValuesTable/_helpers";

export const NULL_VALUE = null;
export const NULL_FILLER_TEXT = "-";

// We want to sort first by draft year, then by overall pick, but the shared table sort function only takes one value.
// As a workaround, define an upper bound on the number of players that could potentially be drafted in a given year.
// We generate a decimal by dividing overall pick by this upper bound, and add it to the year.
// This allows us to sort based on the year and break ties based on the overall pick.
export const PLAYERS_DRAFTED_UPPER_BOUND = 100000;

// TODO: These tooltips need to be updated
export const TOOLTIP_INTL_SURPLUS_VALUE =
	"International Surplus Value (INTL SV) is a forecast of the player's expected production at the Major League level\
	relative to expected compensation over his team control years. Due to the long and uncertain time-horizon for international players to realize surplus value,\
	the international model predicts an expectation of production in units of wins above replacement over the full controllable period (xWAR). A separate mapping\
	approximates expected compensation by converting xWAR to INTL SV.";
export const TOOLTIP_INTL_SV_OVER_TIME =
	"SV Over Time illustrates how a player's surplus value evolved from their\
	platform year as an international (left-most point) through their third season in domestic, professional baseball (right-most point).";
export const TOOLTIP_INTL_SV_CHANGE =
	"SV CHNG shows the player's current surplus value, along with an arrow depicting the direction of change for the player's\
	surplus value relative to their platform year as an international (a blue, downward-facing arrow indicates a decrease, while a green, upward-facing arrow indicates\
	an increase). The magnitude of the surplus value change is shown to the right of the arrow.";
export const TOOLTIP_HIGHEST_LEVEL =
	"This column shows the highest level of domestic, professional baseball the corresponding player has reached.";

export const POSITION_GROUPS_POSITION_PLAYER_OPTIONS = POSITION_GROUPS_POSITION_PLAYER.map((positionGroup: string) => {
	return {
		label: getPositionGroupLabel(positionGroup),
		value: positionGroup
	};
});

export const POSITION_GROUPS_PITCHER_OPTIONS = POSITION_GROUPS_PITCHER.map((positionGroup: string) => {
	return { label: positionGroup, value: positionGroup };
});

export const BATS_COLUMN = {
	value: "bats",
	label: "Bats",
	valueType: VALUE_TYPE_STRING as TValueType,
	width: 16,
	isMobile: false,
	getValueFunction: (row: TIntlSurplusValueSimilarPlayer) => {
		return `${row.player?.bats ?? NULL_FILLER_TEXT}`;
	}
};

export const THROWS_COLUMN = {
	value: "throws",
	label: "Throws",
	valueType: VALUE_TYPE_STRING as TValueType,
	width: 16,
	isMobile: false,
	getValueFunction: (row: TIntlSurplusValueSimilarPlayer) => {
		return `${row.player?.throws ?? NULL_FILLER_TEXT}`;
	}
};

export const COUNTRY_COLUMN = {
	value: "country",
	label: "Country",
	valueType: VALUE_TYPE_STRING as TValueType,
	width: 16,
	isMobile: false,
	getValueFunction: (row: TIntlSurplusValueSimilarPlayer) => {
		return `${row.player?.intlProfile?.birthCountry ?? NULL_FILLER_TEXT}`;
	}
};

export const SV_COLUMN = {
	value: "sv",
	label: "SV",
	tooltip: TOOLTIP_INTL_SURPLUS_VALUE,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	isMobile: true,
	width: 20,
	getValueFunction: (row: TIntlSurplusValueSimilarPlayer) =>
		row.xsurplusMillions !== null ? convertToCurrency(row.xsurplusMillions) : NULL_FILLER_TEXT,
	getSortValueFunction: (row: TIntlSurplusValueSimilarPlayer) => row.xsurplusMillions,
	getColorGradientValueFunction: (row: TIntlSurplusValueSimilarPlayer) => row.xsurplusMillions,
	colorGradientFunction: SV_COLOR_GRADIENT_FUNCTION
};

export const AGE_COLUMN = {
	value: "age",
	label: "age",
	width: 20,
	isMobile: true,
	getValueFunction: (row: TIntlSurplusValueSimilarPlayer) =>
		row.player?.intlProfile?.dateOfBirth || row.player?.birthDate
			? getAgeFromBirthDate(row.player?.intlProfile?.dateOfBirth ?? row.player?.birthDate) ?? NULL_FILLER_TEXT
			: NULL_FILLER_TEXT,
	getSortValueFunction: (row: TIntlSurplusValueSimilarPlayer) =>
		getAgeFromBirthDate(row.player?.intlProfile?.dateOfBirth ?? row.player?.birthDate) ?? NULL_FILLER_TEXT
};

const PLAYER_COLUMN = {
	value: "player",
	label: "Player",
	width: 44,
	isMobile: true,
	getValueFunction: (row: TIntlSurplusValueSimilarPlayer) => {
		return (
			<VStack align="start" gap="0">
				<Text fontSize="md" fontWeight="semibold">
					{getPlayerFullNameFromPlayerProps(
						row?.player?.firstName,
						row?.player?.firstNameLegal,
						row?.player?.lastName,
						row?.player?.lastNameExtended,
						row.player?.intlProfile?.firstName,
						row?.player?.intlProfile?.lastName,
						false,
						INTL
					)}
				</Text>
			</VStack>
		);
	},
	getSortValueFunction: (row: TIntlSurplusValueSimilarPlayer) =>
		row?.player?.intlProfile?.lastName ?? row?.player?.lastName ?? row?.player?.lastNameExtended ?? NULL_VALUE,
	onClickFunction: (row: TIntlSurplusValueSimilarPlayer) => {
		window.open(`../player?philId=${row?.player?.playerIntlId}&view=summary`, "_blank");
	}
};

export const INTL_SURPLUS_VALUE_SIMILAR_PLAYERS_COLUMNS = [
	PLAYER_COLUMN,
	AGE_COLUMN,
	BATS_COLUMN,
	THROWS_COLUMN,
	COUNTRY_COLUMN,
	SV_COLUMN
];
